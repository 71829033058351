var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("query-form", {
        attrs: { queryForm: _vm.queryForm, queryData: _vm.queryData },
        on: { handleQuery: _vm.handleQuery, handleReset: _vm.handleReset },
      }),
      _c("div", { staticClass: "sys_search_line" }),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.getInfo()
            },
          },
        },
        [_vm._v("新 建")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            stripe: "",
            data: _vm.pageData,
            "element-loading-text": "加载中...",
            "header-cell-style": {
              backgroundColor: "#fafafa",
              color: "#606266",
            },
          },
        },
        [
          _c(
            "template",
            { slot: "empty" },
            [
              _c("el-empty", {
                attrs: { "image-size": 100, description: "暂无数据" },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "80", fixed: "" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "订单编号",
              width: "200",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.getInfo(row.id)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.no))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", "show-overflow-tooltip": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _vm._v(" " + _vm._s(row.user.real_name) + " "),
                        _c("el-tag", [
                          _vm._v(
                            " " + _vm._s(row.user.system_agent_name) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.user.province +
                              "-" +
                              row.user.city +
                              "-" +
                              row.user.area
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "营收", "show-overflow-tooltip": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " 金额：" + _vm._s(_vm.$thousandth(row.price, 1)) + " "
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        " 利润：" + _vm._s(_vm.$thousandth(row.profit, 1)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "订单状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: row.status ? "success" : "" } },
                      [_vm._v(_vm._s(_vm.statusLabel[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "结算状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: { type: row.commission_status ? "success" : "" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.commissionStatusLabel[row.commission_status]
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              prop: "created_at",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "80", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "span",
                          { staticClass: "el-dropdown-link operation" },
                          [
                            _vm._v(" 操作"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.getInfo(row.id)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("编辑")])]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.getRemove(row.id)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("删除")])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          total: _vm.pageSize.total,
          page: _vm.pageSize.current_page,
          limt: _vm.pageSize.per_page,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageSize, "current_page", $event)
          },
          "update:limt": function ($event) {
            return _vm.$set(_vm.pageSize, "per_page", $event)
          },
          pagination: _vm.pagination,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }