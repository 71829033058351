import request from '@/utils/request'

// 用户列表
export function getUserList(data) {
  return request({
    url: '/api/user/list',
    method: 'post',
    data
  })
}

export function reqGetAddrUser(data) {
  return request({
    url: '/api/user/get_addr_user',
    method: 'post',
    data
  })
}

// 用户详情
export function reqUserInfo(data) {
  return request({
    url: '/api/user/info',
    method: 'post',
    data
  })
}

// 用户 - 新增/编辑
export function reqUserSave(data) {
  return request({
    url: '/api/user/save',
    method: 'post',
    data
  })
}
// 用户 - 删除
export function reqUserRemove(data) {
  return request({
    url: '/api/user/remove',
    method: 'post',
    data
  })
}
// 用户详情 - 更改上级
export function reqUserRelation(data) {
  return request({
    url: '/api/user/change_parent',
    method: 'post',
    data
  })
}

// 用户详情 - 更改代理等级
export function reqUserChangeAgent(data) {
  return request({
    url: '/api/user/change_level',
    method: 'post',
    data
  })
}
// 用户详情 - 上级更改记录
export function reqUserChangeParentLog(data) {
  return request({
    url: '/api/user/change_parent_log',
    method: 'post',
    data
  })
}

// 用户标签列表
export function getLabelList(data) {
  return request({
    url: '/api/level/list',
    method: 'post',
    data
  })
}

// 用户标签详情
export function getLabelInfo(data) {
  return request({
    url: '/api/level/info',
    method: 'post',
    data
  })
}

// 用户标签新增 || 编辑
export function getLabelSave(data) {
  return request({
    url: '/api/level/save',
    method: 'post',
    data
  })
}

// 用户标签 - 删除
export function getLabelRemove(data) {
  return request({
    url: '/api/level/remove',
    method: 'post',
    data
  })
}

// 用户 - 获取上级树
export function getUserTree(data) {
  return request({
    url: '/api/user/tree',
    method: 'post',
    data
  })
}

// 用户 - 子身份
export function subSave(data) {
  return request({
    url: '/api/user/sub_save',
    method: 'post',
    data
  })
}

// 用户详情 - 订单列表
export function getSubList(data) {
  return request({
    url: '/api/order/sub_list',
    method: 'post',
    data
  })
}

// 用户详情 - 修改行政区
export function changeAddressCode(data) {
  return request({
    url: '/api/user/change_address_code',
    method: 'post',
    data
  })
}

// 用户详情 - 余额修改（待修改）
export function reqUserChangeBalance(data) {
  return request({
    url: 'admin/user/changeBalance',
    method: 'post',
    data
  })
}
