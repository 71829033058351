var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box",
        },
        [
          !_vm.loading
            ? _c(
                "el-col",
                {
                  key: _vm.resetKey,
                  staticClass: "box_left",
                  attrs: { span: 6 },
                },
                [
                  _c("profile-info", {
                    attrs: { user: _vm.user },
                    on: { editUserInfo: _vm.editUserInfo },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.loading
            ? _c(
                "el-col",
                {
                  key: _vm.resetKey + 1,
                  staticClass: "box_right",
                  attrs: { span: 18 },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("basic-info", {
                        attrs: { user: _vm.user },
                        on: { editUserInfo: _vm.editUserInfo },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.tabClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        _vm._l(_vm.tabData, function (item) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: item.id,
                              attrs: { label: item.name, name: item.name },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                      _c("sub-list", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName === "子身份",
                            expression: "activeName === '子身份'",
                          },
                        ],
                        attrs: { user: _vm.user },
                        on: { editUserInfo: _vm.editUserInfo },
                      }),
                      _c("order-list", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName === "进货订单",
                            expression: "activeName === '进货订单'",
                          },
                        ],
                        ref: "order",
                        attrs: { user_id: _vm.id },
                      }),
                      _c("dividendOrders", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName === "分红订单",
                            expression: "activeName === '分红订单'",
                          },
                        ],
                        ref: "dividendOrder",
                        attrs: { user_id: _vm.id },
                      }),
                      _c("user-tree", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName === "上下级",
                            expression: "activeName === '上下级'",
                          },
                        ],
                        ref: "userList",
                        attrs: { user: _vm.user },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }