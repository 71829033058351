<template>
  <div class="app-container">
    <!-- <tinymce-antd :height="300" /> -->

    <el-card class="card-box" shadow="always">
      <div slot="header" class="">
        <span>柱状图</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <div>
        <BarChart height="300px" />
      </div>
    </el-card>

    <el-card class="card-box" shadow="always">
      <div slot="header" class="">
        <span>环形图 - 1</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <div>
        <PieChart height="300px" />
      </div>
    </el-card>

    <el-card class="card-box" shadow="always">
      <div slot="header" class="">
        <span>环形图 - 2</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <div>
        <PieTitleChart height="300px" />
      </div>
    </el-card>

    <el-card class="card-box" shadow="always">
      <div slot="header" class="">
        <span>排行榜（表格）</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <div>
      </div>
    </el-card>

    <el-card class="card-box" shadow="always">
      <div slot="header" class="">
        <span>数量统计 - 名片样式</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <div>
      </div>
    </el-card>

    <el-card class="card-box" shadow="always">
      <div slot="header" class="">
        <span>折线图</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <div>
      </div>
    </el-card>

  </div>
</template>

<script>

// 柱状图
import BarChart from '@/views/dashboard/echarts-components/BarChart.vue'

// 环形图 - 1
import PieChart from '@/views/dashboard/echarts-components/PieChart.vue'
// 环形图 - 2 - 中间带字的 
import PieTitleChart from '@/views/dashboard/echarts-components/PieTitleChart.vue'


export default {
  name: 'index',
  components: {
    BarChart,
    PieChart,
    PieTitleChart,

  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
.card-box {
  margin-bottom: 20px;
}
</style>

