<template>
  <div class="app-container">
    <!-- 数据看板 -->
    <div class="user_header">
      <div class="user_header_item">
        <span>累计提现（元）:</span>
        <span class="user_header_item_span">{{ $thousandth(overall.withdraw_amount, 1) }}</span>
      </div>
      <div class="user_header_item">
        <span>待提现余额（元）:</span>
        <span class="user_header_item_span">{{ $thousandth(overall.wait_withdraw_total, 1) }}</span>
      </div>
    </div>

    <div class="sys_search_line"></div>

    <el-tabs v-model="activeName" type="card" :tab-position="tabPosition" :before-leave="handleLeaveTab">
      <el-tab-pane label="提现记录" name="0">
        <!-- 搜索 -->
        <query-form :queryForm="withdrawQueryForm" :queryData="withdrawQueryData" @handleQuery="withdrawHandleQuery" @handleReset="withdrawHandleQuery" />
        <div class="sys_search_line"></div>

        <el-button :loading="exportLoading" :type="exportLoading ? 'danger' : 'primary'" size="small" @click="exportOrderApiFn">
          {{ exportLoading ? "导出中" : "导出账单" }}
        </el-button>
        <div class="sys_search_line"></div>

        <el-table stripe v-loading="loading" :data="withdraw_list.data" empty-text="暂无数据" element-loading-text="加载中..." :header-cell-style="{backgroundColor: '#fafafa',color: '#606266'}" key="table1">
          <!-- 空数据状态的插槽 -->
          <template slot="empty">
            <el-empty :image-size="100" description="暂无数据"></el-empty>
          </template>
          <el-table-column label="流水号" prop="order_no" width="240" fixed />
          <el-table-column label="提现用户" width="280">
            <template slot-scope="{row}">
              <div style="display: flex;align-items: center;">
                <el-image v-if="row.userBase.avatar" style="width: 50px;height: 50px;border-radius: 50%;" :src="row.userBase.avatar" :preview-src-list="[row.userBase.avatar]" fit="cover" />

                <div style="margin-left: 10px;">
                  <el-link v-if="row.userBase.nickname" type="primary" @click="onPageJump(row.user_id)">{{ row.userBase.nickname ? row.userBase.nickname : '--' }}</el-link>
                  <span v-else>--</span>
                  <div>{{ row.userBase.phone ? row.userBase.phone : '--' }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="品牌" width="150">
            <template slot-scope="{row}">
              <span>{{ getUserBrand(row.userInfo.brand_id) }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="提现金额" width="120">
            <template slot-scope="{row}">￥{{$thousandth(row.withdraw_amount,1)}}</template>
          </el-table-column>
          <el-table-column label="手续费" width="260">
            <template slot-scope="{row}">
              <div>
                <span style="margin-right: 20px;">平台：￥{{$thousandth(row.platform_tax,1)}}</span>
                <span>微信：￥{{$thousandth(row.withdraw_tax,1)}}</span>
              </div>
              <div>总计：¥{{ getTotalFn(row.platform_tax, row.withdraw_tax) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="打款金额" width="120">
            <template slot-scope="{row}">￥{{$thousandth(row.withdraw_final_money,1)}}</template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="{row}">
              <el-button type="text" @click="lockRemarkFn(row.remark)" v-if="row.remark">查看备注</el-button>
              <span v-if="!row.remark">暂无备注</span>
            </template>
          </el-table-column>
          <el-table-column label="一审" prop="first_audit" width="100">
            <template slot-scope="{row}">
              <el-tag v-if="row.first_audit == 2">待审核</el-tag>
              <el-tag v-if="row.first_audit == 1" type="success">通过</el-tag>
              <el-tag v-if="row.first_audit == 3" type="danger">不通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" width="100">
            <template slot-scope="{row}">
              <el-tag v-if="row.status == 1">打款中</el-tag>
              <el-tag v-if="row.status == 2">审核中</el-tag>
              <el-tag v-if="row.status == 6" type="success">已提现</el-tag>
              <el-tag v-if="row.status == 3" type="danger">已拒绝</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="发起时间" prop="created_at" width="200" />
          <el-table-column label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link operation">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="getInfo(scope.row.id)">
                    <span>详情</span>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item @click.native="getAcctQuery(scope.row.id)" v-if="userIdent">
                    <span>汇付余额到账</span>
                  </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="withdraw_list.total" :page.sync="withdraw_list.current_page" :limt.sync="withdraw_list.per_page" @pagination="withdrawPagination" key="pagin1" />
      </el-tab-pane>
      <el-tab-pane label="审核权限" name="1">
        <!-- <Role></Role> -->
      </el-tab-pane>
      <el-tab-pane label="提现配置" name="2">
        <el-form ref="ruleForm" :model="form" :rules="rules" :disabled="showWithdraw" label-width="120px">
          <el-form-item label="平台手续费(%)" prop="withdraw_tax">
            <el-input v-model="form.withdraw_tax" placeholder="请输入平台手续费(%)" />
          </el-form-item>
          <el-form-item label="微信手续费(%)" prop="platform_tax">
            <el-input v-model="form.platform_tax" placeholder="请输入微信手续费(%)" />
          </el-form-item>
          <el-form-item label="最小提现金额" prop="withdraw_minmoney">
            <el-input v-model="form.withdraw_minmoney" placeholder="请输入最小提现金额" />
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="saveWithdraw()">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane label="余额变更记录" name="1">
        <page-filter :data="balanceFilterData" :count="pf_count" :field-list="balanceFieldList" @handleFilter="handleBalanceFilter" @handleReset="handleBalanceReset"></page-filter>
        <div class="sys_search_line"></div>
        <el-table stripe v-loading="loading" :data="balance_list.data" empty-text="暂无数据" element-loading-text="加载中..." :default-sort="{prop: 'date', order: 'descending'}" :header-cell-style="{backgroundColor: '#fafafa',color: '#606266'}" key="table2">

          <template slot="empty">
            <el-empty :image-size="100" description="暂无数据"></el-empty>
          </template>

          <el-table-column label="用户信息" width="350">
            <template slot-scope="{row}">
              <div style="float: left;margin-right: 10px;">
                <el-image style="width: 75px; height: 75px" :src="row.userBase.avatar" :preview-src-list="[row.userBase.avatar]" fit="cover" />
              </div>
              <div style="float: left;margin-right: 10px;">
                <div>ID：{{ row.user_id}}</div>
                <div>用户名称：{{ row.userBase.nickname}}</div>
                <div>用户手机：{{ row.userBase.phone}}</div>
              </div>
              <div style="clear: both"></div>
            </template>
          </el-table-column>
          <el-table-column label="品牌" width="150">
            <template slot-scope="{row}">
              <span>{{ getUserBrand(row.userInfo.brand_id) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="标题" prop="title" />
          <el-table-column label="详情" prop="desc" />
          <el-table-column label="类型" prop="type" width="80">
            <template slot-scope="{row}">
              <el-tag v-if="row.type == 1" type="success">增加</el-tag>
              <el-tag v-if="row.type == 2" type="danger">减少</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="金额"  >
            <template slot-scope="{row}">
              <span>{{$thousandth(row.balance,1)  }}</span>
            </template>
          </el-table-column>
          <el-table-column label="发起时间" prop="created_at" />
        </el-table>
        <pagination :total="balance_list.total" :page.sync="balance_list.current_page" :limt.sync="balance_list.per_page" @pagination="balancePagination" key="pagin2" />
      </el-tab-pane> -->
    </el-tabs>

    <el-dialog title="备注" :visible.sync="dialog">
      <el-input type="textarea" autosize disabled v-model="remark" />
    </el-dialog>

    <el-dialog title="汇付余额到账" :visible.sync="acctDialog">
      <el-descriptions border v-for="(item, key, index) in queryData" :column="1" :key="index" :labelStyle="labelStyle">
        <el-descriptions-item :label="key">{{ item }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStatisticsBill,
  financeUserWithdraw,
  financeUserBalance,
  reqAcctpaymentPayQuery
} from "@/api/finance";
import { getSystemConfig, getSystemConfigSave } from "@/api/setting";
import { parseTime, formatBankCard } from "@/utils";
import table2excel from "js-table2excel";
import Pagination from "@/components/Pagination/index.vue";
// import Role from "@/views/finance/role/index.vue";

export default {
  name: "FinanceFill",

  components: {
    Pagination,
    // Role
  },

  data() {
    return {
      userIdent: false,

      loading: false,
      tabPosition: "top", // tabs布局
      activeName: "0", // 切换名称
      pf_count: 3,
      // 统计数据
      overall: {
        all_settlement_amount: 0,
        today_settlement_amount: 0,
        wait_withdraw_total: 0,
        withdraw_ammount: 0
      },

      balance_list: {},
      balanceForm: {
        page: 1,
        page_size: 10
      },

      // 提现数据
      withdraw_list: {},
      // 提现搜索条件
      withdrawQueryData: {
        page: 1,
        page_size: 10,
        search_id: null,
        search_name: null,
        search_phone: null,
        search_status: null
      },
      // 搜索框
      withdrawQueryForm: [
        {
          type: "input",
          label: "用户ID",
          model: "search_id",
          placeholder: "请输入用户ID"
        },
        {
          type: "input",
          label: "用户名称",
          model: "search_name",
          placeholder: "请输入用户名称"
        },
        {
          type: "input",
          label: "手机号",
          model: "search_phone",
          placeholder: "请输入手机号"
        },
        {
          type: "select",
          label: "打款状态",
          model: "search_status",
          placeholder: "请选择状态",
          clearable: true,
          options: [
            { id: 1, name: "打款中" },
            { id: 2, name: "审核中" },
            { id: 3, name: "已拒绝" },
            { id: 6, name: "已提现" }
          ]
        }
      ],

      balanceFilterData: {
        search_id: "",
        search_name: "",
        search_phone: ""
      },
      // 搜索条件
      balanceFieldList: [
        { label: "用户ID", type: "input", value: "search_id" },
        { label: "用户名称", type: "input", value: "search_name" },
        { label: "手机号", type: "input", value: "search_phone" }
      ],

      // 品牌数据
      brandDataAll: [],

      // 备注
      remark: null,
      dialog: false,

      // 汇付余额到账
      queryData: {},
      acctDialog: false,
      labelStyle: {
        width: "200px"
      },

      // 订单导出字段
      exportLoading: false,
      exportExcelData: [],
      exportPage: 1,
      lastPage: 10, // 最后一页

      // 提现配置
      form: {
        withdraw_tax: 0,
        platform_tax: 0,
        withdraw_minmoney: 0,
      },
      // 表单校验规则
      rules: {
        withdraw_tax: [{ required: true, message: '请输入', trigger: 'blur' }],
        platform_tax: [{ required: true, message: '请输入', trigger: 'blur' }],
        withdraw_minmoney: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      // 允许修改提现配置
      showWithdraw: true,
    };
  },
  watch: {
    $route(to) {
      const { path, query } = to;
      if (path == "/finance/financeFill " && query.type == "refresh") {
        this.init();
      }
    }
  },
  created() {
    this.init();

    // const { roles } = this.$store.getters;
    // roles.find((v) => {
    //   if (v.name == "出纳" || v.name == "超级管理员") {
    //     this.showWithdraw = false;
    //   }
    // });
  },
  computed: {
    getTotalFn() {
      return function(platform, withdraw) {
        let price = this.$sumJs.accDiv(platform + withdraw, 100);
        price = parseFloat(price.toFixed(2));
        return this.$thousandth(price);
      };
    },
    // getUserBrand() {
    //   return function(id) {
    //     let val;
    //     this.brandDataAll && this.brandDataAll.length
    //       ? (val = this.brandDataAll.find(item => item.id == id).name)
    //       : (val = "");
    //     return val;
    //   };
    // },
    getAuditStatus() {
      return function(status) {
        let str = "待审核";
        if (status == 1) str = "通过";
        if (status == 3) str = "不通过";
        return str;
      };
    },
    getBillStatus() {
      return function(status) {
        let str = "审核中";
        if (status == 1) str = "打款中";
        if (status == 3) str = "已拒绝";
        if (status == 6) str = "已提现";
        return str;
      };
    }
  },
  methods: {
    parseTime,
    formatBankCard,

    async init() {
      // await this.getUserIdent();
      await this.getBill();
      // await this.getSystemBrand();
      await this.getWithdrawConfig();
      // 提现
      if (Number(this.activeName) == 0) {
        this.getWithdrawList();
      }
      // 余额
      // if (Number(this.activeName) == 1) {
      //   this.getBalanceList();
      // }
    },

    // 获取用户身份
    // getUserIdent() {
    //   const { roles } = this.$store.getters;
    //   roles.find(v => {
    //     if (v.id === 1 || v.id === 4) {
    //       this.userIdent = true;
    //     }
    //   });
    // },

    // tag切换前回调
    handleLeaveTab(newVal) {
      // 切换tag
      this.activeName = newVal;

      // 提现
      if (Number(newVal) == 0) {
        this.getWithdrawList();
      }
      // 余额
      if (Number(newVal) == 1) {
        this.getBalanceList();
      }
    },

    // 获取统计数据
    getBill() {
      getStatisticsBill().then(res => {
        this.overall = res.result;
      });
    },

    // 获取所有品牌信息
    // getSystemBrand() {
    //   getSystemBrandAll().then(resp => {
    //     const res = resp.result;
    //     const systemBrand = this.$store.getters.systemBrand;
    //     // res所有品牌数据   systemBrand可查看品牌数据
    //     this.brandData = res.filter(v =>
    //       systemBrand.some(val => val.id == v.id)
    //     );
    //     this.brandDataAll = res;
    //   });
    // },

    // 获取提现配置
    getWithdrawConfig() {
      getSystemConfig().then(async res => {
        const { id, value } = await res.result.find(item => item.key == "withDrawConfig");
        this.form = {
          id,
          ...value
        }
      })
    },

    // 提现记录列表数据
    getWithdrawList() {
      this.loading = true;
      financeUserWithdraw(this.withdrawQueryData).then(resp => {
        this.withdraw_list = resp.result;
        this.loading = false;
      });
    },

    // 提现记录分页点击事件
    withdrawPagination(data) {
      this.withdrawQueryData = { ...this.withdrawQueryData, ...data };
      this.getWithdrawList();
    },

    // 提现记录搜索
    withdrawHandleQuery(queryModel) {
      this.withdrawQueryData = queryModel;
      this.getWithdrawList();
    },

    // 提现记录详情
    getInfo(id) {
      this.$router.push({
        path: "financeFillInfo",
        query: {
          id
        }
      });
    },

    // 汇付余额查询
    getAcctQuery(id) {
      reqAcctpaymentPayQuery({ id }).then(res => {
        this.queryData = res.result.data;
        this.acctDialog = true;
      });
    },

    // 余额变更列表数据
    getBalanceList() {
      this.loading = true;
      financeUserBalance(this.balanceForm).then(resp => {
        this.balance_list = resp.result;
        this.loading = false;
      });
    },

    // 余额变更搜索
    handleBalanceFilter(row) {
      this.balanceForm = Object.assign(this.balanceForm, row);
      this.getBalanceList();
    },

    // 余额变更重置
    handleBalanceReset(row) {
      this.balanceForm = Object.assign(this.balanceForm, row);
      this.getBalanceList();
    },

    // 余额变更分页点击事件
    balancePagination(data) {
      this.balanceForm = { ...this.balanceForm, ...data };
      this.getBalanceList();
    },

    // 跳转用户
    onPageJump(id) {
      this.$router.push({
        path: "/member/userInfo",
        query: {
          id
        }
      });
    },

    // 查看备注
    lockRemarkFn(remark) {
      this.remark = remark;
      this.dialog = true;
    },

    // 导出账单
    async exportOrderApiFn() {
      let that = this;

      that.exportLoading = true;
      const loading = this.$fullLoading("导出中");
      if (that.exportPage > that.lastPage) {
        await that.exportOrderExcel();
        setTimeout(() => {
          that.exportLoading = false;
          //请求完成后关闭
          loading.close();
          that.exportPage = 1;
          that.exportExcelData = [];
        }, 1000);
        return;
      }

      const { search_id, search_name, search_phone, search_status } = {
        ...that.withdrawQueryData
      };
      let obj = {
        page: that.exportPage,
        page_size: 100,
        search_id,
        search_name,
        search_phone,
        search_status
      };

      financeUserWithdraw(obj).then(res => {
        console.log(res, "res");
        const { data } = res.result;

        if (!data.length) {
          that.exportLoading = false;
          return that.$message.warning("暂无账单数据！");
        }

        that.lastPage = res.result.last_page;
        that.exportExcelData = that.exportExcelData.concat(data);
        that.exportPage++;

        that.exportOrderApiFn();
      });
    },

    // 导出表单
    exportOrderExcel() {
      let column = [
        { title: "ID", key: "id", type: "text" },
        { title: "流水号", key: "order_no", type: "text" },
        { title: "用户昵称", key: "user_nickname", type: "text" },
        { title: "手机号", key: "user_phone", type: "text" },
        { title: "品牌", key: "user_brand", type: "text" },
        { title: "提现金额", key: "myt_withdraw_amount", type: "text" },
        { title: "平台手续费", key: "myt_platform_tax", type: "text" },
        { title: "微信手续费", key: "myt_withdraw_tax", type: "text" },
        { title: "合计手续费", key: "myt_all_tax", type: "text" },
        { title: "打款金额", key: "myt_withdraw_final_money", type: "text" },
        { title: "打款银行卡号", key: "bank_card", type: "text" },
        { title: "银行名称", key: "bank_name", type: "text" },
        { title: "支行名称", key: "bank_branch", type: "text" },
        { title: "持卡人姓名", key: "bank_username", type: "text" },
        { title: "持卡人手机号", key: "bank_phone", type: "text" },
        { title: "一审状态", key: "first_audit", type: "text" },
        { title: "二审状态", key: "second_audit", type: "text" },
        { title: "状态", key: "status", type: "text" },
        { title: "发起时间", key: "created_at", type: "text" },
        { title: "备注", key: "remark", type: "text" }
      ];
      const tableData = JSON.parse(JSON.stringify(this.exportExcelData));
      const data = this.formatJson(tableData);
      table2excel(column, data, "账单数据");
    },

    formatJson(data) {
      return data.map(item => {
        let all_tax =
          JSON.parse(JSON.stringify(item.platform_tax)) +
          JSON.parse(JSON.stringify(item.withdraw_tax));

        if (item.userBase) {
          item.user_nickname = item.userBase.nickname;
          item.user_phone = item.userBase.phone;
        }
        // if (item.userInfo) {
        //   item.user_brand = this.getUserBrand(item.userInfo.brand_id);
        // }
        item.myt_withdraw_amount = this.$thousandth(item.withdraw_amount, 1);
        item.myt_platform_tax = this.$thousandth(item.platform_tax, 1);
        item.myt_withdraw_tax = this.$thousandth(item.withdraw_tax, 1);
        item.myt_all_tax = this.$thousandth(all_tax, 1);
        item.myt_withdraw_final_money = this.$thousandth(
          item.withdraw_final_money,
          1
        );
        item.bank_card = item.userHuifuId
          ? this.formatBankCard(item.userHuifuId.card_no, " ")
          : "";
        item.bank_name = item.userBank ? item.userBank.bank : "";
        item.bank_branch = item.userBank ? item.userBank.bank_of_deposit : "";
        item.bank_username = item.userHuifuId ? item.userHuifuId.cert_name : "";
        item.bank_phone = item.userHuifuId ? item.userHuifuId.phone : "";
        item.first_audit = this.getAuditStatus(item.first_audit);
        item.second_audit = this.getAuditStatus(item.second_audit);
        item.status = this.getBillStatus(item.status);
        item.remark = item.remark ?? "";
        return item;
      });
    },

    // 保存提现配置
    saveWithdraw() {
      const that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.$messageConfirm("确认保存配置?", () => {
            const { id, platform_tax, withdraw_minmoney, withdraw_tax } = this.form;
            let httpData = {
              id,
              value: {
                platform_tax,
                withdraw_minmoney,
                withdraw_tax,
              }
            }
            console.log('httpData --->', httpData);
            getSystemConfigSave(httpData).then((res) => {
              that.$messageNotify(res);
              that.getWithdrawConfig();
            });
          })
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.user_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 2px;
  padding: 24px;
  margin-top: 10px;
  .user_header_item {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #dfdfdf;
    text-align: center;
    flex: 1;
    font-size: 14px;
    .user_header_item_span {
      margin-top: 6px;
      font-size: 24px;
    }
  }

  .user_header_item:last-of-type {
    border-right: 0;
  }
}

.operation {
  color: #1890ff;
  cursor: pointer;
}

::v-deep .el-tabs__header {
  margin-bottom: 0 !important;
}

::v-deep .el-tabs__content {
  padding: 20px 0 0;
  border-top: 0;
}

.container {
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  border: 1px solid #e8e8e8;
}
.tab_dropdown {
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 4px 4px 0 0;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  .tab_dropdown_title {
    font-size: 14px;
    span {
      color: #1890ff;
      font-weight: bold;
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #1890ff;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right_btn {
  display: flex;
  align-items: center;
}

.f_l {
  float: left;
}
.f_r {
  float: right;
}
.colorblue {
  color: #9e9e9e;
}
.oi_table,
.oi_tables {
  display: flex;
  align-items: center;
  height: 120px;
  border-right: 1px solid #e5e5e5;
}
.oi_table:last-child,
.oi_tables:last-child {
  border-right: 0;
}
.oi_table_bottom {
  border-top: 1px solid #e5e5e5;
  margin-top: -1px;
}
.oi_table {
  justify-content: center;
}
.oi_tables {
  justify-content: flex-end;
  padding-right: 20px;
}

.oit_tb_last {
  border-right: none !important;
}
.text_alc {
  text-align: center;
}
.text_alr {
  text-align: right;
}
.order_table_header {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  line-height: 45px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  .oth_brd {
    border-right: 1px solid #fff;
  }
  .oth_brd_last {
    border-right: none;
  }
}
.order_main {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  .order_item_header {
    height: 45px;
    background: #f5f5f5;
    line-height: 45px;
    padding: 0 10px;

    .order_txt {
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        margin-right: 10px;
        color: #9e9e9e;
      }
    }
    .order_btn {
      text-align: right;
    }
  }

  .order_item_main {
    border-top: 1px solid #e5e5e5;
    .oim_goods {
      display: flex;
      padding-left: 10px;
      .og_title {
        margin-left: 10px;
        line-height: 20px;
        width: 68%;
        .ogt_title,
        .ogt_sku {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
        }
        .ogt_sku {
          text-align: left;
          color: #1890ff;
          margin-top: 5px;
        }
      }
    }
  }
}

.oit_tb_flex {
  display: flex;
}
</style>
