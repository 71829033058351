<template>
  <div class="app-container">
    <!-- 搜索 -->
    <query-form :queryForm="queryForm" :queryData="queryData" @handleQuery="handleQuery" @handleReset="handleQuery" />

    <div class="sys_search_line">
      <el-button type="primary" @click="getInfo(0)">添加账号</el-button>
    </div>

    <el-tabs v-model="tabsIndex" type="card" @tab-click="handleTabs">

      <el-tab-pane :label="item.name" :name="item.name" v-for="(item, index) in tabsData" :key="index">
        <div class="dot" slot="label">
          {{ item.name }}
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-table stripe v-loading="loading" :data="pageData" element-loading-text="加载中..."
      :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }">

      <!-- 空数据状态的插槽 -->
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>

      <el-table-column label="ID" width="100" fixed>
        <template slot-scope="{row}">{{ row.id }}</template>
      </el-table-column>

      <el-table-column label="昵称" fixed width="150">
        <template slot-scope="{row}">{{ row.nickname }}</template>
      </el-table-column>

      <el-table-column label="账号" width="150">
        <template slot-scope="{row}">{{ row.username }}</template>
      </el-table-column>

      <el-table-column label="角色" width="200">
        <template slot-scope="scope">
          <el-tag v-for="(item, index) in scope.row.roles" :key="index" style="margin-right: 10px;">{{
            item.name
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" width="250">
        <template slot-scope="scope">{{ scope.row.created_at }}</template>
      </el-table-column>

      <el-table-column label="状态" sortable>
        <template slot-scope="scope">
          <el-switch class="el_switch_small" :value="scope.row.status" @change="switchChange(scope.row)"
            :active-value="1" :inactive-value="0" active-text="开启" inactive-text="关闭" />
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link operation">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item divided>
                <span @click="getInfo(scope.row.id)">编辑</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="getRemove(scope.row.id)">删除</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination :total="pageSize.total" :page.sync="pageSize.current_page" :limt.sync="pageSize.per_page" @pagination="pagination" />
  </div>
</template>

<script>
import { getAdminList, getAdminStatusChange, getAdminRemove } from '@/api/setting';

import Pagination from '@/components/Pagination/index.vue'

export default {
  name: 'rolesList',

  components: {
    Pagination
  },

  data() {
    return {
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 10,
        search_id: "",
        search_nickname: "",
        search_brand_id: 0,
      },
      // 搜索框
      queryForm: [
        { type: "input", label: "ID", model: "search_id", placeholder: "请输入ID" },
        { type: "input", label: "昵称", model: "search_nickname", placeholder: "请输入昵称" },
      ],

      // 页面数据
      pageData: [],
      pageSize: {},
      tabsData: [],
      tabsIndex: '全部',

      // 页面状态
      loading: false,

      listTypeInfo: {
        categoryList: [],
        statusList: [
          { id: 1, name: '上架' },
          { id: 2, name: '下架' },
        ]
      },
    }
  },

  created() {
    this.init()
  },

  methods: {

    async init() {
      await this.getList()
      // await this.getBrandAll()
    },

    getList() {
      this.loading = true
      getAdminList(this.queryData).then(resp => {
        const { data, total, current_page, per_page } = resp.result;
        this.pageData = data;
        this.pageSize = { total, current_page, per_page }
        this.loading = false;
      })
    },

    // 查询按钮
    handleQuery(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 分页点击事件
    pagination(data) {
      this.queryData = { ...this.queryData, ...data };
      this.getList();
    },

    // 切换tabs
    handleTabs(tab, event) {
      let index = Number(tab.index);
      this.queryData.search_brand_id = index;
      this.getList();
    },

    // 上 / 下架状态
    switchChange(item) {
      this.$messageConfirm(`确认${item.status == 1 ? '开启' : '关闭'}`, () => {
        let httpData = {
          id: item.id,
          status: item.status == 0 ? 1 : 0
        }
        getAdminStatusChange(httpData).then(res => {
          this.$messageNotify(res);
          item.status == 0 ? item.status = 1 : item.status = 0;
        })
      })
    },

    // 新增 || 编辑
    getInfo(id) {
      this.$router.push({
        path: 'accountInfo',
        query: {
          id
        }
      })
    },

    // 删除
    getRemove(id) {
      this.$messageConfirm(`确认删除?`, () => {
        getAdminRemove({
          id
        }).then(res => {
          this.$messageNotify(res);
          this.getList()
        })
      })
    },

  }
}
</script>

<style scoped>
.operation {
  color: #1890ff;
  cursor: pointer;
}

.tag_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag {
  margin: 0 10px 10px 0;
}
</style>
