<template>
  <el-row class="userTree" :gutter="20">
    <el-col :span="8">
      <el-timeline :reverse="true">
        <div v-for="(item, index) in userTree" :key="index">
          <el-timeline-item
            reverse="true"
            v-if="item"
            icon="el-icon-user"
            type="primary"
            size="large"
            placement="top"
            :timestamp="item.real_name"
          >
            <el-card class="treeCard">
              <p>id:{{ item.id }}</p>
              <p>
                行政区:{{ item.province + "-" + item.city + "-" + item.area }}
              </p>
              <p>等级身份:{{ item.system_agent_name }}</p>
            </el-card>
          </el-timeline-item>
        </div>
      </el-timeline>
    </el-col>
    <el-divider
      v-if="userTree.length"
      direction="vertical"
      class="divider"
    ></el-divider>
    <el-col :span="userTree.length ? 16 : 24">
      <el-table
        ref="userTable"
        v-loading="loading"
        stripe
        border
        :row-key="getRowKeys"
        :data="listData"
        element-loading-text="加载中..."
        :max-height="500"
        :tree-props="{ children: 'children' }"
        :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
        @expand-change="expandSelect"
      >
        <!-- 空数据状态的插槽 -->
        <template slot="empty">
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>

        <el-table-column label="ID" prop="id" fixed width="160" />

        <el-table-column label="姓名" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>{{ row.base.real_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>{{ row.phone }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="性别" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>{{ getGender(row.gender) }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="上级" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-button type="text">{{
              getParentName(row.parent_id)
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="行政区" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>{{
              row.base.province + "-" + row.base.city + "-" + row.base.area
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="代理等级" width="160">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.base.system_agent_id">{{
              scope.row.base.system_agent_name
            }}</el-tag>
            <span v-else> - </span>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import { getLabelList, getUserList, getUserTree } from "@/api/member";

import { flexColumnWidth } from "@/utils/table";

import Pagination from "@/components/Pagination/index.vue";
import axios from "axios";
import { getArea } from "@/utils/addrFilter";
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      listData: [],
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 500,
        search_sub: 0,
        search_id: "",
        search_name: "",
        search_real_name: "",
        search_phone: "",
      },

      genderList: [
        { id: 0, title: "未知" },
        { id: 1, title: "男" },
        { id: 2, title: "女" },
      ],
      //行政区下拉选项
      xzqList: [],
      defaultProps: {
        label: "value",
        value: "code",
        children: "children",
      },
      // 代理数据
      agentData: [],

      //用户列表数据
      userList: [],
      userTree: [],
      expands: [],
      getRowKeys(row) {
        return row.id;
      },
    };
  },

  computed: {
    getGender() {
      return function (id) {
        let ele = this.genderList.find((item) => item.id == id);
        let gender = "";
        if (ele) {
          gender = ele.title;
        }
        return gender;
      };
    },
    getParentName() {
      return function (id) {
        let ele = this.userList.find((item) => item.id == id);
        let name = "-";
        if (ele) {
          name = ele.base.real_name;
        }
        return name;
      };
    },
  },
  mounted() {
    this.getAgentList();
    this.getXzqList();
  },
  methods: {
    flexColumnWidth,
    init() {
      this.getUserTree();
      this.getUserList();
    },
    getAgentList() {
      this.queryData = this.$options.data().queryData;
      getLabelList(this.queryData).then((res) => {
        const { data, total, current_page, per_page } = res.result;
        this.agentData = data;
      });
    },
    getXzqList() {
      const url = "/addr.json";
      axios
        .get(url)
        .then((response) => {
          this.xzqList = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    // 获取全部用户列表数据
    getUserList() {
      getUserList(this.queryData).then((resp) => {
        this.userList = resp.result.data;
        this.listData = this.getChildren(this.userList, this.user.id);
      });
    },

    //获取等级树(更换店家时)
    getUserTree() {
      this.userTree = [];
      getUserTree({ user_id: this.user.id })
        .then((res) => {
          if (res.result) {
            this.userTree = res.result.filter(
              (item) =>
                item !== null && typeof item !== "undefined" && item !== ""
            );
          }
        })
        .catch((e) => {
          this.userTree = [];
        });
    },

    getChildren(list, parentId) {
      let result = []; // 存放结果的数组
      result = list.filter((item) => item.parent_id == parentId);
      result.forEach((ele) => {
        const children = this.getChildren(list, ele.id);
        if (children.length > 0) {
          ele.children = children;
          // result = [...result, ...children]; // 合并子节点到结果中
        }
      });
      return result;
    },
    expandSelect(row, expandedRows) {
      if(expandedRows){
        this.handleArr(row.children, true);
      }
    },
    handleArr(arr) {
      arr.forEach((item) => {
        this.$refs.userTable.toggleRowExpansion(item, true);
        if (item.children) {
          this.handleArr(item.children);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.userTree {
  ::v-deep .el-card__body {
    padding: 0 10px;
  }
  ::v-deep .el-timeline-item__timestamp {
    color: #409eff;
    font-size: 15px;
  }

  .divider {
    height: 100%;
    margin: 0;
    position: absolute;
  }
}
</style>
