<template>
  <div class="app-container">
    <page-header :content="form.id ? '编辑' : '新增'" back-path="accountList" v-loading.fullscreen.lock="loading" @saveClick="onSubmit"/>

    <el-form :model="form" ref="ruleForm" :rules="rules" label-width="180px" style="margin-top: 24px;">

      <el-form-item label="昵称:" prop="nickname">
        <el-input v-model="form.nickname" placeholder="请输入昵称" />
      </el-form-item>

      <el-form-item label="账号:" prop="username">
        <el-input v-model="form.username" placeholder="请输入账号" />
      </el-form-item>

      <el-form-item label="密码:" prop="password">
        <el-input v-model="form.password" type="password" auto-complete="new-password" placeholder="请输入密码" />
      </el-form-item>

      <el-form-item label="确认密码:" prop="password_confirm">
        <el-input v-model="form.password_confirm" type="password" auto-complete="new-password" placeholder="请确认密码" />
      </el-form-item>

      <el-form-item label="角色" prop="roles">
        <el-select v-model="form.roles" multiple placeholder="选择角色">
          <el-option
            v-for="item in rolesData"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态:" prop="status">
        <el-switch class="el_switch" v-model="form.status" @change="switchChange" :active-value="1" :inactive-value="0" active-text="开" inactive-text="关"></el-switch>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
  import PageHeader from '@/components/PageHeader/index'
  import { getAdminInfo, getAdminSave } from '@/api/setting';
  import { getRoleAll } from '@/api/public';
  export default {
    name: 'accountInfo',

    components: {
      PageHeader
    },

    data() {
      return {
        loading: false,
        btnStatus: true,
        form: {
          id: 0,
          nickname: '',
          username: '',
          password: '',
          password_confirm: '',
          roles: [],
          brand_id: '',
          status: 1,
          systemBrand: [],
        },
        // 表单校验规则
        rules: {
          nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
          username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          password_confirm: [{ required: true, message: '请确认密码', trigger: 'blur' }],
          roles: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        },

        rolesData: [], // 角色数据
        brandData: [], // 品牌数据
      }
    },

    created() {
      if(this.$route.query.id){
        this.form.id = this.$route.query.id;
      }
      this.getSelectData();
      if (this.form.id > 0) {
        this.getInfo();
      }
      this.btnStatus = true;
    },

    methods: {
      // 获取详情
      getInfo() {
        getAdminInfo({ id: this.form.id }).then(res => {
          this.form = res.result;
        })
      },

      // 获取角色和品牌数据
      getSelectData () {
        // 角色
        getRoleAll().then(res => {
          this.rolesData = res.result;
        })
      },

      switchChange(e) {
        console.log(e);
        this.form.status = e;
      },

      onSubmit() {
        if (!this.btnStatus) return;
        this.btnStatus = false;
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // 处理数据
            getAdminSave(this.form).then(res => {
              this.$messageNotify(res);
              this.$router.push({ path: 'accountList' });
            })
          }
          else {
            this.btnStatus = true;
          }
        })
      }
    }
  }
</script>

<style scoped>
</style>
