import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/assembly/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/assembly/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/assembly/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      title: '首页(未开发)',
      icon: 'home',
      affix: true
    },
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: '店铺总览首页(未开发)' },
      },
    ]
  },

  {
    path: '/goods',
    component: Layout,
    redirect: '/goods/goodsList',
    name: 'Goods',
    meta: {
      title: '商品',
      icon: 'shop'
    },
    children: [
      {
        path: 'goodsList',
        component: () => import('@/views/goods/goodsList/index'),
        name: 'GoodsList',
        meta: { title: '商品管理' }
      },
      {
        path: 'goodsInfo',
        component: () => import('@/views/goods/goodsList/goodsListInfo'),
        hidden: true,
        name: 'GoodsInfo',
        meta: { title: '商品详情', activeMenu: '/goods/goodsList' }
      },
      {
        path: 'goodsCategory',
        component: () => import('@/views/goods/goodsCategory/index'),
        name: 'GoodsCategory',
        meta: { title: '商品分类' }
      }
    ]
  },
  {
    path: '/member',
    component: Layout,
    redirect: '/member/userList',
    name: 'Member',
    meta: {
      title: '用户',
      icon: 'huiyuan'
    },
    children: [
      {
        path: 'userList',
        component: () => import('@/views/member/userList'),
        name: 'UserList',
        meta: { title: '用户管理' }
      },
      {
        path: 'userInfo',
        component: () => import('@/views/member/components/userInfo'),
        hidden: true,
        name: 'UserInfo',
        meta: { title: '用户详情', activeMenu: '/member/userList' }
      },
      {
        path: 'userLabelList',
        component: () => import('@/views/member/userLabelList'),
        name: 'UserLabelList',
        meta: { title: '等级管理' }
      },
      {
        path: 'labelInfo',
        component: () => import('@/views/member/components/labelInfo'),
        hidden: true,
        name: 'LabelInfo',
        meta: { title: '标签详情', activeMenu: '/member/userLabelList' }
      },
      // {
      //   path: 'labelUserList',
      //   component: () => import('@/views/member/labelUserList'),
      //   hidden: true,
      //   name: 'LabelUserList',
      //   meta: { title: '标签用户', activeMenu: '/member/userLabelList' }
      // }
    ]
  },
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance/financeList',
    name: 'Finance',
    meta: {
      title: '财务',
      icon: 'finance'
    },
    children: [
      {
        path: 'financeOrderList',
        component: () => import('@/views/finance/orderList/index'),
        name: 'FinanceOrderList',
        meta: { title: '订单列表' }
      },
      {
        path: 'financeOrderInfo',
        component: () => import('@/views/finance/orderList/info'),
        hidden: true,
        name: 'FinanceOrderInfo',
        meta: { title: '订单详情', activeMenu: '/finance/financeOrderList' }
      },
      {
        path: 'financeFill',
        component: () => import('@/views/finance/bill/index'),
        name: 'FinanceFill',
        meta: { title: '财务账单' }
      },
      {
        path: 'financeFillInfo',
        component: () => import('@/views/finance/bill/info'),
        hidden: true,
        name: 'FinanceFillInfo',
        meta: { title: '账单详情', activeMenu: '/finance/financeFill' }
      },   
    ]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/rolesList',
    name: 'Setting',
    meta: {
      title: '系统',
      icon: 'setting'
    },
    children: [
      {
        path: 'rolesList',
        component: () => import('@/views/setting/rolesList'),
        name: 'RolesList',
        meta: { title: '角色管理' },
      },
      {
        path: 'rolesInfo',
        component: () => import('@/views/setting/components/rolesInfo'),
        hidden: true,
        name: 'RolesInfo',
        meta: { title: '角色详情', activeMenu: '/setting/rolesList' }
      },
      {
        path: 'accountList',
        component: () => import('@/views/setting/accountList'),
        name: 'AccountList',
        meta: { title: '账号管理' }
      },
      {
        path: 'accountInfo',
        component: () => import('@/views/setting/components/accountInfo'),
        hidden: true,
        name: "AccountInfo",
        meta: { title: "账号详情", activeMenu: "/setting/accountList" }
      },
    ]
  }
]



const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
