var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    _vm._b(
      { attrs: { prop: _vm.item.model } },
      "el-form-item",
      _vm.$attrs,
      false
    ),
    [
      _vm.item.type == "input"
        ? _c(
            "el-input",
            _vm._g(
              _vm._b(
                { attrs: { size: _vm.item.size } },
                "el-input",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm.item.type == "select"
        ? _c(
            "el-select",
            _vm._g(
              _vm._b(
                { attrs: { size: _vm.item.size } },
                "el-select",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            _vm._l(_vm.item.options, function (option) {
              return _c(
                "el-option",
                _vm._b(
                  {
                    key: option.id,
                    attrs: { label: option.name, value: option.id },
                  },
                  "el-option",
                  option,
                  false
                )
              )
            }),
            1
          )
        : _vm._e(),
      _vm.item.type == "date"
        ? _c(
            "el-date-picker",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    align: "center",
                    type: "datetimerange",
                    "start-placeholder": "开始时间",
                    "end-placeholder": "结束时间",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    clearable: true,
                    "picker-options": _vm.pickerOptions,
                    size: _vm.item.size,
                  },
                },
                "el-date-picker",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }