import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
import { asyncRoutes, constantRoutes } from '@/router'

const version = require("@/utils/version")

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect']

router.afterEach(() => {
  version.getPro()
  NProgress.done()
})

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  document.title = getPageTitle(to.meta.title)

  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      store.commit("permission/SET_ROUTES", constantRoutes);
      router.addRoutes(constantRoutes);
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})
