<template>
  <div>
    <!-- 单张 -->
    <el-upload
      :data="dataObj"
      class="avatar-uploader"
      action="https://up-z2.qiniup.com/"
      :style="fileStyle"
      :on-progress="onProgress"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :disabled="disabled"
    >
      <el-progress v-if="progressFlag" type="circle" :percentage="loadProgress"></el-progress>
      <img v-if="fileUrl" :src="filePath" class="avatars" />
      <div v-if="!fileUrl" class="center">
        <i size="50" class="el-icon-upload2 avatar-uploader-icon" />
        <div style="color: #999;">上传图片</div>
      </div>
    </el-upload>

    <div class="file_titler">
      支持jpeg、jpg、png、gif、webp格式!
    </div>
  </div>
</template>

<script>
import { getToken } from "@/api/qiniu";

export default {
  name: "uploadImg",
  props: {
    type: {
      type: Object,
      default: () => ({
        object: 0,
        type: 0,
        extension: '', // 后缀
      })
    },
    fileUrl: {
      type: String,
      default: ""
    },
    // 长宽样式设置
    imgStyle: {
      type: String,
      default: 'row',
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dataObj: {
        token: "",
        key: ""
      },
      realPath: "",

      // 进度条
      progressFlag: false,
      loadProgress: 0,

      url: '',
    };
  },
  computed: {
    filePath() {
      return this.fileUrl;
    },

    fileStyle() {
      if (this.imgStyle == 'square') {
        return { width: '140px', height: '140px' }
      }
      if (this.imgStyle == 'row') {
        return { width: '250px', height: '140px' }
      }
      if (this.imgStyle == 'column') {
        return { width: '160px', height: '214px' }
      }
    }
  },
  methods: {
    // 文件上传前的钩子
    beforeAvatarUpload(file) {
      if (["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"].indexOf(file.type) == -1) {
        this.$message.error("请上传正确的图片格式!");
        return false;
      }

      let config = this.type;
      let suffix = file.type.split('/')[1]; // 获取后缀
      config.extension = "."+suffix;
      const _self = this;
      return new Promise((resolve, reject) => {
        getToken(config)
          .then(response => {
            _self._data.dataObj.token = response.result.token;
            _self._data.dataObj.key = response.result.folderPath;
            _self._data.realPath = response.result.realPath;
            
            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },

    emitUrlPath(val) {
      this.$message.success("上传成功！");
      this.$emit("uploadImgPath", val);
    },

    handleAvatarSuccess() {
      this.emitUrlPath(this.realPath);
    },

    // 文件上传时的钩子
    onProgress(event) {
      this.progressFlag = true;
      this.loadProgress = parseInt(event.percent);
      if (this.loadProgress >= 100) {
        setTimeout(() => {
          this.loadProgress = 100;
          this.progressFlag = false;
        }, 1000);
      }
    },
  }
};
</script>

<style>
.avatar-uploader .el-upload {
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.center {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.avatar-uploader-icon {
  font-size: 32px;
  color: #8c939d;
  text-align: center;
}
.avatars {
  width: 100%;
  height: 100%;
  display: block;
}
.el-progress--circle,
.el-progress--dashboard {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_titler {
  margin: 10px 0;
  color: #a4abb4;
}
</style>
