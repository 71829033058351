<template>
  <el-row class="data">
    <el-col :span="24">
      <el-table v-loading="loading" stripe :data="listData" element-loading-text="加载中..." :default-sort="{ prop: 'date', order: 'descending' }" :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }">

        <!-- 空数据状态的插槽 -->
        <template slot="empty">
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>

        <el-table-column label="ID" prop="id" width="60" fixed />

        <el-table-column label="订单编号" width="300">
          <template slot-scope="{row}">
            <el-button type="text" @click="goInfo(row.id)">{{ row.no }}</el-button>
          </template>
        </el-table-column>

        <!-- <el-table-column label="订单类型" width="150">
          <template slot-scope="{row}">
            <span class="tc">{{ getOrderTypeFn(row.table_type) }}</span>
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="支付类型" prop="pay_type_text" width="200" /> -->

        <el-table-column label="分红" width="200">
          <template slot-scope="{row}">¥ {{ $thousandth(row.profit,1) }}</template>
        </el-table-column>

        <!-- <el-table-column label="已退款金额" width="200">
          <template slot-scope="{row}">¥ {{ $thousandth(row.refund_price,1) }}</template>
        </el-table-column> -->

        <el-table-column label="订单状态">
        <template slot-scope="{ row }">
          <el-tag :type="row.status?'success':''">{{ statusLabel[row.status] }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="结算状态">
        <template slot-scope="{ row }">
          <el-tag :type="row.commission_status?'success':''">{{ commissionStatusLabel[row.commission_status] }}</el-tag>
        </template>
      </el-table-column>

        <el-table-column label="创建时间" prop="created_at" width="200" />

      </el-table>

      <pagination :total="pageSize.total" :page.sync="pageSize.current_page" :limt.sync="pageSize.per_page" @pagination="pagination" />
    </el-col>

  </el-row>
</template>

<script>
import { getSubList } from "@/api/member";

import { flexColumnWidth } from "@/utils/table";

import Pagination from '@/components/Pagination/index.vue'

export default {
  props: {
    user_id: {
      type: Number,
      default: "",
    },
  },

  components: {
    Pagination,
  },

  data() {
    return {
      loading: false,

      // 订单部分
      listForm: {
        page: 1,
        search_user_id: this.user_id,
        page_size: 10,
        search_user_id: 0,
      },

      listData: [],

      formData: {
        user_id: this.user_id,
        page: 1,
        page_size: 10,
      },

      pageSize: {},
      statusLabel: {
        0: "未完成",
        1: "已完成",
      },
      commissionStatusLabel:{
        0:"未结算",
        1:"已结算"
      },
    };
  },

  computed: {
    // getOrderTypeFn() {
    //   return function(type) {
    //     if (type == 'goods') return '商品';
    //     if (type == 'course') return '单品课程';
    //     if (type == 'courseBundle') return '专栏课程';
    //     if (type == 'agent') return '会员';
    //   }
    // }
  },

  methods: {
    flexColumnWidth,

    // 获取数据
    getList() {
      this.loading = true;
      getSubList(this.formData).then((res) => {
        const { data, total, current_page, per_page } = res.result

        this.listData = data;
        this.pageSize = {
          total,
          current_page,
          per_page
        }

        this.loading = false;
      });
    },

    goInfo(id) {
      this.$router.push({
        path: "/finance/financeOrderInfo",
        query: {
          id,
        },
      });
    },

    // 分页点击事件
    pagination(data) {
      this.formData = { ...this.formData, ...data };
      this.getList();
    },

  },
};
</script>

<style lang="scss" scoped>
.tc {
  color: #1890ff;
}
</style>
