var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "data" },
    [
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-button",
            {
              staticStyle: { margin: "10px" },
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.getInfo()
                },
              },
            },
            [_vm._v("新建")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                stripe: "",
                data: _vm.listData,
                "element-loading-text": "加载中...",
                "default-sort": { prop: "date", order: "descending" },
                "header-cell-style": {
                  backgroundColor: "#fafafa",
                  color: "#606266",
                },
              },
            },
            [
              _c(
                "template",
                { slot: "empty" },
                [
                  _c("el-empty", {
                    attrs: { "image-size": 100, description: "暂无数据" },
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", width: "60", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: { label: "姓名", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.real_name))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "性别", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.getGender(row.gender)))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "上级", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function ($event) {
                                return _vm.editAscription(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.parent.real_name))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "行政区", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.province + "-" + row.city + "-" + row.area
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "等级", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-tag",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.editAgent(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.system_agent_name))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c(
                              "span",
                              { staticClass: "el-dropdown-link operation" },
                              [
                                _vm._v(" 操作"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.getInfo(row)
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("编辑")])]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.getRemove(row.id)
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("删除")])]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "el-drawer",
            {
              attrs: {
                title: _vm.formData.id == 0 ? "新增" : "编辑",
                visible: _vm.drawer,
                direction: "rtl",
                size: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c("div", { staticClass: "drawer_box" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "0 20px" } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "drawerForm",
                        attrs: {
                          model: _vm.formData,
                          rules: _vm.drawerFormRules,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "用户名称:",
                              prop: "base.real_name",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入用户名称" },
                              model: {
                                value: _vm.formData.base.real_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData.base, "real_name", $$v)
                                },
                                expression: "formData.base.real_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "用户性别:", prop: "base.gender" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: true },
                                model: {
                                  value: _vm.formData.base.gender,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData.base, "gender", $$v)
                                  },
                                  expression: "formData.base.gender",
                                },
                              },
                              _vm._l(_vm.genderList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系方式:", prop: "phone" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入联系方式" },
                              on: {
                                change: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                              model: {
                                value: _vm.formData.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "phone", $$v)
                                },
                                expression: "formData.phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "上级ID:", prop: "parent_id" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "50%" },
                                attrs: { clearable: true, filterable: "" },
                                model: {
                                  value: _vm.formData.parent_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "parent_id", $$v)
                                  },
                                  expression: "formData.parent_id",
                                },
                              },
                              _vm._l(_vm.userList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: _vm.getAgentName(item),
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "行政区:",
                              prop: "base.address_code",
                            },
                          },
                          [
                            _c("el-cascader", {
                              ref: "regionRef",
                              attrs: {
                                placeholder: "请选择行政区",
                                options: _vm.xzqList,
                                props: _vm.defaultProps,
                                filterable: "",
                              },
                              on: { change: _vm.xzqChange },
                              model: {
                                value: _vm.formData.base.address_code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.base,
                                    "address_code",
                                    $$v
                                  )
                                },
                                expression: "formData.base.address_code",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "等级身份:",
                              prop: "system_agent_id",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: true },
                                on: {
                                  change: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                                model: {
                                  value: _vm.formData.system_agent_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "system_agent_id",
                                      $$v
                                    )
                                  },
                                  expression: "formData.system_agent_id",
                                },
                              },
                              _vm._l(_vm.agentData, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "demo-drawer__footer" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.drawer = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: _vm.drawerLoading,
                        },
                        on: { click: _vm.drawerSubmit },
                      },
                      [_vm._v(_vm._s(_vm.drawerLoading ? "提交中" : "保 存"))]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑代理等级",
                visible: _vm.agentDialog,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.agentDialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    "label-width": "80px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.system_agent_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "system_agent_id", $$v)
                            },
                            expression: "form.system_agent_id",
                          },
                        },
                        _vm._l(_vm.agentData, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.agentDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.confirmLoading },
                      on: { click: _vm.submitAgent },
                    },
                    [_vm._v(_vm._s(_vm.confirmLoading ? "保存中" : "确 定"))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑归属人",
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上级ID" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "50%" },
                          attrs: { clearable: true, filterable: "" },
                          model: {
                            value: _vm.form.parent_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "parent_id", $$v)
                            },
                            expression: "form.parent_id",
                          },
                        },
                        _vm._l(_vm.userList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: _vm.getAgentName(item),
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "修改理由", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 6,
                          placeholder: "请输入修改理由",
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.confirmLoading },
                      on: { click: _vm.submitAscription },
                    },
                    [_vm._v(_vm._s(_vm.confirmLoading ? "保存中" : "确 定"))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }