<template>
  <div :class="className" :style="{ minHeight: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from '../mixins/resize'

// 折线堆叠图
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '500px'
    },
    xAxisData: {
      type: Array,
      default: () => {
        return [
          '01-01', '01-02', '01-03', '01-04', '01-05', '01-06', '01-07', '01-08', '01-09', '01-10',
          '01-11', '01-12', '01-13', '01-14', '01-15', '01-16', '01-17', '01-18', '01-19', '01-20',
          '01-21', '01-22', '01-23', '01-24', '01-25', '01-26', '01-27', '01-28', '01-29', '01-30',
        ]
      }
    },
    seriesData: {
      type: Array,
      default: () => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
      }
    }
  },
  watch: {
    xAxisData: {
      deep: true,
      handler(val) {
        this.xAxisData = val
      }
    },
    seriesData: {
      deep: true,
      handler(val) {
        this.seriesData = val
        this.disposeChart()
        this.initChart()
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    this.disposeChart()
  },
  methods: {
    disposeChart() {
      if (!this.chart) {
        return
      }
      this.chart.dispose()
      this.chart = null
      window.removeEventListener("resize", this.handlerResize, false);
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'roma')
      this.drawChart()
    },
    drawChart() {
      this.chart.setOption(
        {
          tooltip: {
            trigger: 'item'
          },
         
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' },
                { value: 580, name: 'Email' },
                { value: 484, name: 'Union Ads' },
                { value: 300, name: 'Video Ads' }
              ]
            }
          ]
        }
      )

      //随着屏幕大小调节图表
      window.addEventListener("resize", this.handlerResize, false);
    },
    handlerResize() {
      this.chart.resize();
    },
  }
}
</script>
