<template>
  <div>
    <!-- 钱包数据 -->
    <div class="user_header_left">
      <div class="header_title">
        <svg-icon icon-class="wallet" />
        <span>钱包数据</span>
      </div>
      <div class="header_data">
        <div class="header_data_item blueColor" @click="openDrawer">
          <span>¥ {{ $thousandth(infoBase.balance, 1) }}</span> 账号余额
        </div>
        <div class="header_data_item">
          <span>{{ $thousandth(infoBase.balance_issued, 1) }}</span> 已发放
        </div>
        <div class="header_data_item">
          <span>{{ $thousandth(infoBase.balance_unissued, 1) }}</span> 未发放
        </div>
      </div>
    </div>
    <!-- 余额明细弹窗 -->
    <el-drawer
      title="余额明细"
      :visible.sync="balanceDrawer"
      direction="rtl"
      :size="1000"
    >
      <div class="drawer_box">
        <div style="padding: 0 20px">
          <el-form label-width="110px" ref="banlanceRef" :model="formData">
            <el-form-item label="修改类型" prop="banlance_type">
              <el-radio-group v-model="formData.banlance_type">
                <el-radio :label="1">增加余额</el-radio>
                <el-radio :label="2">扣除余额</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="余额" prop="price">
              <div class="num">
                <el-input-number
                  size="small"
                  v-model="formData.price"
                  :min="0"
                  controls-position="right"
                  :precision="2"
                />
                <div class="num_company">元</div>
              </div>
            </el-form-item>

            <el-form-item label="备注" prop="desc">
              <el-input
                v-model="formData.desc"
                placeholder="请输入备注"
                type="textarea"
                :rows="6"
                maxlength="10000"
                :show-word-limit="true"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="editUserBanlance"
                >修改</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>
    <!-- <div class="user_header_left" v-for="item in children" :key="item.id">
      <div class="header_title">
        <svg-icon icon-class="wallet" />
        <span>{{getAgentName(item)}}--钱包数据</span>
      </div>
      <div class="header_data ">
        <div class="header_data_item">
          <span>¥ {{ $thousandth(item.balance, 1) }}</span> 账号余额
        </div>
        <div class="header_data_item">
          <span>{{ $thousandth(item.balance_issued, 1) }}</span> 已发放
        </div>
        <div class="header_data_item">
          <span>{{ $thousandth(item.balance_unissued, 1) }}</span> 未发放
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import pagination from "@/components/Pagination/index.vue";
import { reqUserChangeBalance } from "@/api/member";
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },

    userList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  components: {
    pagination,
  },
  computed: {
    getAgentName() {
      return function (item) {
        let system_agent_name = "";
        if (item.system_agent_name.indexOf("省") != -1) {
          system_agent_name = item.province + item.system_agent_name;
        } else if (item.system_agent_name.indexOf("市") != -1) {
          system_agent_name = item.city + item.system_agent_name;
        } else {
          system_agent_name = item.city + item.area + item.system_agent_name;
        }
        return system_agent_name;
        // if (this.brandDataAll.length) {
        //   return this.brandDataAll.find((item) => item.id == id).name;
        // }
      };
    },
  },
  data() {
    return {
      loading: false,
      status: 0,

      // 数据
      infoBase: {
        avatar: null,
        real_name: null,
      },
      children: [],
      balanceDrawer: false,
      formData: {
        banlance_type: 2,
        price: null,
        desc: null,
      },
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.infoBase = this.user.base;
      this.children = this.user.children;
    },
    openDrawer() {
      this.balanceDrawer = true;
      this.formData = this.$options.data().formData;
    },
    // 修改用户余额
    editUserBanlance() {
      let types = this.formData.banlance_type == 1 ? "增加" : "扣除";

      this.$messageConfirm(
        `确认${types}用户余额？${types}：${this.formData.price}`,
        () => {
          reqUserChangeBalance({
            id: this.user.id,
            balance: this.formData.price,
            type: this.formData.banlance_type,
            desc: this.formData.desc,
          }).then((res) => {
            setTimeout(() => {
              this.$messageNotify(res);
              this.$emit("editUserInfo");

              this.balanceDrawer = false;
            }, 500);
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.user_header_left {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e6ebf5;
  padding: 16px 0;
  margin: 20px 0;

  .header_data_item {
    width: 33%;
  }
}

.user_header_left:hover {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.header_title {
  margin-left: 30px;

  span {
    margin-left: 6px;
  }
}

.header_data {
  display: flex;
  width: 100%;
  flex-flow: wrap;

  .header_data_item {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 16px;

    span {
      display: block;
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: bold;
    }
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.num {
  display: flex;
  align-items: center;

  .num_company {
    margin-left: 20px;
  }
}

.orderTitle {
  color: #409eff;
  cursor: pointer;
}

.blueColor {
  color: #409eff;
}

.export {
  border-top: 1px solid #909399;
  padding-top: 10px;
  margin-bottom: 10px;
}
</style>
