<template>
  <div :class="{ fullscreen: fullscreen }" class="tinymce-container">
    <textarea :id="id" class="tinymce-textarea"></textarea>
    <div class="editor-custom-btn-container">
      <editorImage color="#1890ff" @successCBK="imageSuccessCBK" />
    </div>
  </div>
</template>

<script>
import editorImage from './EditorImage'
import toolbar from './toolbar';
import plugins from './plugins';
import styles from './style';
import remoteLoad from './remoteLoad';
const { TinymceCDN } = require('./cdn');
export default {
  name: 'tinymce',
  props: {
    height: {
      type: [Number, String],
      default: '300'
    },
    resize: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  components: { editorImage },
  data() {
    return {
      id: 'editor-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
      firstInit: false,
      errorStatus: false,
      fullscreen: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        await remoteLoad(TinymceCDN);
        if (window.tinymce) {
          this.initTinymce();
        } else {
          this.$message.error('加载资源失败');
        }
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
    initTinymce() {
      const that = this;
      window.tinymce.init({
        selector: `#${this.id}`, //容器
        language: 'zh_CN', //可选  en,zh_CN,es_MX,ja
        height: this.height,
        object_resizing: this.resize, //图片的大小调整手柄
        toolbar: toolbar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean', //过滤从Word粘贴的内容  clean,merge,prompt
        default_link_target: '_blank', //默认打开方式在新窗口打开
        link_title: false, //标记标题是否选择
        paste_retain_style_properties: 'all',
        paste_word_valid_elements: '*[*]', // word需要它
        paste_data_images: true, // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
        paste_convert_word_fake_lists: false, // 插入word文档需要该属性
        file_picker_types: 'media',
        content_style: styles,
        init_instance_callback: editor => {
          //初始化，如果存在value  直接添加上去
          if (that.value) {
            editor.setContent(that.value);
          }
          that.firstInit = true;
          editor.on('input change undo redo', () => {
            that.errorStatus = true;
            that.$emit('input', editor.getContent());
          });
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', e => {
            that.fullscreen = e.state;
          });
        },
        file_picker_callback: function (cb, value, meta) {
          if (meta.filetype == 'media') {
            let input = document.createElement('input'); //创建一个隐藏的input
            input.setAttribute('type', 'file');
            input.onchange = function (file) {
              //获取本地，然后调接口获取到url地址
            };
            //触发点击
            input.click();
          }
        }
      });
    },
    setContent(value) {
      window.tinymce.get(this.id).setContent(value);
    },
    getContent() {
      window.tinymce.get(this.id).getContent();
    },
    imageSuccessCBK(arr) {
      arr.forEach(v => window.tinymce.get(this.id).insertContent(`<img class="wscnph" src="${v.url}" >`))
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.id);
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen');
      }
      if (tinymce) {
        tinymce.destroy();
      }
    }
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated() {
    this.destroyTinymce();
  },
  destroyed() {
    this.destroyTinymce();
  },
  watch: {
    value(nl) {
      if (!this.errorStatus && this.firstInit) {
        this.$nextTick(() => {
          window.tinymce.get(this.id).setContent(nl || '');
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
</style>