var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("page-header", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.loading,
            expression: "loading",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        attrs: {
          content: _vm.form.id ? "编辑" : "新增",
          "back-path": "rolesList",
        },
        on: { saveClick: _vm.onSubmit },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { width: "90%", "margin-top": "20px" },
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名:", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入角色名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:", prop: "status" } },
            [
              _c("el-switch", {
                staticClass: "el_switch",
                attrs: { "active-text": "开", "inactive-text": "关" },
                on: { change: _vm.switchChange },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "页面权限" } },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  "show-checkbox": "",
                  "expand-on-click-node": "",
                  "node-key": "name",
                  "highlight-current": "",
                  data: _vm.menuList,
                  "default-expanded-keys": _vm.defaultRules,
                  "default-checked-keys": _vm.defaultRules,
                  props: _vm.defaultProps,
                },
                on: { check: _vm.checkTree },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }