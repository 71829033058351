import { render, staticRenderFns } from "./basicsSetting.vue?vue&type=template&id=127472fe&scoped=true&"
import script from "./basicsSetting.vue?vue&type=script&lang=js&"
export * from "./basicsSetting.vue?vue&type=script&lang=js&"
import style0 from "./basicsSetting.vue?vue&type=style&index=0&id=127472fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127472fe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ryuuuuuu/Desktop/中国码云/名医堂/yktz-accounting-system-vue/node_modules/.store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('127472fe')) {
      api.createRecord('127472fe', component.options)
    } else {
      api.reload('127472fe', component.options)
    }
    module.hot.accept("./basicsSetting.vue?vue&type=template&id=127472fe&scoped=true&", function () {
      api.rerender('127472fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/goods/goodsList/infoAssembly/basicsSetting.vue"
export default component.exports