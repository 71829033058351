import request from '@/utils/request'

// 财务结算列表
export function reqFinanceList(data) {
  return request({
    url: '/api/finance/list',
    method: 'post',
    data
  })
}

// 财务结算
export function financeChangeStatus(data) {
  return request({
    url: '/api/finance/change_status',
    method: 'post',
    data
  })
}
