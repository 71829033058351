import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/login/sign_in',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: 'admin/admin/menu',
    method: 'post'
  })
}

export function getMenu() {
  return request({
    url: '/api/login/get_member_info',
    method: 'post'
  })
}

// 清除缓存
export function getClearCache() {
  return request({
    url: 'admin/SystemConfig/clearCache',
    method: 'post'
  })
}
