export function messageConfirm(title, fn, back) {
  this.$confirm(title ? title : '确认删除!', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    closeOnClickModal: false,
  }).then(() => {
    fn();
  }).catch(() => {
    back && back();
  })
}

export function messageNotify(res, timer) {
  this.$notify({
    title: '提示',
    message: res && res.message ? res.message : '保存成功',
    type: 'success',
    duration: timer ? timer : 2000
  })
}
