// const toolbar = [
//   'undo redo  bold italic underline strikethrough   alignleft aligncenter alignright   blockquote  formatselect fontsizeselect ',
//   'forecolor backcolor searchreplace bullist numlist outdent indent link image  media  charmap      table  preview  fullscreen code'
// ];

const toolbar = [
  'searchreplace ' + 
  'bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 
  'hr bullist numlist link image charmap preview anchor formatselect fontsizeselect pagebreak forecolor backcolor fullscreen'
];

export default toolbar;