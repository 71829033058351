var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("page-header", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.loading,
            expression: "loading",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        attrs: {
          content: _vm.form.id ? "编辑" : "新增",
          "back-path": "accountList",
        },
        on: { saveClick: _vm.onSubmit },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { "margin-top": "24px" },
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "180px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "昵称:", prop: "nickname" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入昵称" },
                model: {
                  value: _vm.form.nickname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账号:", prop: "username" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入账号" },
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "密码:", prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "auto-complete": "new-password",
                  placeholder: "请输入密码",
                },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "确认密码:", prop: "password_confirm" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  "auto-complete": "new-password",
                  placeholder: "请确认密码",
                },
                model: {
                  value: _vm.form.password_confirm,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password_confirm", $$v)
                  },
                  expression: "form.password_confirm",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色", prop: "roles" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", placeholder: "选择角色" },
                  model: {
                    value: _vm.form.roles,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roles", $$v)
                    },
                    expression: "form.roles",
                  },
                },
                _vm._l(_vm.rolesData, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:", prop: "status" } },
            [
              _c("el-switch", {
                staticClass: "el_switch",
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-text": "开",
                  "inactive-text": "关",
                },
                on: { change: _vm.switchChange },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }