var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader",
          style: _vm.fileStyle,
          attrs: {
            data: _vm.dataObj,
            action: "https://up-z2.qiniup.com/",
            "on-progress": _vm.onProgress,
            "show-file-list": false,
            "on-success": _vm.handleAvatarSuccess,
            "before-upload": _vm.beforeAvatarUpload,
            disabled: _vm.disabled,
          },
        },
        [
          _vm.progressFlag
            ? _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.loadProgress },
              })
            : _vm._e(),
          _vm.fileUrl
            ? _c("img", {
                staticClass: "avatars",
                attrs: { src: _vm.filePath },
              })
            : _vm._e(),
          !_vm.fileUrl
            ? _c("div", { staticClass: "center" }, [
                _c("i", {
                  staticClass: "el-icon-upload2 avatar-uploader-icon",
                  attrs: { size: "50" },
                }),
                _c("div", { staticStyle: { color: "#999" } }, [
                  _vm._v("上传图片"),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "file_titler" }, [
        _vm._v(" 支持jpeg、jpg、png、gif、webp格式! "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }