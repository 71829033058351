<template>
  <div>
    <!-- 多张 -->
    <el-upload
      :data="dataObj"
      :file-list="realPath"
      :multiple="true"
      :show-file-list="true"
      :limit="5"
      :on-exceed="handleExceed"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
      class="upload_list"
      action="https://up-z2.qiniup.com/"
      list-type="picture-card"
    >
      <div class="center">
        <i size="50" class="el-icon-upload2 avatar-uploader-icon" />
        <div style="color: #999">上传图片</div>
      </div>
    </el-upload>

    <div class="filev_title">
      支持jpeg、jpg、png、gif、webp格式,最多可上传5张图片!
    </div>
  </div>
</template>

<script>
import { getToken } from "@/api/qiniu";

export default {
  props: {
    type: {
      // 上传获取图片接口参数
      type: Object,
      default: () => ({
        object: 0,
        type: 0,
        extension: "", // 后缀
      }),
    },
    // 回显数据
    imageList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    limit: {
      // 最大上传数
      type: Number,
      default: 5,
    },
    listType: {
      type: String,
      default: "picture-card",
    },
  },
  data() {
    return {
      dataObj: {
        token: "",
        key: "",
      },
      realPath: this.imageList,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let data = [];
      this.imageList.map((item) => {
        data.push({ url: item })
      });
      this.realPath = data;
    },

    beforeUpload(file) {
      console.log(file);
      const imgFormat =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif" ||
        file.type == "image/webp";

      if (!imgFormat) {
        return this.$message.error("请上传正确的图片格式!");
      } else {
        const _self = this;
        return new Promise((resolve, reject) => {
          let config = this.type;
          let suffix = file.type.split("/")[1]; // 获取后缀
          config.extension = suffix;

          getToken(config)
            .then((response) => {
              const { token, folderPath, realPath } = response.result;
              _self._data.dataObj.token = token;
              _self._data.dataObj.key = folderPath;

              setTimeout(() => {
                _self._data.realPath.push({
                  url: realPath,
                  uid: file.uid,
                  status: "success",
                });
              }, 600);

              resolve(true);
            })
            .catch((err) => {
              reject(false);
            });
        });
      }
    },

    handleSuccess() {
      this.$emit("uploadListPath", this.realPath);
    },

    handleRemove(file) {
      let arr = this.realPath.filter((item) => item.url != file.url);
      this.realPath = arr;

      this.$emit("uploadListPath", arr);
    },

    handleExceed() {
      this.$message.warning(`最多上传${this.limit}张图片!`);
    },
  },
};
</script>

<style scoped>
.upload_list {
  display: flex;
}
.upload_list ::v-deep .el-upload,
.upload_lists ::v-deep .el-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 136px;
  height: 136px;
  line-height: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 10px;
}

.upload_list ::v-deep .el-upload:hover {
  border-color: #409eff;
}

.upload_lists ::v-deep .el-upload {
  display: none !important;
}

.filev_title {
  margin-top: -10px;
  color: #a4abb4;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 136px !important;
  height: 136px !important;
}
</style>