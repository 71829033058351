<template>
  <div class="upload-container">
    <el-button :style="{background:color,borderColor:color}" icon="el-icon-upload2" size="mini" type="primary" @click=" dialogVisible=true">
      上传图片
    </el-button>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-upload
        :data="dataObj"
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        :limit="5"
        :on-exceed="handleExceed"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        class="editor-slide-upload"
        action="https://up-z2.qiniup.com/"
        list-type="picture-card"
      >
        <el-button size="small" type="primary">
          点击上传
        </el-button>
      </el-upload>
      <el-button @click="handleCancel">
        关闭
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        提交
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/api/qiniu'

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      dataObj: {
        token: '',
        key: ''
      },
      realPath: [],
      // upload 组件上传类型
      uploadType: {
        object: 0,
        type: 0,
        extension: '',
      },
    }
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleCancel() {
      this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      if (!this.checkAllSuccess()) {
        this.$message('请等待所有图像成功上载。如果出现网络问题，请刷新页面并再次上传！')
        return
      }

      this.$emit('successCBK', arr)
      this.listObj = {}
      this.realPath = []
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess(response, file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].hasSuccess = true
          console.log(this.listObj, 'this.listObj handleSuccess');
          return
        }
      }
    },
    handleExceed() {
      this.$message.warning('一次最多上传5张，请分批次上传!')
    },
    handleRemove(file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    beforeUpload(file) {
      const imgFormat = file.type == 'image/jpeg' ||
            file.type == 'image/jpg' ||
            file.type == 'image/png' ||
            file.type == 'image/gif' ||
            file.type == 'image/webp'

      if (!imgFormat) {
        this.$message.error('请上传正确的图片格式!')
      } else {
        const _URL = window.URL || window.webkitURL
        const fileName = file.uid
        this.listObj[fileName] = {}

        const _self = this
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = _URL.createObjectURL(file)
          img.onload = function() {
            _self.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height }
          }

          let config = this.uploadType;
          let suffix = file.type.split('/')[1]; // 获取后缀
          config.extension = suffix;

          getToken(config).then(response => {
            _self._data.dataObj.token = response.result.token
            _self._data.dataObj.key = response.result.folderPath
            _self._data.realPath.push(response.result.realPath)

            _self.listObj[fileName].url = response.result.realPath;
            resolve(true)
          }).catch(err => {
            reject(false)
          })
        })
      }

      return imgFormat 
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
</style>
