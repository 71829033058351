var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "userTree", attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-timeline",
            { attrs: { reverse: true } },
            _vm._l(_vm.userTree, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  item
                    ? _c(
                        "el-timeline-item",
                        {
                          attrs: {
                            reverse: "true",
                            icon: "el-icon-user",
                            type: "primary",
                            size: "large",
                            placement: "top",
                            timestamp: item.real_name,
                          },
                        },
                        [
                          _c("el-card", { staticClass: "treeCard" }, [
                            _c("p", [_vm._v("id:" + _vm._s(item.id))]),
                            _c("p", [
                              _vm._v(
                                " 行政区:" +
                                  _vm._s(
                                    item.province +
                                      "-" +
                                      item.city +
                                      "-" +
                                      item.area
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "等级身份:" + _vm._s(item.system_agent_name)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm.userTree.length
        ? _c("el-divider", {
            staticClass: "divider",
            attrs: { direction: "vertical" },
          })
        : _vm._e(),
      _c(
        "el-col",
        { attrs: { span: _vm.userTree.length ? 16 : 24 } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "userTable",
              attrs: {
                stripe: "",
                border: "",
                "row-key": _vm.getRowKeys,
                data: _vm.listData,
                "element-loading-text": "加载中...",
                "max-height": 500,
                "tree-props": { children: "children" },
                "header-cell-style": {
                  backgroundColor: "#fafafa",
                  color: "#606266",
                },
              },
              on: { "expand-change": _vm.expandSelect },
            },
            [
              _c(
                "template",
                { slot: "empty" },
                [
                  _c("el-empty", {
                    attrs: { "image-size": 100, description: "暂无数据" },
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", fixed: "", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "姓名", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.base.real_name))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "手机号", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.phone))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "上级", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v(_vm._s(_vm.getParentName(row.parent_id))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "行政区", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.base.province +
                                "-" +
                                row.base.city +
                                "-" +
                                row.base.area
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "代理等级", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.base.system_agent_id
                          ? _c("el-tag", [
                              _vm._v(_vm._s(scope.row.base.system_agent_name)),
                            ])
                          : _c("span", [_vm._v(" - ")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }