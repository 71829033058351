<template>
  <div :class="className" :style="{ minHeight: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from '../mixins/resize'

// 折线堆叠图
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '500px'
    },
    xAxisData: {
      type: Array,
      default: () => {
        return [
          '01-01', '01-02', '01-03', '01-04', '01-05', '01-06', '01-07', '01-08', '01-09', '01-10',
          '01-11', '01-12', '01-13', '01-14', '01-15', '01-16', '01-17', '01-18', '01-19', '01-20',
          '01-21', '01-22', '01-23', '01-24', '01-25', '01-26', '01-27', '01-28', '01-29', '01-30',
        ]
      }
    },
    seriesData: {
      type: Array,
      default: () => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
      }
    }
  },
  watch: {
    xAxisData: {
      deep: true,
      handler(val) {
        this.xAxisData = val
      }
    },
    seriesData: {
      deep: true,
      handler(val) {
        this.seriesData = val
        this.disposeChart()
        this.initChart()
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    this.disposeChart()
  },
  destroyed() {},
  methods: {
    disposeChart() {
      if (!this.chart) {
        return
      }
      this.chart.dispose();
      this.chart = null;
      window.removeEventListener("resize", this.handlerResize, false);
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'roma')
      this.drawChart()
    },
    drawChart() {
      this.chart.setOption(
        {
          //提示框，鼠标悬浮交互时的信息提示
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            }
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          calculable: true,
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: this.xAxisData,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],

          dataZoom: [
            {
              // start: 0,//默认为0
              // end: 100,//默认为100
              type: 'slider',
              show: true,
              // xAxisIndex: [0],
              handleSize: 0,//滑动条的 左右2个滑动条的大小
              startValue: 0, // 初始显示值
              endValue: 30, // 结束显示值
              height: 20,//组件高度
              left: '5%', //左边的距离
              right: '5%',//右边的距离
              bottom: -10,//底边的距离
              // borderColor: "#000",
              fillerColor: '#409eff',
              borderRadius: 5,
              backgroundColor: '#eee',//两边未选中的滑动条区域的颜色
              showDataShadow: false,//是否显示数据阴影 默认auto
              showDetail: false,//即拖拽时候是否显示详细数值信息 默认true
              realtime: true, //是否实时更新
              filterMode: 'filter',
            }
          ],

          series: [
            {
              name: '销售金额：',
              type: 'bar',
              barWidth: '20%',
              data: this.seriesData
            }
          ]
        }
      )

      //随着屏幕大小调节图表
      window.addEventListener("resize", this.handlerResize, false);
    },
    handlerResize() {
      this.chart.resize();
    },
  },
}
</script>
