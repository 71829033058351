var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default" },
    [
      !_vm.fileUrl
        ? _c(
            "el-upload",
            {
              staticClass: "avatar-uploader-video",
              attrs: {
                action: "https://up-z2.qiniup.com/",
                data: _vm.dataObj,
                "show-file-list": false,
                "before-upload": _vm.beforeVideoUpload,
                "on-success": _vm.handleVideoSuccess,
                "on-progress": _vm.handleVideoProgress,
              },
            },
            [
              _vm.progressFlag
                ? _c("el-progress", {
                    attrs: { type: "circle", percentage: _vm.loadProgress },
                  })
                : _vm._e(),
              _c("div", { staticClass: "center" }, [
                _c("i", {
                  staticClass: "el-icon-upload2 avatar-uploader-icon",
                  attrs: { size: "50" },
                }),
                _c("div", { staticStyle: { color: "#999" } }, [
                  _vm._v("上传视频"),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.fileUrl
        ? _c("div", {
            staticClass: "video-player",
            attrs: { id: "video-player" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }