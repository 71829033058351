<template>
  <div class="app-container">
    <!-- 搜索 -->
    <query-form
      :queryForm="queryForm"
      :queryData="queryData"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    />

    <div class="sys_search_line"></div>
    <el-button type="primary" @click="getInfo()">新 建</el-button>
    <el-table
      stripe
      v-loading="loading"
      :data="pageData"
      element-loading-text="加载中..."
      :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
    >
      <!-- 空数据状态的插槽 -->
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>
      <el-table-column label="ID" prop="id" width="80" fixed />
      <!-- <el-table-column label="用户ID" prop="user.id" width="80"/> -->

      <el-table-column label="订单编号" width="200" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-button type="text" @click.native="getInfo(row.id)">{{ row.no }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="姓名" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <div>
            {{ row.user.real_name }}
            <el-tag >
              {{ row.user.system_agent_name }}
            </el-tag>
          </div>

          <div>
            {{row.user.province + "-" + row.user.city + "-" + row.user.area}}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="营收" show-overflow-tooltip>
        <template slot-scope="{row}">
          <div>
            金额：{{$thousandth(row.price, 1)}}
          </div>
          <div>
            利润：{{ $thousandth(row.profit, 1) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="订单状态">
        <template slot-scope="{ row }">
          <el-tag :type="row.status?'success':''">{{ statusLabel[row.status] }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="结算状态">
        <template slot-scope="{ row }">
          <el-tag :type="row.commission_status?'success':''">{{ commissionStatusLabel[row.commission_status] }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" prop="created_at" show-overflow-tooltip/>

      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="{ row }">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link operation">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="getInfo(row.id)">
                <span>编辑</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item @click.native="getInfo(2, row)">
                <span>查看</span>
              </el-dropdown-item> -->
              <el-dropdown-item @click.native="getRemove(row.id)"
                ><span>删除</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="pageSize.total"
      :page.sync="pageSize.current_page"
      :limt.sync="pageSize.per_page"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import clipboard from "@/directive/clipboard/index.js"; // use clipboard by v-directive

import { getOrderList, reqOrderRemove } from "@/api/order";
import { flexColumnWidth } from "@/utils/table";
import Pagination from "@/components/Pagination/index.vue";
import { parseTime, floatNumAddSub } from "@/utils/index";

import sumJs from "@/utils/sum";
export default {
  name: "financeOrderList",
  directives: {
    clipboard,
  },
  components: {
    Pagination,
  },
  data() {
    return {
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 10,
        search_no: "",
        search_user_id: "",
        search_status: "",
      },
      // 搜索框
      queryForm: [
        {
          type: "input",
          label: "订单号",
          model: "search_no",
          placeholder: "请输入订单号",
        },
        {
          type: "input",
          label: "用户ID",
          model: "search_user_id",
          placeholder: "请输入用户ID",
        },
        {
          type: "select",
          label: "状态",
          model: "search_status",
          placeholder: "请选择",
          options: [
            { id: 0, name: "未完成" },
            { id: 1, name: "已完成" },
          ],
        },
      ],
      statusLabel: {
        0: "未完成",
        1: "已完成",
      },
      commissionStatusLabel:{
        0:"未结算",
        1:"已结算"
      },
      // 页面数据
      pageData: [],
      pageSize: {},

      // 页面状态
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    getAgentName() {
      return function (item) {
        let name = "-"
        if(item){
          name = item.province+'-'+item.city+'-'+item.area+'-'+item.system_agent_name;
        }
        return name;
      };
    },
  },
  methods: {
    parseTime,
    flexColumnWidth,

    // 获取用户列表数据
    getList() {
      this.loading = true;
      // 获取列表数据
      getOrderList(this.queryData).then((resp) => {
        const { data, total, current_page, per_page } = resp.result;

        this.pageData = data;
        this.pageSize = {
          total,
          current_page,
          per_page,
        };
        this.loading = false;
      });
    },
    // 查询按钮
    handleQuery(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 重置按钮
    handleReset(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 分页点击事件
    pagination(data) {
      this.queryData = { ...this.queryData, ...data };
      this.getList();
    },

    getInfo(row) {
      let id = row ? row : 0;
      this.$router.push({
        path: "financeOrderInfo",
        query: {
          id,
        },
      });
    },

    // 删除
    getRemove(id) {
      this.$messageConfirm(`确认删除?`, () => {
        reqOrderRemove({
          id,
        }).then((res) => {
          this.$messageNotify(res);
          this.getList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right_btn {
  display: flex;
  align-items: center;
}

.company {
  margin-bottom: 30px;
}

.user_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 2px;
  padding: 10px;

  //margin-top: 10px;
  .user_header_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border-right: 1px solid #dfdfdf;
    font-size: 14px;

    .user_header_item_span {
      margin-top: 6px;
      font-size: 20px;
    }
  }

  .user_header_item:last-of-type {
    border-right: 0;
  }
}

.f_l {
  float: left;
}

.f_r {
  float: right;
}

.colorblue {
  color: #9e9e9e;
}

.oi_table,
.oi_tables {
  display: flex;
  align-items: center;
  height: 80px;
  border-right: 1px solid #e5e5e5;
}

.oi_table:last-child,
.oi_tables:last-child {
  border-right: 0;
}

.oi_table_bottom {
  border-top: 1px solid #e5e5e5;
  margin-top: -1px;
}

.oi_table {
  justify-content: center;
}

.oi_tables {
  justify-content: flex-end;
  padding-right: 20px;
}

.oit_tb_last {
  border-right: none !important;
}

.text_alc {
  text-align: center;
}

.tow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text_alr {
  text-align: right;
}

.order_table_header {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  line-height: 45px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;

  .oth_brd {
    border-right: 1px solid #fff;
  }

  .oth_brd_last {
    border-right: none;
  }
}

.order_main {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  font-size: 12px;

  .order_item_header {
    height: 45px;
    background: #f5f5f5;
    line-height: 45px;
    padding: 0 10px;

    .order_txt {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .order_btn {
      text-align: right;
    }
  }

  .order_item_main {
    border-top: 1px solid #e5e5e5;

    .oim_goods {
      display: flex;
      padding-left: 10px;
      width: 100%;

      .og_title {
        margin-left: 10px;
        line-height: 20px;
        width: 68%;

        .ogt_title,
        .ogt_sku {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .ogt_sku {
          text-align: left;
          color: #1890ff;
          margin-top: 5px;
        }
      }
    }
  }
}

.mjClass {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ::v-deep .el-link .el-link--primary {
    color: #1890ff;
    max-width: 10em;
  }

  ::v-deep .el-link--inner {
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.oit_tb_flex {
  display: flex;
}

.success {
  color: #409eff;
}

.fail {
  color: #f56c6c;
}
</style>
