<template>
  <div>
    <el-upload
      class="upload-demo editor-slide-upload"
      :data="dataObj"
      action="https://up-z2.qiniup.com/"
      name="file"
      :before-upload="beforeAvatarUpload"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :show-file-list="false"
      multiple
      :limit="5"
      :ref="torefUpload"
    />
    <quill-editor
      :ref="toref"
      v-model="content"
      @change="$emit('input', content)"
      :options="editorOption"
      style="height: 450px;"
    ></quill-editor>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.js";
import { getToken } from "@/api/qiniu";
export default {
  name: "newQuillEditor",
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
    },
    toref: {
      type: String,
      default: "quillEditor",
    },
    quillIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value: function () {
      this.content = this.value;
    },
  },
  data() {
    return {
      content: this.value,
      editorOption: {
        placeholder: "编辑内容",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "code-block"],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],
              ["link", "image", "video"],
              ["clean"], // remove formatting button
            ],
            handlers: {
              image: (value) => {
                if (value) {
                  // 这是是为了获取你当前点击的那个富文本框
                  this.$refs[this.torefUpload].$refs[
                    "upload-inner"
                  ].handleClick();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      torefUpload: "quillEditorUpload",
      listObj: {},
      dataObj: {
        token: "",
        key: "",
      },
      realPath: [],
      // upload 组件上传类型
      uploadType: {
        object: 0,
        type: 0,
        extension: "",
      },
    };
  },
  mounted() {
    this.torefUpload = this.toref + "Upload";
  },
  methods: {
    beforeAvatarUpload(file) {
      const imgFormat =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif" ||
        file.type == "image/webp";

      if (!imgFormat) {
        this.$message.error("请上传正确的图片格式!");
      } else {
        const _URL = window.URL || window.webkitURL;
        const fileName = file.uid;
        this.listObj[fileName] = {};

        const _self = this;
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = _URL.createObjectURL(file);
          img.onload = function () {
            _self.listObj[fileName] = {
              hasSuccess: false,
              uid: file.uid,
              width: this.width,
              height: this.height,
            };
          };

          let config = this.uploadType;
          let suffix = file.type.split("/")[1]; // 获取后缀
          config.extension = suffix;
          getToken(config)
            .then((response) => {
              _self._data.dataObj.token = response.result.token;
              _self._data.dataObj.key = response.result.folderPath;
              _self._data.realPath.push(response.result.realPath);
              resolve(true);
            })
            .catch((err) => {
              reject(false);
            });
        });
      }
      return imgFormat;
    },
    handleSuccess(res, file) {
      let that = this;
      //如果上传成功
      if (res) {
        //动态添加 ref  通过 eval () 去执行
        that.realPath.forEach((item) => {
          let toeval = "that.$refs." + that.toref + ".quill";
          //eval() 函数可计算某个字符串，并执行其中的的 JavaScript 代码。
          let quill = eval(toeval);
          console.log(quill);
          //获取光标所在位置
          let length = quill.selection.savedRange.index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image", item);
          // 调整光标到最后
          quill.setSelection(length + 1);
        });
        that.realPath = [];
      } else {
        // 提示信息，需引入Message
        this.$message.error("图片插入失败");
      }
      //this.form.unscramble = res.fileName;
    },
    handleExceed() {
      this.$message.warning("一次最多上传5张，请分批次上传!");
    },
  },
};
</script>
<style lang="scss" scoped>
.editor-slide-upload {
  //margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    width: 100%;
  }
}
.ql-container{
  height:300px;
}
</style>
