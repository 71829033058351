// import Cookies from 'js-cookie'

// export let TokenKey = 'Admin-Token'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   TokenKey = token
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

// 设置键名
const tokenKey = 'admin-token';

// 设置token
export function setToken(token) {
  return localStorage.setItem(tokenKey, token);
}
// 获取token
export function getToken() {
  return localStorage.getItem(tokenKey);
}
// 删除token
export function removeToken() {
  return localStorage.removeItem(tokenKey);
}
