<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>个人信息</span>
      </div>
      <div class="user-profile">
        <!-- <div class="user_img">
          <el-image 
            class="user_img" 
            :src="infoBase.avatar" 
            :preview-src-list="[infoBase.avatar]" 
            fit="cover" 
          />
        </div> -->
        <div class="box-center">
          <el-tooltip
            effect="dark"
            :content="infoBase.real_name"
            placement="top"
          >
            <div class="user-name">{{ infoBase.real_name }}</div>
          </el-tooltip>
          <div style="margin: 4px 0">用户ID：{{ pageUser.id }}</div>
        </div>
      </div>

      <div class="user_nav" v-for="(item, index) in profile" :key="index">
        <div class="user_nav_top">
          <svg-icon :icon-class="item.icon" v-if="item.id != 4" />
          <i :class="item.icon" v-if="item.id == 4"></i>
          <span>{{ item.title }}</span>
        </div>

        <div class="action">
          <div
            style="cursor: pointer"
            :style="{ color: item.icon == 'guishuren' ? '#409eff' : '' }"
          >
            {{ item.text ? item.text : "--" }}
          </div>
          <div>
            <!-- 复制手机号 -->
            <div
              v-if="item.id == 1"
              v-clipboard:copy="item.text"
              v-clipboard:success="clipSuccess"
            >
              <i class="el-icon-copy-document icon" />
            </div>

            <!-- 用户等级 -->
            <div v-if="item.id == 2">
              <el-button type="text" slot="suffix" @click="editAgent()"
                >编辑</el-button
              >
            </div>
            <!-- 编辑归属人 -->
            <div v-if="item.id == 3">
              <el-button type="text" slot="suffix" @click="editAscription()"
                >编辑</el-button
              >
            </div>
            <!-- 编辑归属人 -->
            <div v-if="item.id == 4">
              <el-button type="text" slot="suffix" @click="editDistrict()"
                >编辑</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 代理等级 -->
    <el-dialog
      title="编辑代理等级"
      :visible.sync="agentDialog"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="身份">
          <el-select
            v-model="form.system_agent_id"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in agentData"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="修改理由" prop="remark">
          <el-input type="textarea" :rows="6" v-model="form.remark" placeholder="请输入修改理由" />
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="agentDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAgent"
          :loading="confirmLoading"
          >{{ confirmLoading ? "保存中" : "确 定" }}</el-button
        >
      </div>
    </el-dialog>

    <!-- 弹窗选择归属人 -->
    <el-dialog
      title="编辑归属人"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="上级ID">
          <!-- <el-input v-model="form.parent_id" autocomplete="off"></el-input> -->
          <el-select
            v-model="form.parent_id"
            :clearable="true"
            style="width: 50%"
            filterable
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="getAgentName(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="修改理由" prop="remark">
          <el-input
            type="textarea"
            :rows="6"
            v-model="form.remark"
            placeholder="请输入修改理由"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAscription"
          :loading="confirmLoading"
          >{{ confirmLoading ? "保存中" : "确 定" }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 弹窗选择行政区 -->
    <el-dialog
      title="编辑行政区"
      :visible.sync="districtDialog"
      :close-on-click-modal="false"
    >
      <el-form :model="xzqData">
        <el-form-item label="行政区">
          <el-cascader
            ref="regionRef"
            v-model="xzqData.address_code"
            placeholder="请选择行政区"
            :options="xzqList"
            :props="defaultProps"
            filterable
            clearable
            @change="xzqChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="districtDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitDistrict"
          :loading="confirmLoading"
          >{{ confirmLoading ? "保存中" : "确 定" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  getLabelList,
  reqUserChangeAgent,
  reqUserRelation,
  changeAddressCode,
} from "@/api/member";

import { flexColumnWidth } from "@/utils/table";
import clipboard from "@/directive/clipboard/index.js"; // use clipboard by v-directive

import Pagination from "@/components/Pagination/index.vue";
import axios from "axios";
import { getArea } from "@/utils/addrFilter";
export default {
  directives: {
    clipboard,
  },
  components: {
    Pagination,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getAgentName() {
      return function (item) {
        let name = "-";
        if (item.base) {
          name =
            item.base.real_name +
            "-" +
            item.base.province +
            "-" +
            item.base.city +
            "-" +
            item.base.area +
            "-" +
            item.base.system_agent_name;
        }
        return name;
      };
    },
  },

  data() {
    return {
      // 数据
      pageUser: {},
      infoBase: {
        avatar: null,
        real_name: null,
      },
      profile: [],
      form: {
        id: 0,
        p_id: 0,
        remark: null,
        real_name: null,
        system_agent_id: null,
      },
      rules: {
        // remark: [{ required: true, message: '请输入修改理由', trigger: 'blur' }],
      },

      // 上级
      dialogFormVisible: false,

      // 代理
      agentDialog: false,

      drawerProfileId: 0,
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 100,
        search_sub: 1,
        search_id: "",
        search_name: "",
        search_real_name: "",
        search_phone: "",
      },

      // 页面数据
      pageData: [],
      pageSize: {},

      // 代理数据
      agentData: [],

      // 页面状态
      loading: false,
      tabsIndex: "all",

      confirmLoading: false,
      //用户列表数据
      userList: [],
      //行政区
      xzqList: [],
      defaultProps: {
        label: "value",
        value: "code",
        children: "children",
      },
      xzqData: {},
      districtDialog: false,
    };
  },

  created() {},
  async mounted() {
    this.pageUser = JSON.parse(JSON.stringify(this.user));
    await this.getAgentList();
    await this.getList();
    this.getXzqList();
    this.init();
  },
  methods: {
    flexColumnWidth,

    init() {
      this.infoBase = this.pageUser.base ? this.pageUser.base : this.pageUser;
      this.profile = [
        {
          id: 0,
          title: "真实姓名",
          text: this.infoBase.real_name,
          icon: "names",
        },
        {
          id: 1,
          title: "手机号码",
          text: this.pageUser.phone,
          icon: "phone",
        },
        {
          id: 2,
          title: "用户等级",
          text: this.pageUser.base.system_agent_name,
          icon: "daili",
        },
        {
          id: 3,
          title: "归属人",
          text: this.pageUser.parent ? this.pageUser.parent.real_name : "-",
          icon: "guishuren",
        },
        {
          id: 4,
          title: "行政区",
          text:
            this.infoBase.province +
            "-" +
            this.infoBase.city +
            "-" +
            this.infoBase.area,
          icon: "el-icon-location-information",
        },
      ];
    },

    clipSuccess() {
      this.$message.success("复制成功!");
    },
    getAgentList() {
      this.queryData = this.$options.data().queryData;
      getLabelList(this.queryData).then((res) => {
        const { data, total, current_page, per_page } = res.result;
        this.agentData = data;
      });
    },

    getList() {
      // 获取列表数据
      getUserList(this.queryData).then((resp) => {
        const { data, total, current_page, per_page } = resp.result;

        this.userList = data;
        this.userList.unshift({
          id: 0,
        });
      });
    },

    // 编辑身份
    editAgent() {
      this.form.system_agent_id = this.infoBase.system_agent_id;
      this.agentDialog = true;
    },
    submitAgent() {
      this.confirmLoading = true;
      reqUserChangeAgent({
        user_id: this.pageUser.id,
        system_agent_id: this.form.system_agent_id,
      }).then((res) => {
        setTimeout(() => {
          this.confirmLoading = false;
          this.$messageNotify();
          this.agentDialog = false;
          this.$emit("editUserInfo");
        }, 500);
      });
    },

    // 编辑归属人
    async editAscription() {
      this.form = JSON.parse(JSON.stringify(this.$options.data().form));
      this.form.parent_id = this.$set(
        this.form,
        "parent_id",
        this.pageUser.parent_id
      );
      this.getList();
      this.dialogFormVisible = true;
    },

    submitAscription() {
      if (!this.form.remark || !this.form.parent_id) {
        this.$message.warning("请填写修改信息");
        return;
      }
      this.confirmLoading = true;
      reqUserRelation({
        parent_id: this.form.parent_id,
        remark: this.form.remark,
        user_id: this.pageUser.id,
      }).then((res) => {
        setTimeout(() => {
          this.confirmLoading = false;
          this.$messageNotify();
          this.dialogFormVisible = false;
          this.$emit("editUserInfo");
        }, 500);
      });
    },
    //获取行政区列表
    getXzqList() {
      const url = "/addr.json";
      axios
        .get(url)
        .then((response) => {
          this.xzqList = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    //编辑行政区
    editDistrict() {
      this.$set(this.xzqData, "province", this.infoBase.province);
      this.$set(this.xzqData, "city", this.infoBase.city);
      this.$set(this.xzqData, "area", this.infoBase.area);
      this.$set(this.xzqData, "address_code", this.infoBase.address_code);
      this.$set(this.xzqData, "user_id", this.pageUser.id);
      this.districtDialog = true;
    },
    //选择行政区
    xzqChange(data) {
      if (data[2]) {
        let xzq = getArea(data[2]);
        let district = xzq.split("-");
        this.xzqData.province = district[0];
        this.xzqData.city = district[1];
        this.xzqData.area = district[2];
      }
      this.$refs.regionRef.dropDownVisible = false;
    },
    submitDistrict() {
      this.confirmLoading = true;

      changeAddressCode(this.xzqData).then((res) => {
        setTimeout(() => {
          this.confirmLoading = false;
          this.$messageNotify();
          this.dialogFormVisible = false;
          this.$emit("editUserInfo");
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile {
  display: flex;
  align-items: center;

  .user-name {
    width: 200px;
    cursor: pointer;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .user_img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .box-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
    margin-left: 16px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .box-social {
    padding-top: 30px;

    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }

  .user-follow {
    padding-top: 20px;
  }
}

.user_nav {
  margin-top: 20px;
  border-bottom: 1px solid #dfe6ec;

  .user_nav_top {
    // border-bottom: 1px solid #dfe6ec;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: bold;

    span {
      font-size: 14px;
      margin-left: 6px;
    }
  }
}

.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;
}

.fl {
  display: flex;
  align-items: center;
}

.icon {
  cursor: pointer;
  color: #409eff;
}
</style>
