<template>
  <div class="app-container">
    <page-header
      content="财务详情"
      v-loading.fullscreen.lock="loading"
      :back="backPage"
      :showSaveBtn="showSaveBtn"
    />

    <div class="nav">
      <!-- 提现信息 -->
      <page-body :navBg="navBg">
        <template slot="title">提现信息</template>

        <el-row class="row-main">
          <el-col :span="3"><div class="ly_txt">流水号：</div></el-col>
          <el-col :span="9">{{ formLists.info.order_no }}</el-col>
          <el-col :span="3"><div class="ly_txt">打款方式：</div></el-col>
          <el-col :span="9">
            <el-tag v-if="formLists.info.transfers_type == 1" type="success"
              >打款到微信零钱</el-tag
            >
            <el-tag v-if="formLists.info.transfers_type == 2"
              >打款到银行卡</el-tag
            >
          </el-col>
        </el-row>

        <el-row class="row-main">
          <el-col :span="3"><div class="ly_txt">提现金额：</div></el-col>
          <el-col :span="9">￥{{ $thousandth(formLists.info.withdraw_amount,1) }}</el-col>
          <el-col :span="3"><div class="ly_txt">平台手续费：</div></el-col>
          <el-col :span="9">￥{{ $thousandth(formLists.info.withdraw_tax,1)}}</el-col>
        </el-row>

        <el-row class="row-main">
          <el-col :span="3"><div class="ly_txt">最终提现：</div></el-col>
          <el-col :span="9">￥{{ $thousandth(formLists.info.withdraw_final_money,1) }}</el-col>
          <el-col :span="3"><div class="ly_txt">微信手续费：</div></el-col>
          <el-col :span="9">￥{{ $thousandth(formLists.info.platform_tax,1) }}</el-col>
        </el-row>

        <el-row class="row-main">
          <el-col :span="3"><div class="ly_txt">一审：</div></el-col>
          <el-col :span="9">
            <el-tag v-if="formLists.info.first_audit == 2">待审核</el-tag>
            <el-tag v-if="formLists.info.first_audit == 1" type="success">通过</el-tag>
            <el-tag v-if="formLists.info.first_audit == 3" type="danger">不通过</el-tag>
          </el-col>
        </el-row>

        <el-row class="row-main">
          <el-col :span="3"><div class="ly_txt">提现状态：</div></el-col>
          <el-col :span="9">
            <el-tag v-if="formLists.info.status == 2" type="warning">提现中</el-tag>
            <el-tag v-if="formLists.info.status == 1" type="success">已提现</el-tag>
            <el-tag v-if="formLists.info.status == 3" type="danger">已拒绝</el-tag>
            <el-tag v-if="formLists.info.status == 6">已完成</el-tag>
          </el-col>
          <el-col :span="3"><div class="ly_txt">创建时间：</div></el-col>
          <el-col :span="9">{{ formLists.info.created_at }}</el-col>
        </el-row>

        <el-row class="row-main">
          <el-col :span="3"><div class="ly_txt">备注：</div></el-col>
          <el-col :span="21">
            <el-input
              type="textarea"
              autosize
              disabled
              v-model="formLists.info.remark"
              v-if="formLists.info.remark"
            />
            <el-button type="text" @click="remarkChange()">
              {{ formLists.info.remark ? "修改" : "添加" }}备注
            </el-button>
          </el-col>
        </el-row>
      </page-body>

      <!-- 汇付账户信息 -->
      <!-- <page-body :navBg="navBg" style="margin: 20px 0" v-if="huifuData && huifuData.card_info">
        <template slot="title">汇付账户信息</template>
        <el-row>
          <el-col :span="3">持卡人姓名：</el-col>
          <el-col :span="8">{{ huifuData.card_info.card_name }}</el-col>
          <el-col :span="2"></el-col>
          <el-col :span="3">持卡人手机：</el-col>
          <el-col :span="8">{{ huifuData.card_info.mp }}</el-col>
        </el-row>
        <el-row style="margin: 30px 0;">
          <el-col :span="3">持卡人卡号：</el-col>
          <el-col :span="8">{{ huifuData.card_info.card_no }}</el-col>
          <el-col :span="2"></el-col>
          <el-col :span="3">持卡人身份证：</el-col>
          <el-col :span="8">{{ huifuData.card_info.cert_no }}</el-col>
        </el-row>
        <el-row style="margin-bottom: 30px;" v-if="file_list.length">
          <div class="image_list">
            <div v-for="(item, index) in file_list" :key="index" class="image_item">
              <div>{{ getAccountImageTitle(item.file_type) }}</div>
              <el-image class="image" :src="item.file_image" :preview-src-list="[item.file_image]" />
            </div>
          </div>
        </el-row>
      </page-body> -->

      <!-- 提现人信息 -->
      <page-body :navBg="navBg" style="margin: 20px 0">
        <template slot="title">提现人信息</template>
        <div style="float: left; margin-right: 10px">
          <el-image
            v-if="formLists.info.userBase"
            style="width: 75px; height: 75px"
            :src="formLists.info.userBase.avatar"
            :preview-src-list="[formLists.info.userBase.avatar]"
            fit="cover"
          />
        </div>
        <div style="float: left; margin-right: 10px">
          <div>
            ID：
            <el-button type="text">{{
              formLists.info.user_id ? formLists.info.user_id : "--"
            }}</el-button>
          </div>
          <div>
            用户名称：
            <el-button
              v-if="formLists.info.userBase"
              type="text"
              @click="onPageJump(formLists.info.userBase.user_id)"
              >{{ formLists.info.userBase.nickname }}</el-button
            >
            <span v-else>--</span>
          </div>
          <div>
            用户手机：
            <el-button type="text">{{
              formLists.info.userInfo ? formLists.info.userInfo.phone : "--"
            }}</el-button>
          </div>
        </div>
        <div style="clear: both"></div>
      </page-body>

      <!-- 初审 -->
      <page-body
        :navBg="navBg"
        style="margin-bottom: 20px"
        v-if="formLists.info.first_audit == 2"
      >
        <template slot="title">审核</template>
        <el-form
          ref="ruleForm"
          :model="audit_form"
          :rules="audit_rules"
          label-width="80px"
        >
          <el-form-item label="状态" prop="status">
            <el-switch
              v-model="audit_form.status"
              active-value="1"
              inactive-value="3"
              active-text="通过"
              inactive-text="不通过"
            />
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="audit_form.remark" />
          </el-form-item>
        </el-form>

        <el-button
          style="margin-left: 80px"
          type="primary"
          size="small"
          @click="auditSubmit(1)"
          >确认</el-button
        >
      </page-body>

      <!-- 打款审核 -->
      <page-body
        :navBg="navBg"
        style="margin-bottom: 20px"
        v-if="formLists.info.first_audit == 1 && formLists.info.status == 2"
      >
        <template slot="title">打款审核</template>
        <el-form
          ref="ruleForm"
          :model="audit_form"
          :rules="audit_rules"
          label-width="80px"
        >
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="audit_form.status">
              <el-radio-button label="3">不通过</el-radio-button>
              <el-radio-button label="1">通过</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="打款方式" prop="status">
            <el-radio-group v-model="audit_form.payment_way">
              <el-radio-button :label="true">线下打款</el-radio-button>
              <el-radio-button :label="false">微信零钱</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="audit_form.remark" />
          </el-form-item>
        </el-form>
        <el-button
          style="margin-left: 80px"
          type="primary"
          size="small"
          @click="auditSubmit(3)"
          >确认</el-button
        >
      </page-body>

      <!-- 操作日志 -->
      <page-body :navBg="navBg">
        <template slot="title">操作日志</template>
        <el-table
          stripe
          v-loading="loading"
          :data="formLists.list"
          empty-text="暂无数据"
          element-loading-text="加载中..."
          :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
          key="table1"
        >
          <el-table-column label="时间" prop="created_at" />
          <el-table-column label="操作" prop="action" />
          <el-table-column label="明细" prop="detail" />
          <el-table-column label="操作人" prop="adminInfo.nickname" />
        </el-table>
      </page-body>

      <!-- 提现任务 -->
      <page-body :navBg="navBg" style="margin: 20px 0">
        <template slot="title">提现任务</template>
        <el-table
          stripe
          ref="txTable"
          v-loading="loading"
          :data="dataList"
          element-loading-text="加载中..."
          :default-sort="{ prop: 'date' }"
          :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
          key="table2"
        >
          <el-table-column label="任务单号" prop="no" />
          <el-table-column label="提现金额" >
            <template slot-scope="{row}">
              <span>{{$thousandth(row.price,1) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提现状态">
            <template slot-scope="{ row }">
              <el-tag :type="dkstatus[row.status].status">{{ dkstatus[row.status].message }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" prop="updated_at" />
          <el-table-column label="操作" v-if="author">
            <template slot-scope="{ row }">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link operation">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="querySmallChange(row.no)">
                    <span>查询零钱</span>
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="getWithdrawTransfer(row.id)">
                    <span>微信转账</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <pagination
          :total="pageSize.total"
          :page.sync="pageSize.current_page"
          :limt.sync="pageSize.per_page"
          @pagination="Pagination"
          key="Pagination2"
        />
      </page-body>
    </div>

    <!-- 查询企业付款到零钱数据 -->
    <!-- 退款余额不足弹窗 -->
    <el-dialog title="企业付款到零钱数据" :visible.sync="dialog" :close-on-click-modal="false">
      <el-form label-width="200px">
        <el-form-item :label="key" v-for="(item, key, index) in smallChangeData" :key="index">{{ item }}</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="dialog = false">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 发货弹窗 -->
    <el-drawer
      title="备注"
      :visible.sync="drawer"
      :direction="'rtl'"
      size="500px"
      :close-on-press-escape="false"
      :show-close="false"
      :wrapperClosable="false"
    >
      <div class="drawer_box">
        <div style="padding: 0 20px">
          <!-- 商家备注 -->
          <el-form>
            <el-form-item>
              <el-input
                v-model="remark"
                placeholder="请输入备注"
                type="textarea"
                :rows="5"
              />
            </el-form-item>
          </el-form>
        </div>

        <div class="demo-drawer__footer">
          <el-button class="btn" size="small" @click="cancelForm()">取 消</el-button>
          <el-button
            class="btn"
            type="primary"
            size="small"
            @click="drawerSubmit()"
          >
            保 存
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  financeUserWithdrawAudit,
  financeUserWithdrawInfo,
  withdrawQueueList,
  reqFinanceOrderQuerySmallChange,
  reqFinanceOrderWithdrawRemark,
  reqWithdrawHuiFuAccount,
  reqWithdrawHuiFuImg,
  reqWithdrawTransfer,
} from "@/api/finance";

import PageBody from "@/components/PageBody/index.vue";
import Pagination from "@/components/Pagination";

export default {
  components: {
    PageBody,
    Pagination
  },
  data() {
    return {
      author: false, // true为超管

      id: this.$route.query.id || 0,
      loading: true,
      showSaveBtn: false,
      navBg: false,
      formLists: {
        info: {
          order_no: null,
          transfers_type: null,
          withdraw_amount: "--",
          withdraw_final_money: "--",
          platform_tax: "--",
          first_audit: 0,
          second_audit: 0,
          status: 0,
          created_at: null,
          remark: null,
          is_first_audit: false,
          is_second_audit: false,
          is_payment: false,
          user_id: 0,
          userBase: {
            avatar: null,
            nickname: null,
          },
          userInfo: {
            phone: null,
          },
        },
        list: [],
      },
      audit_form: {
        type: 1,
        status: "1",
        remark: null,
        payment_way: false,
      },
      audit_rules: {
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      listLoading: true,
      SearchData: {
        page: 1,
        page_size: 10,
        id: this.$route.query.id || 0,
      },
      dataList: [],
      pageSize: {},
      dkstatus:{
        1:{message:'成功',status:'success'},
        2:{message:'待打款',status:'warning'},
        3:{message:'失败',status:'danger'},
      },


      // 查询企业付款到零钱数据
      dialog: false,
      smallChangeData: {},

      drawer: false,
      remark: null,

      // 汇付账户详情
      huifuData: {},
      file_list: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.init();
    }
    // 超级管理员权限
    const { roles } = this.$store.getters;
    roles.find((v) => {
      if (v.name == "超级管理员" || v.name == "出纳") {
        this.author = true;
      }
    });
  },
  computed: {
    getAccountImageTitle() {
      return function(type) {
        const mapData = {
          F02: "身份证正面",
          F03: "身份证反面",
          F13: "银行卡正面",
          F14: "银行卡反面",
        }
        return mapData[type];
      }
    },
  },
  methods: {
    async init() {
      await this.getInfo();
      // await this.getHuiFuInfo();
      await this.getList();
    },

    // 提现详情
    getInfo() {
      let id = this.id
      financeUserWithdrawInfo(id).then((res) => {
        const { result } = res;
        this.formLists = result;
        this.getHuiFuInfo(this.formLists.info.user_id);
        this.loading = false;
      });
    },

    // 汇付账户详情
    getHuiFuInfo(user_id) {
      reqWithdrawHuiFuAccount({ id: user_id }).then(res => {
        const { data } = res.result;
        this.huifuData = JSON.parse(JSON.stringify(data));
        this.huifuData.indv_base_info.file_list.slice(0, 4).map(item => {
          reqWithdrawHuiFuImg({ file_id: item.file_id }).then(resp => {
            item.file_image = resp.result.data.download_url;
            this.file_list.push(item);
          })
        })
      })
    },

    // 用户详情
    onPageJump(id) {
      this.$router.push({
        path: "/member/userInfo",
        query: {
          id,
        },
      });
    },

    // 修改备注
    remarkChange() {
      this.remark = JSON.parse(JSON.stringify(this.formLists.info.remark));
      this.drawer = true;
    },

    // 取消修改备足
    cancelForm() {
      this.drawer = false;
    },

    // 确认修改备注
    drawerSubmit() {
      if (!this.remark) return this.$message.warning("请输入备注!");
      let httpData = {
        id: this.id,
        remark: this.remark
      }
      reqFinanceOrderWithdrawRemark(httpData).then(res => {
        this.formLists.info.remark = this.remark;
        this.drawer = false;
      })
    },

    auditSubmit(types) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let msg = "";
          this.audit_form.type = types;

          switch (this.audit_form.type) {
            case 1:
              if (this.audit_form.status == 1) {
                msg = "确认审核为通过？";
              } else {
                msg = "确认审核为不通过？";
              }
              break;
            default:
              if (this.audit_form.status == 1) {
                msg = "确认打款审核为通过？";
              } else {
                msg = "确认打款审核为不通过？";
              }
          }
          let config = {
            id: this.id,
            type: this.audit_form.type,
            status: this.audit_form.status,
            remark: this.audit_form.remark,
          };
          if (this.audit_form.type == 3) {
            config.payment_way = this.audit_form.payment_way;
          }
          this.$messageConfirm(msg, () => {
            financeUserWithdrawAudit(config).then((res) => {
              this.$messageNotify(res);
              this.loading = true;

              setTimeout(() => {
                this.backPage();
              }, 1000);
            });
          });
        }
      });
    },

    // 返回首页
    backPage() {
      this.$router.push({
        path: "financeFill",
        query: {
          type: "refresh"
        }
      });
    },

    getList() {
      let config = this.SearchData;
      withdrawQueueList(config).then((res) => {
        const { data, total, current_page, per_page } = res.result;

        this.listLoading = false;
        this.dataList = data;

        this.pageSize = {
          total,
          current_page,
          per_page,
        };
      });
    },

    Pagination(data) {
      this.SearchData = { ...this.SearchData, ...data };
      this.getList();
    },

    // 查询企业付款到零钱
    querySmallChange(no) {
      reqFinanceOrderQuerySmallChange({ no }).then(res => {
        this.smallChangeData = res.result;
        this.dialog = true;
      })
    },

    // 微信v3转账
    getWithdrawTransfer(id) {
      this.$messageConfirm(`确认微信转账?`, () => {
        reqWithdrawTransfer({ id }).then(res => {
          this.$messageNotify(res);
        })
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer_box_item {
  padding: 0 20px;
  height: 70vh;
  overflow-y: scroll;
}

.mr10 {
  margin-right: 10px;
}
.nav {
  padding: 24px;
}
.ly_txt {
  text-align: right;
}
.row-main {
  line-height: 40px;
}

.image_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .image_item {
    width: 160px;
    height: 214px;
    border-radius: 5px;
    margin-right: 20px;
    margin-bottom: 30px;
    .image {
      width: 100%;
      height: 100%;
      margin-top: 10px;
    }
  }
}

::v-deep .el-switch__core {
  margin: 0 10px;
}
</style>
