<template>
  <div class="orderInfo" v-loading="pageLoading">
    <page-header content="订单详情" back-path="financeOrderList" @saveClick="onSubmit">
      <template slot="headerBtnTwo">
        <el-button type="primary" plain size="small" @click="completedOrder()" v-if="!tempStatus & (id != 0)">
          完成订单
        </el-button>
        <el-button type="success" plain size="small" @click="financeChangeStatus()" v-if="tempStatus & !form.commission_status & (id != 0)">
          订单结算
        </el-button>
      </template>
    </page-header>

    <div class="nav">
      <page-body :navBg="navBg">
        <template slot="title">订单信息</template>
        <el-form ref="ruleForm" :model="form" :rules="rules" label-width="90px" label-position="left">
          <el-row :gutter="20">
            <el-col :span="16" class="left">

              <el-form-item label="行政区:">
                <el-cascader ref="regionRef" v-model="address_code" placeholder="请选择行政区" :options="xzqList"
                  :props="defaultProps" filterable clearable @change="xzqChange"></el-cascader>
              </el-form-item>

              <el-form-item label="门店:" prop="user_id">
                <el-select v-model="form.user_id" :clearable="true" @change="userChange" filterable
                  :disabled="form.commission_status == 1">
                  <el-option v-for="item in userList" :key="item.id" :label="getAgentName(item)" :value="item.user_id">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="分润比例:" prop="ratio">
                <el-input v-model="form.ratio" @blur="ratioChange" style="width: 50%"
                  :disabled="form.commission_status == 1">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>

              <el-button type="primary" @click="drawer = true" :disabled="form.commission_status == 1">选择商品</el-button>
              <el-table stripe :data="chooseGoods" class="tableClass" :max-height="300">
                <!-- 空数据状态的插槽 -->
                <template slot="empty">
                  <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column label="ID" prop="table_id" width="80" fixed />

                <el-table-column label="名称" prop="table_title" show-overflow-tooltip />
                <el-table-column label="封面图" width="160">
                  <template slot-scope="scope">
                    <el-image style="width: 100px; height: 60px" :src="scope.row.table_image" fit="cover" />
                  </template>
                </el-table-column>
                <el-table-column label="单价" prop="price" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="
                      !showEdit || scope.row.table_id != tempGoods.table_id
                    ">{{ scope.row.price }}</span>
                    <el-input v-else v-model="scope.row.price" />
                  </template>
                </el-table-column>
                <el-table-column label="数量" prop="product_num" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="
                      !showEdit || scope.row.table_id != tempGoods.table_id
                    ">{{ scope.row.product_num }}</span>
                    <el-input v-else v-model="scope.row.product_num" />
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="120" fixed="right">
                  <template slot-scope="{ row }">
                    <el-button type="text" @click="editGoods(row)"
                      v-if="!showEdit || row.table_id != tempGoods.table_id"
                      :disabled="form.commission_status == 1">编辑</el-button>
                    <el-button type="text" style="color: red" @click="delGoods(row)"
                      v-if="!showEdit || row.table_id != tempGoods.table_id"
                      :disabled="form.commission_status == 1">删除</el-button>
                    <div v-else>
                      <el-button type="text" style="color: #999" @click="cancleEdit(row)"
                        :disabled="form.commission_status == 1">取消</el-button>
                      <el-button type="text" @click="sureEdit(row)"
                        :disabled="form.commission_status == 1">确认</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="totalPrice">{{ "总金额：" + totalPrice + "元" }}</div>
            </el-col>
            <el-divider direction="vertical" class="divider"></el-divider>
            <el-col :span="8" class="right">
              <el-form-item label="利润:" prop="profit">
                <el-input v-model="form.profit" @blur="profitChange" :disabled="form.commission_status == 1">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <div>
                <el-timeline :reverse="true">
                  <div v-for="(item, index) in userTree" :key="index">
                    <el-timeline-item reverse="true" v-if="item" icon="el-icon-user" type="primary" size="large"
                      placement="top" :timestamp="getUserName(item)">
                      <el-card class="treeCard">
                        <p>
                          等级身份:{{
                            item.user_info
                              ? item.user_info.system_agent_name
                              : item.system_agent_name
                          }}
                        </p>
                        <p v-if="item.price">
                          分润:{{ $thousandth(item.price, 1) }}
                        </p>
                      </el-card>
                    </el-timeline-item>
                  </div>
                </el-timeline>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </page-body>
    </div>

    <el-drawer title="选择商品" :visible.sync="drawer" :direction="'rtl'" size="60%">
      <div class="drawer_box">
        <div style="padding: 0 20px">
          <el-table ref="mulGoods" stripe :data="goodsList" @selection-change="handleSelectionChange"
            class="tableClass">
            <!-- 空数据状态的插槽 -->
            <template slot="empty">
              <el-empty :image-size="100" description="暂无数据"></el-empty>
            </template>
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="ID" prop="id" width="80" fixed />
            <el-table-column label="封面图" width="160">
              <template slot-scope="scope">
                <el-image style="width: 100px; height: 60px" :src="scope.row.goods_image" fit="cover" />
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="title" show-overflow-tooltip />

            <el-table-column label="单价" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ $thousandth(scope.row.sku[0].price, 1) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="demo-drawer__footer">
          <el-button class="btn" size="small" @click="cancleDrawer">取 消</el-button>
          <el-button class="btn" type="primary" size="small" @click="sureGoods">确 认</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getOrderInfo, reqOrderSave } from "@/api/order";
import { reqGetAddrUser, getUserTree } from "@/api/member";
import { getGoodsList } from "@/api/goods";
import { reqOrderCommissionStatus } from "@/api/order"
import PageBody from "@/components/PageBody/index.vue";
import sumJs from "@/utils/sum";
import axios from "axios";
import { getArea } from "@/utils/addrFilter";
export default {
  components: {
    PageBody,
  },
  data() {
    return {
      id: null,
      backPath: "financeOrderList",
      btnStatus: true, // 按钮点击状态
      pageForm: {
        page: 1,
        page_size: 10,
        search_sub: 1,
        search_id: "",
        search_name: "",
        search_phone: "",

        //
        type: "all",
        search_address_code: [],
      },
      pageLoading: false,
      navBg: false,
      form: {
        ratio: 20,
      },
      rules: {
        user_id: [{ required: true, message: "请选择门店", trigger: "blur" }],
        ratio: [{ required: true, message: "请输入分润比例", trigger: "blur" }],
        profit: [{ required: true, message: "请输入利润", trigger: "blur" }],
      },
      userList: [],
      goodsList: [],
      tempChooseList: [],
      chooseGoods: [],
      showEdit: false,
      tempGoods: {},
      drawer: false,
      totalPrice: "",
      userTree: [],
      tempStatus: 0, //临时订单状态
      //行政区下拉选项
      address_code: [],
      xzqList: [],
      defaultProps: {
        label: "value",
        value: "code",
        children: "children",
      },
    };
  },
  created() {
    this.id = this.$route.query.id ? Number(this.$route.query.id) : 0;
    this.init();
  },
  computed: {
    getAgentName() {
      return function (item) {
        return item.real_name + " - " + item.system_agent_name;
      };
    },
    getUserName() {
      return function (item) {
        let name = "";
        if (item.user_info) {
          let userInfo = item.user_info;
          name =
            userInfo.real_name +
            "(" +
            userInfo.province +
            "-" +
            userInfo.city +
            "-" +
            userInfo.area +
            ")";
        } else {
          name =
            item.real_name +
            "(" +
            item.province +
            "-" +
            item.city +
            "-" +
            item.area +
            ")";
        }
        return name;
      };
    },
  },
  methods: {
    async init() {
      await this.getGoodsList();
      await this.getXzqList();
      if (this.id > 0) {
        await this.getInfo();
      }
    },
    // 订单详情数据
    getInfo() {
      // 数据初始化
      this.pageLoading = true;

      getOrderInfo({ id: this.id }).then((res) => {
        this.pageLoading = false;
        this.form = res.result;
        if (this.form.status) {
          this.tempStatus = 1;
        }
        this.chooseGoods = this.form.product;
        this.form.profit = this.$thousandth(this.form.profit, 1);
        if (this.chooseGoods.length) {
          this.getTotalPrice();
        }
        if (this.form.commission.length) {
          this.userTree = this.form.commission;
        } else {
          this.getUserTree();
        }
        if (this.form.user) {
          this.address_code = this.form.user.address_code;

          //
          this.pageForm.search_address_code = this.address_code
          this.getUserList()
        }
      });
    },
    getUserList() {
      reqGetAddrUser(this.pageForm).then((res) => {
        console.log( res )
        this.userList = res.result
      });
    },
    getGoodsList() {
      getGoodsList(this.pageForm).then((res) => {
        this.goodsList = res.result.data;
      });
    },
    handleSelectionChange(val) {
      this.tempChooseList = val;
    },
    //确认选择商品
    sureGoods() {
      this.tempChooseList.forEach((item) => {
        let goods = this.chooseGoods.find((ele) => item.id == ele.table_id);
        if (!goods) {
          this.chooseGoods.push({
            id: 0,
            table_id: item.id,
            table_title: item.title,
            price: sumJs.accDiv(item.sku[0].price, 100),
            product_num: 1,
            table_image: item.goods_image,
            table_sku_id: item.sku[0].id,
          });
        }
      });
      this.drawer = false;
      this.getTotalPrice();
    },
    //删除选择商品
    delGoods(row) {
      let ids = this.chooseGoods.findIndex(
        (ele) => row.table_id == ele.table_id
      );
      this.chooseGoods.splice(ids, 1);
      this.getTotalPrice();
    },
    cancleDrawer() {
      this.drawer = false;
    },
    editGoods(row) {
      this.tempGoods = JSON.parse(JSON.stringify(row));
      this.showEdit = true;
    },
    sureEdit(row) {
      this.showEdit = false;
      this.tempGoods = {};
      this.getTotalPrice();
    },
    cancleEdit(row) {
      row.price = this.tempGoods.price;
      row.product_num = this.tempGoods.product_num;
      this.showEdit = false;
    },
    //确认完成订单
    completedOrder() {
      this.$messageConfirm(`是否确认完成订单?`, () => {
        this.form.status = 1;
        this.onSubmit();
      });
    },
    //保存信息
    onSubmit() {
      this.pageLoading = true;
      // 基础设置数据
      let obj = this.form;
      obj.id = this.id;
      if (obj.profit) {
        obj.profit = obj.profit.replace(/,/g, "") * 100;
      }
      obj.product = this.chooseGoods;
      if (!obj.status) {
        obj.status = 0;
      }
      if (!obj.product.length) {
        this.$message.warning("请选择商品");
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          reqOrderSave(obj)
            .then((res) => {
              this.$messageNotify(res);
              this.pageLoading = false;
              this.$router.push({ path: this.backPath });
            })
            .catch((err) => {
              this.form.status = this.tempStatus;
              this.pageLoading = false;
            });
        } else {
          this.form.status = this.tempStatus;
          this.pageLoading = false;
        }
      });
    },
    // 结算
    financeChangeStatus() {
      this.$messageConfirm(`确认结算?`, () => {
        reqOrderCommissionStatus({
          order_id:this.id,
        }).then((res) => {
          this.$messageNotify(res);
        });
      });
    },
    //获取商品总价
    getTotalPrice() {
      let totalPrice = 0;
      this.chooseGoods.forEach((item) => {
        let price = sumJs.accMul(item.price, Number(item.product_num));
        totalPrice = sumJs.accAdd(totalPrice, price);
      });
      this.totalPrice = totalPrice;
    },
    //获取等级树(更换店家时)
    getUserTree() {
      this.userTree = [];
      getUserTree({ user_id: this.form.user_id })
        .then((res) => {
          if (res.result) {
            this.userTree = res.result.filter(
              (item) =>
                item !== null && typeof item !== "undefined" && item !== ""
            );
            this.getCommission();
          }
        })
        .catch((e) => {
          this.userTree = [];
        });
    },
    //计算分润(更换店家或更换分润比例或利润更改后)
    getCommission() {
      //let data = this.userTree;
      if (!this.form.ratio || !this.form.profit) {
        return;
      }
      this.userTree.forEach((item, i) => {
        let ratio = sumJs.accDiv(Number(this.form.ratio), 100);
        let profit = this.form.profit.replace(/,/g, "");
        if (i == 0) {
          item.price = sumJs.accMul(Number(profit), Number(this.form.ratio));
        } else {
          item.price = sumJs.accMul(this.userTree[i - 1].price, ratio);
        }
      });
      this.$forceUpdate();
    },
    userChange(data) {
      if (data) {
        this.getUserTree();
      } else {
        this.userTree = [];
      }
    },
    profitChange() {
      this.getCommission();
    },
    ratioChange() {
      this.getCommission();
    },
    //获取行政区列表
    getXzqList() {
      const url = "/addr.json";
      axios
        .get(url)
        .then((response) => {
          this.xzqList = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    //选择行政区
    xzqChange(data) {
      console.log("选择行政区 --- > ", data)
      if (data.length > 0 ){
        this.pageForm.search_address_code = data
        this.getUserList();
      }
      this.$refs.regionRef.dropDownVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.orderInfo {
  margin: 20px;
  padding-bottom: 20px;
  background-color: #fff;

  .commodity_content {
    padding: 0;
  }

  .totalPrice {
    margin-top: 10px;
    font-size: 16px;
  }

  .divider {
    height: 100%;
    margin: 0;
    position: absolute;
  }

  ::v-deep .el-card__body {
    padding: 10px;
  }

  ::v-deep .el-timeline-item__timestamp {
    color: #409eff;
    font-size: 15px;
  }
}

.nav {
  padding: 24px;
}
</style>
<style scoped>
.tableClass>>>tr {
  font-size: 12px;
}

.tableClass>>>.el-table__cell {
  padding: 3px 0;
}
</style>
