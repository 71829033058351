<template>
  <div class="app-container">
    <!-- 搜索 -->
    <!-- <query-form
      :queryForm="queryForm"
      :queryData="queryData"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    /> -->

    <div style="margin-bottom: 5px;">
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        @click="getInfo()"
        >新增</el-button
      >
      <!-- <el-button type="danger" :disabled="selectAllStatus" plain size="medium" @click="removeAll">批量删除</el-button> -->
    </div>

    <el-table
      stripe
      v-loading="loading"
      :data="pageData"
      element-loading-text="加载中..."
      :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
      @selection-change="handleSelectionChange"
    >
      <!-- 空数据状态的插槽 -->
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>

      <el-table-column label="ID" width="50">
        <template slot-scope="scope">{{ scope.row.id }}</template>
      </el-table-column>
      <el-table-column label="名称">
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>

      <el-table-column label="创建时间">
        <template slot-scope="scope">{{ scope.row.created_at }}</template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="80">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link operation">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="getInfo(scope.row)">
                <span>编辑</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="getRemove(scope.row.id)">
                <span>删除</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="pageSize.total"
      :page.sync="pageSize.current_page"
      :limt.sync="pageSize.per_page"
      @pagination="pagination"
    />

    <!-- 等级名称 -->
    <el-dialog
      title="等级名称"
      :visible.sync="levelNameDialog"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :rules="rules" :model="form">
        <el-form-item label="等级名称" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="levelNameDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitLevel"
          :loading="confirmLoading"
          >{{ confirmLoading ? "保存中" : "确 定" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLabelList,
  getLabelSave,
  getLabelRemove,
  getLabelRemoveAll,
} from "@/api/member";
import Pagination from "@/components/Pagination/index.vue";

export default {
  name: "userLabelList",

  components: {
    Pagination,
  },

  data() {
    return {
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 10,
        search_name: "",
      },
      // 搜索框
      queryForm: [
        {
          type: "input",
          label: "名称",
          model: "search_name",
          placeholder: "请输入",
        },
      ],

      // 页面数据
      pageData: [],
      pageSize: {},
      removeData: [], // 批量删除

      // 页面状态
      loading: false,
      selectAllStatus: true,

      levelNameDialog: false,
      confirmLoading: false,
      form: {
        id: 0,
      },
      // 表单校验规则
      rules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.getList();
    },

    getList() {
      this.loading = true;
      getLabelList(this.queryData).then((res) => {
        const { data, total, current_page, per_page } = res.result;

        this.pageData = data;
        this.pageSize = {
          total,
          current_page,
          per_page,
        };

        this.loading = false;
      });
    },

    // 查询按钮
    handleQuery(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 重置按钮
    handleReset(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 选择表格操作
    handleSelectionChange(val) {
      if (val.length) {
        this.selectAllStatus = false;
        val.map((item) => {
          this.removeData.push(item.id);
        });
        this.removeData = [...new Set(this.removeData)];
      } else {
        this.selectAllStatus = true;
      }
    },

    // 分页点击事件
    pagination(data) {
      this.queryData = { ...this.queryData, ...data };
      this.getList();
    },

    // 新增 || 编辑
    getInfo(info) {
      this.form = info ? info : this.$options.data().form;
      this.levelNameDialog = true;
    },
    submitLevel() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          getLabelSave(this.form).then((res) => {
            setTimeout(() => {
              this.confirmLoading = false;
              this.$messageNotify();
              this.levelNameDialog = false;
              this.getList();
              // this.$emit('editUserInfo');
            }, 500);
          });
        }
      });
    },

    // 删除
    getRemove(id) {
      this.$messageConfirm(`确认删除?`, () => {
        getLabelRemove({
          id,
        }).then((res) => {
          this.$messageNotify(res);
          this.getList();
        });
      });
    },

    // 批量删除
    // removeAll() {
    //   if (this.selectAllStatus) {
    //     return this.$message.warning("请先选中数据再操作!");
    //   }
    //   this.$messageConfirm(`确认批量删除?`, () => {
    //     getLabelRemoveAll({
    //       ids: this.removeData
    //     }).then(res => {
    //       this.$messageNotify(res);
    //       this.getList()
    //     })
    //   })
    // },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 16px;
}

.header {
  padding-left: 15px;

  .header_title {
    padding-left: 10px;
    border-left: 3px solid #2f9be9;
    font-weight: 600;
  }
}

.ability {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 15px;
  padding: 15px;
}

.operation {
  color: #1890ff;
  cursor: pointer;
}
</style>
