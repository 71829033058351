<template>
  <div class="container"
       style="min-height: 600px">
    <page-header :content="id > 0 ? '编辑商品' : '新增商品'"
                 :back-path="backPath"
                 @saveClick="onSubmit" />
    <basics-setting :formList="formList"
                    :type="id == 0 ? 2 : 1"
                    ref="basics" />
  </div>
</template>

<script>
import { getGoodsInfo, getGoodsSave } from '@/api/goods'
import basicsSetting from './infoAssembly/basicsSetting.vue'

export default {
  name: 'goodsInfo',

  components: {
    basicsSetting,
    // goodsSetting,
    // paySetting,
    // hotSetting,
  },

  data() {
    return {
      id: 0,
      goods_type: 0,
      backPath: 'goodsList',

      loading: false,
      pageLoading: false,
      formList: {},

      shareSetting: {
        posterImage: {
          id: 0,
          image: null,
        },
      },

      tabIndex: 0,
      titleList: ['基础设置', '商品设置', '购买设置', '开始预热'],
    }
  },

  created() {
    this.id = this.$route.query.id
    if (this.id > 0) {
      this.getInfo(this.id)
    } else {
      this.loading = true
    }

    // if (this.$route.query.type) {
    //   this.goods_type = this.$route.query.type
    //     ? Number(this.$route.query.type)
    //     : 0
    //   if (Number(this.$route.query.type) == 1) {
    //     this.backPath = 'newcomerGoods'
    //     this.$route.meta.activeMenu = '/goods/newcomerGoods'
    //   }
    //   if (Number(this.$route.query.type) == 2) {
    //     this.backPath = 'seckillGoods'
    //     this.$route.meta.activeMenu = '/goods/seckillGoods'
    //   }
    //   if (Number(this.$route.query.type) == 3) {
    //     this.backPath = '/live/liveGoods'
    //     this.$route.meta.activeMenu = '/live/liveGoods'
    //   }
    //   if (Number(this.$route.query.type) == 4) {
    //     this.backPath = '/live/livePlaybackGoods'
    //     this.$route.meta.activeMenu = '/live/livePlaybackGoods'
    //   }
    // } else {
      this.$route.meta.activeMenu = '/goods/goodsList'
    // }
  },

  methods: {
    tabClick(index) {
      this.tabIndex = index
    },

    // 获取数据
    getInfo(id) {
      let that = this
      getGoodsInfo(id).then((res) => {
        const { result } = res
        that.formList = result
        that.formList.sku.forEach((item) => {
          item.original_price = that.$sumJs.accDiv(item.original_price, 100)
          item.vip_price = that.$sumJs.accDiv(item.vip_price, 100)
          item.price = that.$sumJs.accDiv(item.price, 100)
        })
        if (that.formList.deliverySetting.fee)
          that.formList.deliverySetting.fee = that.$sumJs.accDiv(
            that.formList.deliverySetting.fee,
            100
          )
        if (
          typeof result.shareSetting === 'object' &&
          !Object.keys(result.shareSetting).length
        ) {
          that.formList.shareSetting = that.shareSetting
        } else if (
          typeof result.shareSetting === 'array' &&
          !result.shareSetting.length
        ) {
          that.formList.shareSetting = that.shareSetting
        }

        that.loading = true
      })
    },

    onSubmit() {
      this.pageLoading = true

      this.$refs.basics.formRules().then((res) => {
        if (!res) {
          this.pageLoading = false
          return this.$message.error('请完善基础设置！')
        }

        // 基础设置数据
        let obj = this.$refs.basics.formLists
        obj.id = this.id
        // 商品规格数据
        obj.skus = this.$refs.basics.commodityList
        // for (let i in obj.skus) {
        //   if (
        //     obj.skus[i].original_price != 0 &&
        //     obj.skus[i].price > obj.skus[i].original_price
        //   ) {
        //     this.$message.warning("销售价格不能大于划线价格");
        //     return;
        //   }
        // }

        getGoodsSave(obj)
          .then((res) => {
            this.$messageNotify(res)
            this.pageLoading = false
            this.$router.push({ path: this.backPath })
          })
          .catch((err) => {
            this.pageLoading = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  // margin: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.nav {
  padding: 24px;
}
</style>
