<template>
  <div class="app-container">
    <el-row class="box" v-loading="loading">
      <el-col :span="6" class="box_left" v-if="!loading" :key="resetKey">
        <profile-info :user="user" @editUserInfo="editUserInfo" />
      </el-col>

      <el-col :span="18" class="box_right" v-if="!loading" :key="resetKey + 1">
        <el-row>
          <!-- 基本信息 -->
          <basic-info :user="user" @editUserInfo="editUserInfo" />
        </el-row>
        <el-row>
          <el-tabs v-model="activeName" @tab-click="tabClick">
            <el-tab-pane
              v-for="item in tabData"
              :key="item.id"
              :label="item.name"
              :name="item.name"
            >
              <div slot="label">{{ item.name }}</div>
            </el-tab-pane>
          </el-tabs>
          <sub-list
            :user="user"
            v-show="activeName === '子身份'"
            @editUserInfo="editUserInfo"
          />
          <!-- 进货订单 -->
          <order-list
            ref="order"
            :user_id="id"
            v-show="activeName === '进货订单'"
          />
          <!-- 分红订单 -->
          <dividendOrders
            ref="dividendOrder"
            :user_id="id"
            v-show="activeName === '分红订单'"
          />
          <!-- 上下级 -->
          <user-tree
            ref="userList"
            :user="user"
            v-show="activeName === '上下级'"
          />
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import profileInfo from "../user/profileInfo.vue";
import basicInfo from "../user/basicInfo.vue";
import orderList from "../user/orderList.vue";
import dividendOrders from "../user/dividendOrders.vue";
import subList from "../user/subList.vue";
import userTree from "../user/userTree.vue";
import { reqUserInfo } from "@/api/member";

export default {
  name: "userInfo",

  components: {
    basicInfo,
    orderList,

    profileInfo,
    subList,
    userTree,
    dividendOrders,
  },

  data() {
    return {
      id: 0,
      loading: true,
      activeName: "子身份",
      tabIndex: null,
      tabData: [
        { id: 0, name: "子身份" },
        // { id: 1, name: "进货订单" },
        { id: 2, name: "分红订单" },
        { id: 3, name: "上下级" },
      ],

      // 刷新key
      resetKey: 0,

      user: {
        nickname: "--",
        avatar: "",
        user_id: 0,
        p_id: 0,
        open_id: null,
        h5_open_id: null,
      },
    };
  },
  created() {
    this.id = this.$route.query.id ? Number(this.$route.query.id) : 0;

    if (this.id) {
      this.getInfo();
    } else {
      this.loading = false;
    }
  },
  methods: {
    async getInfo() {
      this.loading = true;

      await reqUserInfo({
        id: this.id,
      }).then((res) => {
        const { result } = res;

        this.user = result;
        this.loading = false;
        this.resetKey += 1;
        if (this.user.base.system_agent_id == 5) {
          this.tabData.push({
            id: 1,
            name: "进货订单",
          });
        }
      });
    },

    // 切换 tab
    tabClick(val) {
      let name = val.name;
      this.tabIndex = Number(val.index);

      // 进货订单请求
      if (name == '进货订单') {
        this.$refs.order.getList();
      }
      // 分红订单请求
      if (name == '分红订单') {
        this.$refs.dividendOrder.getList();
      }
      // 上下级
      if (name == '上下级') {
        this.$refs.userList.init();
      }
    },

    editUserInfo() {
      this.getInfo();
      // if (this.tabIndex == 3) this.$refs.customer.init();
      // if (this.tabIndex == 4) this.$refs.operation.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  .box_left {
    margin-right: 20px;
  }
  .box_right {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 1px solid #e6ebf5;
    padding: 0 20px;
  }
}
</style>
