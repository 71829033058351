import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f60f2948&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ryuuuuuu/Desktop/中国码云/名医堂/yktz-accounting-system-vue/node_modules/.store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f60f2948')) {
      api.createRecord('f60f2948', component.options)
    } else {
      api.reload('f60f2948', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=f60f2948&", function () {
      api.rerender('f60f2948', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Sticky/index.vue"
export default component.exports