<template>
  <div class="commodity">
    <div class="commodity_header">
      <div class="commodity_header_left">

        <div>
          <span><slot name="title" /></span> 
          <slot name="desc" />
        </div> 

        <el-switch v-if="switchStatus" :value="switchValue" class="el_switch_small" :inactive-value="inactiveValue" :active-value="activeValue" @change="onClick($event, 'switch')" active-text="开启" inactive-text="关闭" />

        <div class="btns" v-if="buttonStatus">
          <el-button size="small" type="primary" @click="onClick($event, 'click')">{{ btnTitle }}</el-button>

          <slot name="btnTwo" />
        </div>
        
      </div>
    </div>
    
    <transition name="fade">
      <div class="commodity_content" v-if="navState">
        <div class="commodity_list" :style="{ background: (navBg ? '#f7f7f7' : '#fff'), border: (navBg ? '1px solid #f7f7f7' : '0') }">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      navBg: {
        type: Boolean,
        default: true
      },
      navStatus: {
        type: [Boolean, Number],
        default: true
      },
      switchStatus: {
        type: Boolean,
        default: false
      },
      buttonStatus: {
        type: Boolean,
        default: false
      },
      btnTitle: {
        type: String,
        default: '按钮'
      },
      switchType: {
        type: Boolean,
        default: false,
      },
      switchValue: {
        type: [Boolean, Number]
      },
      inactiveValue: {
        type: [Boolean, String, Number],
        default: 0
      },
      activeValue: {
        type: [Boolean, String, Number],
        default: 1
      },
    },
    data() {
      return {
        navState: this.navStatus,
      }
    },
    watch: {
      navStatus(val) {
        this.navState = val;
      }
    },
    methods: {
      onClick(e, type) {
        this.$emit('onClick', e);
        if (type == 'switch') {
          this.navState = e;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .commodity {
    border: 1px solid #e8e8e8;
    box-shadow: 0 2px 8px 0 #e8e8e8, 0 2px 10px 0 #e8e8e8;
  
    .commodity_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 48px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      background-color: #f5f5f9;
      padding: 14px 24px;

      .commodity_header_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #a4abb4;
        span {
          font-weight: bold;
          font-size: 16px;
          color: #000;
          margin-right: 14px;
        }
      }
    }

    .commodity_content {
      padding: 24px;
      .commodity_list {
        padding: 10px;
        font-size: 14px;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: .2s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>