var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-upload", {
        ref: _vm.torefUpload,
        staticClass: "upload-demo editor-slide-upload",
        attrs: {
          data: _vm.dataObj,
          action: "https://up-z2.qiniup.com/",
          name: "file",
          "before-upload": _vm.beforeAvatarUpload,
          "on-success": _vm.handleSuccess,
          "on-exceed": _vm.handleExceed,
          "show-file-list": false,
          multiple: "",
          limit: 5,
        },
      }),
      _c("quill-editor", {
        ref: _vm.toref,
        staticStyle: { height: "450px" },
        attrs: { options: _vm.editorOption },
        on: {
          change: function ($event) {
            return _vm.$emit("input", _vm.content)
          },
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }