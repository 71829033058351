<template>
  <div class="basicSetting">
    <el-form ref="ruleForm"
             :rules="rules"
             :model="formLists"
             label-width="80px">
      <el-form-item label="商品标题"
                    prop="title">
        <el-input v-model="formLists.title"
                  placeholder="请输入商品标题"
                  maxlength="50"
                  :show-word-limit="true" />
      </el-form-item>
      <el-form-item label="商品分类"
                    prop="category_id">
        <el-select v-model="formLists.category_id"
                   placeholder="选择分类">
          <el-option :key="0"
                     label="请选择"
                     :value="0" />

          <el-option v-for="item in categoryList"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id" />
        </el-select>
        <div class="category">
          还没有商品分类？<span @click="onPageJump">点击快速新增分类</span>
        </div>
      </el-form-item>
      <!-- <el-form-item label="封面比例">
        <el-radio-group v-model="poster_bl">
          <el-radio :label="1">16 : 9</el-radio>
          <el-radio :label="2">3 : 4</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="商品封面"
                    prop="goods_image">
        <upload-img :fileUrl="formLists.goods_image"
                    @uploadImgPath="uploadImgPath($event, 'img')" />
        <el-button type="danger"
                   @click="deleteFile('img')"
                   v-if="formLists.goods_image">删除图片</el-button>
      </el-form-item>
      <!-- <el-form-item label="商品封面" prop="goods_image" v-show="poster_bl == 2">
        <upload-img
          :fileUrl="formLists.goods_image43"
          imgStyle="column"
          @uploadImgPath="uploadImgPath($event, 'img43')"
          v-show="poster_bl == 2"
        />
        <el-button
          type="danger"
          @click="deleteFile('img43')"
          v-if="formLists.goods_image43"
          >删除图片</el-button
        >
      </el-form-item> -->

      <!-- <el-form-item label="详情视频" prop="goods_video">
        <upload-video
          :fileUrl="formLists.goods_video"
          @uploadVideoPath="uploadVideoPath"
        />

        <el-button
          type="danger"
          @click="deleteFile('video')"
          v-if="formLists.goods_video"
          class="mt"
          >删除视频</el-button
        >
      </el-form-item>
      <el-form-item label="详情图片" prop="goods_albums">
        <upload-list
          :imageList="formLists.goods_albums"
          @uploadListPath="uploadListPath"
        />
      </el-form-item> -->
      <!-- 商品规格 -->
      <div v-for="(item, index) in commodityList"
           :key="index">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="规格名称:">
              <el-input v-model="item.name"
                        size="small" />
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item label="产品库存:">
              <div class="comdt_left_input"
                   style="margin-right: 30px;">
                <el-input-number v-model="item.quantity"
                                 :min="0"
                                 size="small"
                                 controls-position="right" />
                <div class="list_footer_num_company">个</div>
              </div>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item label="销售价格:">
              <div class="comdt_left_input"
                   style="margin-right: 30px;">
                <el-input-number v-model="item.price"
                                 :min="0"
                                 size="small"
                                 controls-position="right"
                                 :precision="2" />
                <div class="list_footer_num_company">元</div>
              </div>
            </el-form-item>

          </el-col>
        </el-row>
      </div>
      <!-- <page-body :navBg="false"
                 :buttonStatus="true"
                 btnTitle="添加商品规格"
                 @onClick="addCommodity">
        <template slot="title">商品规格</template>
        <template slot="desc">设置商品的规格，若多个规格则添加多个商品规格</template>
        <div v-for="(item, index) in commodityList"
             :key="index">

          <div class="commodity_list"
               v-if="!item.del_status"
               style="padding-bottom: 20px">
            <div class="commodity_list_top">
              <div class="list_top_comdt">
                <div class="comdt_left">
                  <span class="span active">规格名称:</span>
                  <div style="margin-right: 30px;">
                    <el-input v-model="item.name"
                              size="small" />
                  </div>
                </div>
                <div class="comdt_left">
                  <span class="span active">产品库存:</span>
                  <div class="comdt_left_input"
                       style="margin-right: 30px;">
                    <el-input-number v-model="item.quantity"
                                     :min="0"
                                     size="small"
                                     controls-position="right" />
                    <div class="list_footer_num_company">个</div>
                  </div>
                </div>
                <div class="comdt_left">
                  <span class="span actives">销售价格:</span>
                  <div class="comdt_left_input"
                       style="margin-right: 30px;">
                    <el-input-number v-model="item.price"
                                     :min="0"
                                     size="small"
                                     controls-position="right"
                                     :precision="2" />
                    <div class="list_footer_num_company">元</div>
                  </div>
                </div>
              </div>
              <div>
                <el-button type="danger"
                           icon="el-icon-delete"
                           size="small"
                           circle
                           @click="removeClick(item, index)"></el-button>
              </div>
            </div>

          </div>
        </div>
      </page-body> -->
      <el-form-item label="商品描述"
                    prop="description">
        <el-input type="textarea"
                  v-model="formLists.description"
                  placeholder="请输入商品描述"
                  maxlength="200"
                  :show-word-limit="true" />
      </el-form-item>
      <el-form-item label="商品详情"
                    prop="detail">
        <newEditor v-model="formLists.detail"
                   :quillIndex="1"
                   toref="Editorc1"></newEditor>

      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import PageBody from '@/components/PageBody/index.vue'
// import { getAllGoodsCategory } from "@/api/public";
import { getGoodsCategoryList } from '@/api/goods'
import UploadImg from '@/components/Upload/uploadImg.vue'
import UploadVideo from '@/components/Upload/uploadVideo.vue'
import uploadList from '@/components/Upload/uploadList.vue'
export default {
  name: 'goodsInfoBasics',

  components: {
    UploadImg,
    UploadVideo,
    uploadList,
    PageBody,
  },

  props: {
    formList: {
      type: Object,
      default: () => {},
    },

    type: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'], // 链接、图片、视频
          ],
        },
        placeholder: '请输入正文',
      },
      poster_bl: 1,
      form: {
        goods_image: '',
        // goods_image43: '',
        // goods_video: '',
        // goods_albums: [],
      },

      image_path: '',
      listForm: {
        page: 1,
        page_size: 100,
      },

      categoryList: [],
      // 表单校验规则
      rules: {
        title: [{ required: true, message: '请输入商品标题', trigger: 'blur' }],
        goods_image: [
          { required: true, message: '请选择商品封面图', trigger: 'change' },
        ],
        // goods_albums: [
        //   { required: true, message: '请选择商品详情图', trigger: 'change' },
        // ],
        description: [
          { required: true, message: '请输入商品描述', trigger: 'blur' },
        ],
        detail: [
          { required: true, message: '请输入商品详情', trigger: 'blur' },
        ],
        category_id: [
          { required: true, message: '请选择商品分类', trigger: 'change' },
        ],
      },

      // 规格数据
      commoditys: [
        {
          name: '',
          price: 0,
          quantity: 1000,
        },
      ],
      // 删除的规格加进来
      newList: [],
      del: 0,
    }
  },

  computed: {
    formLists() {
      if (this.type == 1) {
        return this.formList
      } else {
        return this.form
      }
    },
    commodityList() {
      if (this.type == 1) {
        return this.formList.sku
      } else {
        return this.commoditys
      }
    },
  },

  created() {
    this.getCategoryList()
  },

  methods: {
    getCategoryList() {
      getGoodsCategoryList({
        page: 1,
        page_size: 100,
      }).then((res) => {
        this.categoryList = res.result.data
      })
    },

    // 图片上传成功
    uploadImgPath(path, type) {
      if (type == 'img') {
        this.formLists.goods_image = path
      }
      if (type == 'img43') {
        this.formLists.goods_image43 = path
      }
    },

    // 商品详情视频上传成功
    uploadVideoPath(path) {
      this.formLists.goods_video = path
    },

    // 删除图片或视频
    deleteFile(type) {
      if (type == 'img') {
        this.formLists.goods_image = ''
      } else if (type == 'img43') {
        this.formLists.goods_image43 = ''
      } else if (type == 'video') {
        this.formLists.goods_video = ''
      }
      this.$message.success('删除成功!')
    },

    // 商品详情图片上传成功
    uploadListPath(path) {
      this.formLists.goods_albums = path
    },

    formRules() {
      return new Promise((resolve) => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
    },

    // 新增分类
    onPageJump() {
      this.$router.push({ path: 'goodsCategory' })
    },

    // 添加规格
    addCommodity() {
      let data = {
        name: '',
        price: 0,
        quantity: 1000,
      }

      const arr = this.commodityList.filter((item) => !item.del_status)

      let flag = arr.every((item) => !!item.name)
      let priceFlag = arr.every((item) => item.price != 0)

      if (!flag) return this.$message.warning('请填写规格名称!')
      if (!priceFlag) return this.$message.warning('销售价格不能小于或等于0!')

      this.commodityList.push(data)
    },

    // 删除规格
    removeClick(obj) {
      this.$messageConfirm('', () => {
        this.removeCommodity(obj)
      })
    },

    removeCommodity(obj) {
      if (this.commodityList.length - this.newList.length == 1) {
        this.$message.warning('已经是最后一个不能在删除了!')
      } else {
        obj.del_status = 1
        this.newList.push(obj)
      }
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
.basicSetting {
  padding: 20px;
}
.category {
  color: #ababab;
}

.category span {
  cursor: pointer;
  color: #1890ff;
}

.mt {
  margin-top: 20px;
}
.comdt_left_input {
  display: flex;
  align-items: center;
  .list_footer_num_company {
    width: 36px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: rgba(220, 226, 232);
    border-radius: 0 4px 4px 0;
    margin-left: -6px;
  }
}
.commodity_list {
  background: rgba(247, 247, 247, 1.5);
  border: 1px solid rgba(247, 247, 247, 1.5);
  padding: 10px 10px 0;
  font-size: 14px;
  border-bottom: 1px solid #8c8c8c;

  .commodity_list_top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .list_top_comdt {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .comdt_left {
        display: flex;
        align-items: center;
        flex: 1;
        .comdt_left_input {
          display: flex;
          align-items: center;
          .list_footer_num_company {
            width: 36px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: rgba(220, 226, 232);
            border-radius: 0 4px 4px 0;
            margin-left: -6px;
          }
        }
      }
    }
  }

  .commodity_list_img {
    display: flex;
    margin: 20px 0 0;

    .commodity_list_img_text {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 10px;
      margin-left: 20px;
      color: #a4abb4;
    }
  }

  .commodity_list_footer {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 20px;

    .commodity_list_footer_item {
      display: flex;
      flex: 1;
      align-items: center;

      .list_footer_num {
        display: flex;
        align-items: center;

        .list_footer_num_company {
          width: 36px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: rgba(220, 226, 232);
          border-radius: 0 4px 4px 0;
          margin-left: -6px;
        }
      }
    }
  }
}
::v-deep .el-textarea__inner {
  height: 130px;
}
::v-deep .ql-container {
  height: 200px;
}
</style>
