<template>
  <div class="container">
    <page-header :content="form.id ? '编辑' : '新增'" back-path="rolesList" v-loading.fullscreen.lock="loading"
      @saveClick="onSubmit" />

    <el-form :model="form" ref="ruleForm" :rules="rules" label-width="120px" style="width: 90%;margin-top: 20px;">
      <el-form-item label="角色名:" prop="name">
        <el-input v-model="form.name" placeholder="请输入角色名称" />
      </el-form-item>
      <el-form-item label="状态:" prop="status">
        <el-switch class="el_switch" v-model="form.status" @change="switchChange" active-text="开"
          inactive-text="关"></el-switch>
      </el-form-item>
      <el-form-item label="页面权限">
        <!--
          show-checkbox: 节点是否可被选择  boolean
          expand-on-click-node: 是否在点击节点的时候展开或者收缩节点  boolean
          node-key: 每个树节点用来作为唯一标识的属性，整棵树应该是唯一的  String
          highlight-current: 是否高亮当前选中节点，默认值是 false  boolean
          data: 展示数据  array
          default-expanded-keys: 默认展开的节点的 key 的数组  array
          default-checked-keys: 默认勾选的节点的 key 的数组  array
          props: 数据配置项
            label	指定节点标签为节点对象的某个属性值  string, function(data, node)
            children	指定子树为节点对象的某个属性值  string
            disabled	指定节点选择框是否禁用为节点对象的某个属性值  boolean, function(data, node)
            isLeaf	指定节点是否为叶子节点，仅在指定了 lazy 属性的情况下生效
        -->
        <el-tree ref="tree" show-checkbox expand-on-click-node node-key="name" highlight-current
          :data="menuList" :default-expanded-keys="defaultRules" :default-checked-keys="defaultRules"
          :props="defaultProps" @check="checkTree" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getRolesInfo, getRolesSave } from '@/api/setting';
import { asyncRoutes } from "@/router"
export default {
  name: 'rolesInfo',
  data() {
    return {
      loading: false,
      form: {
        id: 0,
        name: null,
        status: true,
        rules: []
      },
      // 可选路由菜单
      menuList: [],
      // 默认选中的路由
      defaultRules: [],
      // 表单校验规则
      rules: {
        name: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
      },
      defaultProps: {
        children: "children",
        label: (data) => {
          return data.meta.title;
        }
      },
    }
  },

  created() {
    this.form.id = this.$route.query.id;
    this.menuList = asyncRoutes.filter(val => val.path != "*");
    this.getInfo();
  },

  methods: {
    // 获取详情
    getInfo() {
      getRolesInfo(this.form).then(res => {
        const { has_rules, role } = res.result;
        // 角色信息
        if (this.form.id != 0) {
          this.form = role;
          if (role.status == 1) {
            this.form.status = true;
          }
        }
        // 默认选中页面
        this.defaultRules = this.form.rules = has_rules;
      })
    },

    switchChange(e) {
      this.form.status = e;
    },

    checkTree() {
      this.form.rules = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
    },

    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 处理数据
          if (!this.form.rules.length) {
            return this.$message.warning('请选择相应的页面权限!');
          }
          const data = Object.assign({}, this.form)
          if (data.status == true) {
            data.status = 1
          } else {
            data.status = 0
          }

          getRolesSave(data).then(res => {
            this.$messageNotify(res);
            this.$router.push({ path: 'rolesList' });
          })

        }
      })
    }
  }
}
</script>

<style scoped>

</style>
