import request from '@/utils/request'

// 获取上传 Token
export function getToken(data) {
  return request({
    url: '/api/upload/getUploadToken',
    method: 'post',
    data
  })
}
