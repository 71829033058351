var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("query-form", {
        attrs: { queryForm: _vm.queryForm, queryData: _vm.queryData },
        on: { handleQuery: _vm.handleQuery, handleReset: _vm.handleReset },
      }),
      _c(
        "div",
        { staticClass: "sys_search_line" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.getInfo(0)
                },
              },
            },
            [_vm._v("添加角色")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "danger",
                disabled: _vm.selectAllStatus,
                plain: "",
                size: "medium",
              },
              on: { click: _vm.removeAll },
            },
            [_vm._v("批量删除")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            stripe: "",
            data: _vm.pageData,
            "element-loading-text": "加载中...",
            "header-cell-style": {
              backgroundColor: "#fafafa",
              color: "#606266",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c(
            "template",
            { slot: "empty" },
            [
              _c("el-empty", {
                attrs: { "image-size": 100, description: "暂无数据" },
              }),
            ],
            1
          ),
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "ID", width: "100", fixed: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.id))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "角色" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.name))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      staticClass: "el_switch_small",
                      attrs: {
                        value: scope.row.status,
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-text": "开启",
                        "inactive-text": "关闭",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.switchChange(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "span",
                          { staticClass: "el-dropdown-link operation" },
                          [
                            _vm._v(" 操作"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("el-dropdown-item", [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.getInfo(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]),
                            _c("el-dropdown-item", [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.getRemove(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          total: _vm.pageSize.total,
          page: _vm.pageSize.current_page,
          limt: _vm.pageSize.per_page,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageSize, "current_page", $event)
          },
          "update:limt": function ($event) {
            return _vm.$set(_vm.pageSize, "per_page", $event)
          },
          pagination: _vm.pagination,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }