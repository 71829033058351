<template>
  <el-form-item :prop="item.model" v-bind="$attrs">
    <!-- 输入框类型 -->
    <el-input
      v-if="item.type == 'input'"
      v-bind="$attrs"
      v-on="$listeners"
      :size="item.size"
    />
    <!-- 下拉框类型 -->
    <el-select
      v-else-if="item.type == 'select'"
      v-bind="$attrs"
      v-on="$listeners"
      :size="item.size"
    >
      <el-option
        v-for="option in item.options"
        :key="option.id"
        :label="option.name"
        :value="option.id"
        v-bind="option"
      />
    </el-select>
    <!-- 时间选择类型 -->
    <el-date-picker 
      v-if="item.type == 'date'" 
      v-bind="$attrs"
      v-on="$listeners"
      align="center" 
      type="datetimerange"
      start-placeholder="开始时间" 
      end-placeholder="结束时间"
      value-format="yyyy-MM-dd HH:mm:ss"
      :clearable="true"
      :picker-options="pickerOptions"
      :size="item.size"
    />
  </el-form-item>
</template>
<script>
export default {
  name: "QueryForm",
  inheritAttrs: false,
  props: ["item"],
  data() {
    return {
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() > Date.now();
        // },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  }
};
</script>