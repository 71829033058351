var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basicSetting" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.formLists,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入商品标题",
                  maxlength: "50",
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.formLists.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formLists, "title", $$v)
                  },
                  expression: "formLists.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品分类", prop: "category_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "选择分类" },
                  model: {
                    value: _vm.formLists.category_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formLists, "category_id", $$v)
                    },
                    expression: "formLists.category_id",
                  },
                },
                [
                  _c("el-option", {
                    key: 0,
                    attrs: { label: "请选择", value: 0 },
                  }),
                  _vm._l(_vm.categoryList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "category" }, [
                _vm._v(" 还没有商品分类？"),
                _c("span", { on: { click: _vm.onPageJump } }, [
                  _vm._v("点击快速新增分类"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品封面", prop: "goods_image" } },
            [
              _c("upload-img", {
                attrs: { fileUrl: _vm.formLists.goods_image },
                on: {
                  uploadImgPath: function ($event) {
                    return _vm.uploadImgPath($event, "img")
                  },
                },
              }),
              _vm.formLists.goods_image
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteFile("img")
                        },
                      },
                    },
                    [_vm._v("删除图片")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.commodityList, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "规格名称:" } },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "产品库存:" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "comdt_left_input",
                              staticStyle: { "margin-right": "30px" },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  size: "small",
                                  "controls-position": "right",
                                },
                                model: {
                                  value: item.quantity,
                                  callback: function ($$v) {
                                    _vm.$set(item, "quantity", $$v)
                                  },
                                  expression: "item.quantity",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "list_footer_num_company" },
                                [_vm._v("个")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售价格:" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "comdt_left_input",
                              staticStyle: { "margin-right": "30px" },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  size: "small",
                                  "controls-position": "right",
                                  precision: 2,
                                },
                                model: {
                                  value: item.price,
                                  callback: function ($$v) {
                                    _vm.$set(item, "price", $$v)
                                  },
                                  expression: "item.price",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "list_footer_num_company" },
                                [_vm._v("元")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: "商品描述", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入商品描述",
                  maxlength: "200",
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.formLists.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.formLists, "description", $$v)
                  },
                  expression: "formLists.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品详情", prop: "detail" } },
            [
              _c("newEditor", {
                attrs: { quillIndex: 1, toref: "Editorc1" },
                model: {
                  value: _vm.formLists.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.formLists, "detail", $$v)
                  },
                  expression: "formLists.detail",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }