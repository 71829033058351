<template>
  <div class="app-container">

    <!-- 搜索 -->
    <query-form :queryForm="queryForm" :queryData="queryData" @handleQuery="handleQuery" @handleReset="handleReset" />

    <div class="sys_search_line">
      <el-button type="primary" size="medium" icon="el-icon-plus" @click="getInfo(0)">新建</el-button>
    </div>

    <!-- 数据 -->
    <el-table stripe v-loading="loading" :data="pageData" element-loading-text="加载中..."
      :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }" @selection-change="handleSelectionChange">

      <!-- 空数据状态的插槽 -->
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>

      <!-- <el-table-column type="selection" width="55"></el-table-column> -->

      <el-table-column label="ID" width="100" fixed>
        <template slot-scope="scope">{{ scope.row.id }}</template>
      </el-table-column>

      <!-- <el-table-column label="封面图">
        <template slot-scope="scope">
          <el-image style="width: 70px; height: 70px" :src="scope.row.image_path"
            :preview-src-list="[scope.row.image_path]" fit="cover" />
        </template>
      </el-table-column>

      <el-table-column label="Banner图">
        <template slot-scope="scope">
          <el-image style="width: 70px; height: 70px" :src="scope.row.banner_path"
            :preview-src-list="[scope.row.banner_path]" fit="cover" />
        </template>
      </el-table-column> -->

      <el-table-column label="名称">
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link operation">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="getInfo(scope.row.id)"><span>编辑</span></el-dropdown-item>
              <el-dropdown-item @click.native="getRemove(scope.row.id)"><span>删除</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>

    </el-table>

    <pagination :total="pageSize.total" :page.sync="pageSize.current_page" :limt.sync="pageSize.per_page"
      @pagination="pagination" />

    <el-drawer :title="formData.id ? '编辑' : '新增'" :visible.sync="drawer" :direction="'rtl'" size="40%"
      :close-on-press-escape="false" :show-close="false" :wrapperClosable="false">
      <div class="drawer_box">
        <div style="padding: 0 20px">
          <el-form ref="ruleForm" :model="formData" :rules="rules" label-width="100px">
            <el-form-item label="分类名称" prop="name">
              <el-input v-model="formData.name" placeholder="请输入商品名称" />
            </el-form-item>
            <!-- <el-form-item label="分类图片">
              <upload-img :fileUrl="formData.image_path" imgStyle="square" @uploadImgPath="uploadImgPath" />
              <el-button type="danger" @click="deleteFile('img')" v-if="formData.image_path">删除图片</el-button>
            </el-form-item>

            <el-form-item label="Banner图片">
              <upload-img :fileUrl="formData.banner_path" @uploadImgPath="uploadBannerPath" />
              <el-button type="danger" @click="deleteFile('banner')" v-if="formData.banner_path">删除图片</el-button>
            </el-form-item> -->

          </el-form>
        </div>

        <div class="demo-drawer__footer">
          <el-button class="btn" size="small" @click="cancelForm">取 消</el-button>
          <el-button class="btn" type="primary" size="small" @click="drawerSubmit" :loading="drawerLoading">{{
            drawerLoading? '提交中': '保 存'
          }}</el-button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import {
  getGoodsCategoryList,
  getGoodsCategoryInfo,
  getGoodsCategorySave,
  getGoodsCategoryRemove,
} from '@/api/goods'

import UploadImg from '@/components/Upload/uploadImg.vue'
import Pagination from '@/components/Pagination/index.vue'

export default {
  name: 'Shoplist',

  components: {
    UploadImg,
    Pagination
  },

  data() {
    return {
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 10,
        search_id: '',
        search_name: '',
      },
      // 搜索框
      queryForm: [
        { type: "input", label: "ID", model: "search_id", placeholder: "请输入标题" },
        { type: "input", label: "标题", model: "search_name", placeholder: "请输入标题" },
      ],

      // 页面数据
      pageData: [],
      pageSize: {},
      removeData: [], // 批量删除

      // 页面状态
      selectAllStatus: true,
      loading: false,

      drawer: false,
      drawerLoading: false,
     
      formData: {
        id: 0,
        name: '',
        // image_path: '',
        // sort: 0,
      },
      // 表单校验规则
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        image_path: [{ required: true, message: '请选择图片', trigger: 'change' }],
      },
    }
  },

  created() {
    console.log(111, '111');
    this.init();
  },

  methods: {

    init() {
      this.getList();
    },

    getList() {
      this.loading = true;
      
      getGoodsCategoryList(this.queryData).then(res => {
        const { data, total, current_page, per_page } = res.result

        this.pageData = data;
        this.pageSize = {
          total,
          current_page,
          per_page
        }

        this.loading = false;
      })
    },

    // 查询按钮
    handleQuery(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 重置按钮
    handleReset(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 选择表格操作
    handleSelectionChange(val) {
      if (val.length) {
        this.selectAllStatus = false;
        val.map((item) => {
          this.removeData.push(item.id)
        })
        this.removeData = [...new Set(this.removeData)]
      } else {
        this.selectAllStatus = true;
      }
    },

    // 分页点击事件
    pagination(data) {
      this.queryData = { ...this.queryData, ...data };
      this.getList();
    },

    // 分类详情
    getInfo(id) {
      // 数据初始化
      this.formData = this.$options.data().formData;
      this.drawer = true

      if (id) {
        getGoodsCategoryInfo({
          id
        }).then(res => {
          this.formData = res.result
        })
      }
    },

    // 图片上传成功
    // uploadImgPath(path) {
    //   this.formData.image_path = path;
    //   this.$forceUpdate();
    // },

    // // 图片上传成功
    // uploadBannerPath(path) {
    //   this.formData.banner_path = path;
    //   this.$forceUpdate();
    // },

    // 删除图片
    deleteFile(type) {
      if (type == 'img') {
        this.formData.image_path = '';
      }
      if (type == 'banner') {
        this.formData.banner_path = '';
      }
      this.$message.success('删除成功!');
    },

    cancelForm() {
      this.drawer = false;
    },

    drawerSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.drawerLoading = true;
          // 数据处理

          getGoodsCategorySave(this.formData).then(res => {
            // 清空表单验证
            this.$refs.ruleForm.resetFields()
            this.drawer = false

            this.$messageNotify(res);
            this.getList();
          })

          setTimeout(() => {
            this.drawerLoading = false;
          }, 1000)
        }
      })
    },

    // 删除
    getRemove(id) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          getGoodsCategoryRemove(id).then(res => {
            if (res.code === 200) {
              this.$notify({
                title: '成功',
                message: '删除成功!',
                type: 'success',
                duration: 2000
              })
              this.getList(this.listForm)
            }
          })
        })
    },

  }
}
</script>

<style lang="scss" scoped>
.operation {
  color: #1890ff;
  cursor: pointer;
}

.discount {
  font-size: 6px;
}
</style>
