<template>
  <div class="app-container">
    <!-- 搜索 -->
    <query-form
      :queryForm="queryForm"
      :queryData="queryData"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    />

    <div class="sys_search_line">
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        @click="getInfo(0)"
        >新建商品</el-button
      >
      <!-- <el-button
        type="danger"
        :disabled="selectAllStatus"
        plain
        size="medium"
        @click="removeAll"
        >批量删除</el-button
      > -->
    </div>

    <el-table
      stripe
      v-loading="loading"
      :data="pageData"
      :default-sort="{ prop: 'date', order: 'descending' }"
      element-loading-text="加载中..."
      :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <!-- 空数据状态的插槽 -->
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>

      <el-table-column type="selection" width="55" fixed></el-table-column>

      <el-table-column label="ID" width="100" fixed>
        <template slot-scope="scope">{{ scope.row.id }}</template>
      </el-table-column>

      <el-table-column label="标题" :width="flexColumnWidth('title', pageData)">
        <template slot-scope="scope">
          <!-- <svg-icon
            icon-class="intop"
            v-if="scope.row.is_top"
            style="margin-right: 10px"
          />
          <el-tag
            v-if="scope.row.is_welfareGoods"
            type="primary"
            style="margin-right: 10px"
            >新人福利</el-tag
          >
          <el-tag
            v-if="scope.row.is_newGoodsOnSale"
            type="primary"
            style="margin-right: 10px"
            >新品首发</el-tag
          > -->
          <div>{{ scope.row.title }}</div>
        </template>
      </el-table-column>

      <el-table-column label="封面图" width="160">
        <template slot-scope="scope">
          <el-image
            style="width: 130px; height: 70px"
            :src="scope.row.goods_image"
            :preview-src-list="[scope.row.goods_image]"
            fit="cover"
          />
        </template>
      </el-table-column>

      <el-table-column label="分类" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.category_name">{{
            scope.row.category_name
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="价格" prop="price" sortable>
        <template slot-scope="scope">
          <span>{{ getGoodsPrice(scope.row.sku) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="库存" sortable>
        <template slot-scope="scope">{{
          $thousandth(scope.row.sku[0].quantity)
        }}</template>
      </el-table-column>

      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-switch
            class="el_switch_small"
            :value="scope.row.status"
            @change="switchChange(scope.row)"
            :active-value="1"
            :inactive-value="0"
            active-text="上架"
            inactive-text="下架"
          />
        </template>
      </el-table-column>

      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link operation">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="settingNewGoods(scope.row.id)">
                <span>设为新品首发</span>
              </el-dropdown-item> -->
              <el-dropdown-item class="dropdown" />
              <el-dropdown-item @click.native="getInfo(scope.row.id)">
                <span>编辑</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="getRemove(scope.row.id)">
                <span>删除</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="pageSize.total"
      :page.sync="pageSize.current_page"
      :limt.sync="pageSize.per_page"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import {
  getGoodsList,
  getGoodsRemove,
  getGoodsCategoryList,
  getGoodsRemoveAll,
  getGoodsStatus,
  reqNewGoods,
} from "@/api/goods";


import { flexColumnWidth } from "@/utils/table";

import Pagination from "@/components/Pagination/index.vue";

export default {
  name: "goodsList",
  components: {
    Pagination,
  },
  data() {
    return {
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 10,
        search_name: "",
        search_category_id: "",
      },
      // 搜索框
      queryForm: [
        
        {
          type: "input",
          label: "标题",
          model: "search_title",
          placeholder: "请输入标题",
        },
        {
          type: "select",
          label: "分类",
          model: "search_category_id",
          placeholder: "请选择分类",
          options: [],
        },
        // {
        //   type: "select",
        //   label: "状态",
        //   model: "search_status",
        //   placeholder: "请选择状态",
        //   options: [
        //     { id: 1, name: "上架" },
        //     { id: 0, name: "下架" },
        //   ],
        // },
      ],

      // 页面数据
      pageData: [],
      pageSize: {},
      categoryData: [],
      removeData: [], // 批量删除

      // 页面状态
      loading: false,
      selectAllStatus: true,
    };
  },
  computed: {
    getGoodsPrice() {
      return function (arr) {
        let min = this.$thousandth(
          Math.min.apply(
            Math,
            arr.map((item) => {
              return item.price;
            })
          ),
          1
        );
        let max = this.$thousandth(
          Math.max.apply(
            Math,
            arr.map((item) => {
              return item.price;
            })
          ),
          1
        );

        if (arr.length == 1 || min == max) {
          return "¥" + max;
        } else {
          return "¥" + min + " ~ " + "¥" + max;
        }
      };
    },
  },
  created() {
    this.getList();
  },
  methods: {
    flexColumnWidth,

    getList() {
      this.loading = true;

      // 商品列表
      getGoodsList(this.queryData).then((res) => {
        const { data, total, current_page, per_page } = res.result;

        this.pageData = data;
        this.pageSize = {
          total,
          current_page,
          per_page,
        };

        this.loading = false;
      });

      // 全部商品分类
      getGoodsCategoryList({
        page: 1,
        page_size: 100,
      }).then((res) => {
        const data = res.result.data;
        const categoryList = data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
        this.queryForm[1].options = categoryList;
      });
    },

    // 查询按钮
    handleQuery(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 重置按钮
    handleReset(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 选择表格操作
    handleSelectionChange(val) {
      if (val.length) {
        this.selectAllStatus = false;
        val.map((item) => {
          this.removeData.push(item.id);
        });
        this.removeData = [...new Set(this.removeData)];
      } else {
        this.selectAllStatus = true;
      }
    },

    // 分页点击事件
    pagination(data) {
      this.queryData = { ...this.queryData, ...data };
      this.getList();
    },

    // 表格 - 单列排序
    handleSortChange(column) {
      // column是个形参，具体查看element-ui文档
      const { prop, order } = column;

      this.listForm.search_order_field.name = prop;
      this.listForm.search_order_field.order = order;

      const config = Object.assign(this.listForm);
      this.getList();
    },

    // 上 / 下架状态
    switchChange(item) {
      this.$messageConfirm(`确认${item.status == 1 ? "下架" : "上架"}`, () => {
        let httpData = {
          id: item.id,
        };
        getGoodsStatus(httpData).then((res) => {
          this.$messageNotify(res);
          item.status == 0 ? (item.status = 1) : (item.status = 0);
        });
      });
    },

    // 将商品设为新品
    // settingNewGoods(id) {
    //   reqNewGoods({ id }).then((res) => {
    //     this.$messageNotify(res);
    //     this.getList();
    //   });
    // },

    // 新增 / 编辑
    getInfo(id) {
      this.$router.push({
        path: "goodsInfo",
        query: {
          id,
        },
      });
    },

    // 删除
    getRemove(id) {
      this.$messageConfirm(`确认删除?`, () => {
        getGoodsRemove({
          id,
        }).then((res) => {
          this.$messageNotify(res);
          this.getList();
        });
      });
    },

    // 批量删除
    // removeAll() {
    //   if (this.selectAllStatus) {
    //     return this.$message.warning("请先选中数据再操作!");
    //   }
    //   this.$messageConfirm(`确认批量删除?`, () => {
    //     getGoodsRemoveAll({
    //       ids: this.removeData,
    //     }).then((res) => {
    //       this.$messageNotify(res);
    //       this.getList();
    //     });
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.operation {
  color: #1890ff;
  cursor: pointer;
}

.dropdown {
  height: 1px;
  margin: 4px 0;
  background-color: #e8e8e8;
}
</style>
