var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sticky", { attrs: { "sticky-top": 84, "z-index": 5 } }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: _vm.content,
              placement: "bottom",
            },
          },
          [
            _c("el-page-header", {
              attrs: { content: _vm.content },
              on: { back: _vm.goBack },
            }),
          ],
          1
        ),
        _vm.showSaveBtn == true
          ? _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.saveClick },
              },
              [_vm._v(_vm._s(_vm.btntext))]
            )
          : _vm._e(),
        _vm._t("headerBtnTwo"),
      ],
      2
    ),
    _vm.showTabs == true
      ? _c(
          "div",
          { staticClass: "tabClass" },
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: { "tab-click": _vm.tabClick },
                model: {
                  value: _vm.tabActiveName,
                  callback: function ($$v) {
                    _vm.tabActiveName = $$v
                  },
                  expression: "tabActiveName",
                },
              },
              _vm._l(_vm.tabData, function (item) {
                return _c("el-tab-pane", {
                  key: item.id,
                  attrs: { label: item.name, name: item.name },
                })
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }