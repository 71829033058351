var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "orderInfo",
    },
    [
      _c(
        "page-header",
        {
          attrs: { content: "订单详情", "back-path": "financeOrderList" },
          on: { saveClick: _vm.onSubmit },
        },
        [
          _c(
            "template",
            { slot: "headerBtnTwo" },
            [
              !_vm.tempStatus & (_vm.id != 0)
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.completedOrder()
                        },
                      },
                    },
                    [_vm._v(" 完成订单 ")]
                  )
                : _vm._e(),
              _vm.tempStatus & !_vm.form.commission_status & (_vm.id != 0)
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success", plain: "", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.financeChangeStatus()
                        },
                      },
                    },
                    [_vm._v(" 订单结算 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "page-body",
            { attrs: { navBg: _vm.navBg } },
            [
              _c("template", { slot: "title" }, [_vm._v("订单信息")]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "90px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "left", attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "行政区:" } },
                            [
                              _c("el-cascader", {
                                ref: "regionRef",
                                attrs: {
                                  placeholder: "请选择行政区",
                                  options: _vm.xzqList,
                                  props: _vm.defaultProps,
                                  filterable: "",
                                  clearable: "",
                                },
                                on: { change: _vm.xzqChange },
                                model: {
                                  value: _vm.address_code,
                                  callback: function ($$v) {
                                    _vm.address_code = $$v
                                  },
                                  expression: "address_code",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "门店:", prop: "user_id" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: true,
                                    filterable: "",
                                    disabled: _vm.form.commission_status == 1,
                                  },
                                  on: { change: _vm.userChange },
                                  model: {
                                    value: _vm.form.user_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "user_id", $$v)
                                    },
                                    expression: "form.user_id",
                                  },
                                },
                                _vm._l(_vm.userList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: _vm.getAgentName(item),
                                      value: item.user_id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "分润比例:", prop: "ratio" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    disabled: _vm.form.commission_status == 1,
                                  },
                                  on: { blur: _vm.ratioChange },
                                  model: {
                                    value: _vm.form.ratio,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ratio", $$v)
                                    },
                                    expression: "form.ratio",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("%"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.form.commission_status == 1,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.drawer = true
                                },
                              },
                            },
                            [_vm._v("选择商品")]
                          ),
                          _c(
                            "el-table",
                            {
                              staticClass: "tableClass",
                              attrs: {
                                stripe: "",
                                data: _vm.chooseGoods,
                                "max-height": 300,
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "empty" },
                                [
                                  _c("el-empty", {
                                    attrs: {
                                      "image-size": 100,
                                      description: "暂无数据",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("el-table-column", {
                                attrs: {
                                  label: "ID",
                                  prop: "table_id",
                                  width: "80",
                                  fixed: "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "名称",
                                  prop: "table_title",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "封面图", width: "160" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "60px",
                                          },
                                          attrs: {
                                            src: scope.row.table_image,
                                            fit: "cover",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单价",
                                  prop: "price",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        !_vm.showEdit ||
                                        scope.row.table_id !=
                                          _vm.tempGoods.table_id
                                          ? _c("span", [
                                              _vm._v(_vm._s(scope.row.price)),
                                            ])
                                          : _c("el-input", {
                                              model: {
                                                value: scope.row.price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "price",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.price",
                                              },
                                            }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "数量",
                                  prop: "product_num",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        !_vm.showEdit ||
                                        scope.row.table_id !=
                                          _vm.tempGoods.table_id
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.product_num)
                                              ),
                                            ])
                                          : _c("el-input", {
                                              model: {
                                                value: scope.row.product_num,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "product_num",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.product_num",
                                              },
                                            }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "120",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !_vm.showEdit ||
                                        row.table_id != _vm.tempGoods.table_id
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  disabled:
                                                    _vm.form
                                                      .commission_status == 1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editGoods(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            )
                                          : _vm._e(),
                                        !_vm.showEdit ||
                                        row.table_id != _vm.tempGoods.table_id
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: { color: "red" },
                                                attrs: {
                                                  type: "text",
                                                  disabled:
                                                    _vm.form
                                                      .commission_status == 1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delGoods(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      disabled:
                                                        _vm.form
                                                          .commission_status ==
                                                        1,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancleEdit(
                                                          row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("取消")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      disabled:
                                                        _vm.form
                                                          .commission_status ==
                                                        1,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.sureEdit(row)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("确认")]
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "totalPrice" }, [
                            _vm._v(_vm._s("总金额：" + _vm.totalPrice + "元")),
                          ]),
                        ],
                        1
                      ),
                      _c("el-divider", {
                        staticClass: "divider",
                        attrs: { direction: "vertical" },
                      }),
                      _c(
                        "el-col",
                        { staticClass: "right", attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "利润:", prop: "profit" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: _vm.form.commission_status == 1,
                                  },
                                  on: { blur: _vm.profitChange },
                                  model: {
                                    value: _vm.form.profit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "profit", $$v)
                                    },
                                    expression: "form.profit",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-timeline",
                                { attrs: { reverse: true } },
                                _vm._l(_vm.userTree, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      item
                                        ? _c(
                                            "el-timeline-item",
                                            {
                                              attrs: {
                                                reverse: "true",
                                                icon: "el-icon-user",
                                                type: "primary",
                                                size: "large",
                                                placement: "top",
                                                timestamp:
                                                  _vm.getUserName(item),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-card",
                                                { staticClass: "treeCard" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " 等级身份:" +
                                                        _vm._s(
                                                          item.user_info
                                                            ? item.user_info
                                                                .system_agent_name
                                                            : item.system_agent_name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  item.price
                                                    ? _c("p", [
                                                        _vm._v(
                                                          " 分润:" +
                                                            _vm._s(
                                                              _vm.$thousandth(
                                                                item.price,
                                                                1
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "选择商品",
            visible: _vm.drawer,
            direction: "rtl",
            size: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer_box" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 20px" } },
              [
                _c(
                  "el-table",
                  {
                    ref: "mulGoods",
                    staticClass: "tableClass",
                    attrs: { stripe: "", data: _vm.goodsList },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c(
                      "template",
                      { slot: "empty" },
                      [
                        _c("el-empty", {
                          attrs: { "image-size": 100, description: "暂无数据" },
                        }),
                      ],
                      1
                    ),
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "ID",
                        prop: "id",
                        width: "80",
                        fixed: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "封面图", width: "160" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-image", {
                                staticStyle: { width: "100px", height: "60px" },
                                attrs: {
                                  src: scope.row.goods_image,
                                  fit: "cover",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "名称",
                        prop: "title",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "单价", "show-overflow-tooltip": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$thousandth(scope.row.sku[0].price, 1)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "demo-drawer__footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { size: "small" },
                    on: { click: _vm.cancleDrawer },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.sureGoods },
                  },
                  [_vm._v("确 认")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }