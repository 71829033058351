/**
 * Created by PanJiaChen on 16/11/18.
 */
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, pattern, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }

  let format;
  if (pattern == "oblique") {
    format = cFormat || "{y}/{m}/{d} {h}:{i}:{s}";
  } else {
    format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  }

  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split("?")[1]).replace(/\+/g, " ");
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split("&");
  searchArr.forEach((v) => {
    const index = v.indexOf("=");
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + "";
  const randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

/**
 * 数字千分位转换
 */
export function thousandth(num, type) {
  if (!num) {
    num = 0;
  }
  if (type) num = this.$sumJs.accDiv(num, 100);
  let str = num.toString(); // 数字转字符串
  let str2 = null;
  // 如果带小数点
  if (str.indexOf(".") !== -1) {
    // 带小数点只需要处理小数点左边的
    const strArr = str.split("."); // 根据小数点切割字符串
    str = strArr[0]; // 小数点左边
    str2 = strArr[1]; // 小数点右边
    //如12345.678  str=12345，str2=678
  }
  let result = ""; // 结果
  while (str.length > 3) {
    // while循环 字符串长度大于3就得添加千分位
    // 切割法 ，从后往前切割字符串 ⬇️
    result = "," + str.slice(str.length - 3, str.length) + result;
    // 切割str最后三位，用逗号拼接 比如12345 切割为 ,345
    // 用result接收，并拼接上一次循环得到的result
    str = str.slice(0, str.length - 3); // str字符串剥离上面切割的后三位，比如 12345 剥离成 12
  }

  if (str.length <= 3 && str.length > 0) {
    // 长度小于等于3 且长度大于0，直接拼接到result
    // 为什么可以等于3 因为上面result 拼接时候在前面带上了‘,’
    // 相当于123456 上一步处理完之后 result=',456' str='123'
    result = str + result;
  }
  // 最后判断是否带小数点（str2是小数点右边的数字）
  // 如果带了小数点就拼接小数点右边的str2 ⬇️
  str2 ? (result = result + "." + str2) : "";
  return result;
}

export function formatBankCard(val, symbol = "，") {
  if (val && val.toString().length > 4) {
    return val.toString().replace(/(.{4})/g, `$1${symbol}`);
  }
  return val
}

// 秒转换为时分秒
export function formatSecToDate(sec) {
  if (!sec) {
    return "-";
  }
  let min = Math.floor(sec % 3600); // 分钟
  let h =
    Math.floor(sec / 3600) < 10
      ? "0" + Math.floor(sec / 3600)
      : Math.floor(sec / 3600); // 时
  let m =
    Math.floor(min / 60) < 10
      ? "0" + Math.floor(min / 60)
      : Math.floor(min / 60); // 分
  let s = sec % 60 < 10 ? "0" + (sec % 60) : sec % 60; // 秒
  return h + ":" + m + ":" + s;
}

/*  
  delSameObjValue 数组对象相同值相加去重
  arr 需要处理的数组
  resultNum 最终计算结果的键名
  keyName 用于计算判断的键名 
  keyValue 用于计算结果的键名 --> 对应的键值为number类型
*/
export function delSameObjValue(arr, resultNum, keyName, keyValue) {
  const warp = new Map();
  arr.forEach((i) => {
    let str = keyName.map((v) => i[v]).join("_");
    i[resultNum] = keyValue.reduce((p, c) => (p += i[c]), 0);
    warp.has(str)
      ? (warp.get(str)[resultNum] += i[resultNum])
      : warp.set(str, i);
  });
  return Array.from(warp).map(([, v]) => v);
}

// 浮点小数相加 & 相减
export function floatNumAddSub(num1, num2, type = "add") {
  const num1Digits = (num1.toString().split(".")[1] || "").length;
  const num2Digits = (num2.toString().split(".")[1] || "").length;
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));

  if (type == "add")
    return (numRide(num1, baseNum) + numRide(num2, baseNum)) / baseNum;
  if (type == "sub")
    return (numRide(num1, baseNum) - numRide(num2, baseNum)) / baseNum;
}

// 浮点小数相乘
export function numRide(num1, num2) {
  const num1String = num1.toString();
  const num2String = num2.toString();
  const num1Digits = (num1String.split(".")[1] || "").length;
  const num2Digits = (num2String.split(".")[1] || "").length;
  const baseNum = Math.pow(10, num1Digits + num2Digits);
  return (
    (Number(num1String.replace(".", "")) *
      Number(num2String.replace(".", ""))) /
    baseNum
  );
}
