import request from '@/utils/request'

// 订单 - 列表
export function getOrderList(data) {
  return request({
    url: '/api/order/list',
    method: 'post',
    data
  })
}

// 订单 - 详情
export function getOrderInfo(data) {
  return request({
    url: '/api/order/info',
    method: 'post',
    data
  })
}

// 订单 - 新增/编辑
export function reqOrderSave(data) {
  return request({
    url: '/api/order/save',
    method: 'post',
    data
  })
}
// 订单 - 删除
export function reqOrderRemove(data) {
  return request({
    url: '/api/order/remove',
    method: 'post',
    data
  })
}

// 订单结算
export function reqOrderCommissionStatus(data) {
  return request({
    url: '/api/order/change_commission_status',
    method: 'post',
    data
  })
}
