<template>
  <div class="container">
    <page-header :content="id ? '编辑' : '新增'" back-path="userLabelList" v-loading.fullscreen.lock="loading"
      @saveClick="onSubmit" />

    <el-form :model="form" ref="ruleForm" :rules="rules" label-width="180px" style="width: 80%;margin-top: 24px;">
      <el-form-item label="标签名称:" prop="name">
        <el-input data-pirceChange="1" v-model="form.name" placeholder="请输入标签名称" />
      </el-form-item>
      <el-form-item label="标签备注:" prop="remark">
        <el-input type="textarea"  v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getLabelInfo, getLabelSave } from '@/api/member'
import PageHeader from '@/components/PageHeader/index'

export default {
  components: {
    PageHeader
  },

  data() {
    return {
      loading: false,
      form: {
        id: 0,
        name: null,
        remark: null,
      },
      // 表单校验规则
      rules: {
        name: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入标签备注', trigger: 'blur' }],
      },
    }
  },

  created() {
    let id = this.$route.query.id
    if (id) {
      this.getInfo(id)
    }
  },

  watch: {

  },

  methods: {
    // 获取详情
    getInfo(id) {
      getLabelInfo({
        id
      }).then(res => {
        this.form = res.result
      })
    },

    // 新增 || 编辑
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 新增 || 编辑
          getLabelSave(this.form).then(res => {
            this.$messageNotify(res);
            this.$router.push({ path: 'userLabelList' })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  margin: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

::v-deep .el-textarea__inner {
  height: 75px;
  overflow-y: auto;
}
</style>
