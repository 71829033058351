<template>
  <el-row class="data">
    <el-col :span="24">
      <el-button size="small" style="margin: 10px" @click="getInfo()"
        >新建</el-button
      >
      <el-table
        v-loading="loading"
        stripe
        :data="listData"
        element-loading-text="加载中..."
        :default-sort="{ prop: 'date', order: 'descending' }"
        :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
      >
        <!-- 空数据状态的插槽 -->
        <template slot="empty">
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>

        <el-table-column label="ID" prop="id" width="60" fixed />

        <el-table-column label="姓名" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>{{ row.real_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>{{ getGender(row.gender) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上级" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="editAscription(row)"
              >{{ row.parent.real_name }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="行政区" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span>{{ row.province + "-" + row.city + "-" + row.area }}</span>
          </template>
        </el-table-column>
        <el-table-column label="等级" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-tag @click="editAgent(row)">{{ row.system_agent_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="{ row }">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link operation">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="getInfo(row)">
                  <span>编辑</span>
                </el-dropdown-item>
                <el-dropdown-item @click.native="getRemove(row.id)"
                  ><span>删除</span></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增编辑 -->
      <el-drawer
        :title="formData.id == 0 ? '新增' : '编辑'"
        :visible.sync="drawer"
        :direction="'rtl'"
        size="60%"
      >
        <div class="drawer_box">
          <div style="padding: 0 20px">
            <el-form
              ref="drawerForm"
              :model="formData"
              :rules="drawerFormRules"
              label-width="100px"
            >
              <el-form-item label="用户名称:" prop="base.real_name">
                <el-input
                  v-model="formData.base.real_name"
                  placeholder="请输入用户名称"
                />
              </el-form-item>
              <el-form-item label="用户性别:" prop="base.gender">
                <el-select v-model="formData.base.gender" :clearable="true">
                  <el-option
                    v-for="item in genderList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="联系方式:" prop="phone">
                <el-input
                  v-model="formData.phone"
                  placeholder="请输入联系方式"
                  @change="$forceUpdate()"
                />
              </el-form-item>
              <el-form-item label="上级ID:" prop="parent_id">
                <!-- <el-input
                  v-model="formData.parent_id"
                  placeholder="请输入父级ID"
                @change="$forceUpdate()"/> -->
                <el-select
                  v-model="formData.parent_id"
                  :clearable="true"
                  style="width: 50%"
                  filterable
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="getAgentName(item)"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="行政区:" prop="base.address_code">
                <el-cascader
                  ref="regionRef"
                  v-model="formData.base.address_code"
                  placeholder="请选择行政区"
                  :options="xzqList"
                  :props="defaultProps"
                  filterable
                  @change="xzqChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="等级身份:" prop="system_agent_id">
                <el-select
                  v-model="formData.system_agent_id"
                  :clearable="true"
                  @change="$forceUpdate()"
                >
                  <el-option
                    v-for="item in agentData"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>

          <div class="demo-drawer__footer">
            <el-button class="btn" size="small" @click="drawer = false"
              >取 消</el-button
            >
            <el-button
              class="btn"
              type="primary"
              size="small"
              @click="drawerSubmit"
              :loading="drawerLoading"
              >{{ drawerLoading ? "提交中" : "保 存" }}</el-button
            >
          </div>
        </div>
      </el-drawer>
      <!-- 代理等级 -->
      <el-dialog
        title="编辑代理等级"
        :visible.sync="agentDialog"
        :close-on-click-modal="false"
      >
        <el-form ref="ruleForm" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="身份">
            <el-select
              v-model="form.system_agent_id"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in agentData"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="修改理由" prop="remark">
          <el-input type="textarea" :rows="6" v-model="form.remark" placeholder="请输入修改理由" />
        </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="agentDialog = false">取 消</el-button>
          <el-button
            type="primary"
            @click="submitAgent"
            :loading="confirmLoading"
            >{{ confirmLoading ? "保存中" : "确 定" }}</el-button
          >
        </div>
      </el-dialog>

      <!-- 弹窗选择归属人 -->
      <el-dialog
        title="编辑归属人"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form :model="form">
          <el-form-item label="上级ID">
            <!-- <el-input v-model="form.parent_id" autocomplete="off"></el-input> -->
            <el-select
              v-model="form.parent_id"
              :clearable="true"
              style="width: 50%"
              filterable
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="getAgentName(item)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="修改理由" prop="remark">
            <el-input
              type="textarea"
              :rows="6"
              v-model="form.remark"
              placeholder="请输入修改理由"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="submitAscription"
            :loading="confirmLoading"
            >{{ confirmLoading ? "保存中" : "确 定" }}</el-button
          >
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
import {
  getLabelList,
  reqUserChangeAgent,
  reqUserRelation,
  reqUserRemove,
  subSave,
  getUserList,
} from "@/api/member";

import { flexColumnWidth } from "@/utils/table";

import Pagination from "@/components/Pagination/index.vue";
import axios from "axios";
import { getArea } from "@/utils/addrFilter";
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    Pagination,
  },

  data() {
    return {
      loading: false,
      listData: [],
      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 100,
        search_sub: 1,
        search_id: "",
        search_name: "",
        search_real_name: "",
        search_phone: "",
      },
      drawer: false,
      drawerLoading: false,
      formData: {
        id: 0,
        base: {},
      },
      drawerFormRules: {
        "base.real_name": [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        "base.gender": [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],

        parent_id: [
          { required: true, message: "请输入上级ID", trigger: "blur" },
        ],
        "base.address_code": [
          { required: true, message: "请选择行政区", trigger: "change" },
        ],
        system_agent_id: [
          { required: true, message: "请选择等级身份", trigger: "change" },
        ],
      },
      genderList: [
        { id: 0, title: "未知" },
        { id: 1, title: "男" },
        { id: 2, title: "女" },
      ],
      //行政区下拉选项
      xzqList: [],
      defaultProps: {
        label: "value",
        value: "code",
        children: "children",
      },
      // 代理数据
      agentData: [],

      form: {
        id: 0,
        p_id: 0,
        remark: null,
        real_name: null,
        system_agent_id: null,
      },
      rules: {},
      // 上级
      dialogFormVisible: false,

      // 代理
      agentDialog: false,
      //在编辑的子身份
      tempUser: {},
      confirmLoading: false,

      //用户列表数据
      userList: [],
    };
  },

  computed: {
    getGender() {
      return function (id) {
        let ele = this.genderList.find((item) => item.id == id);
        let gender = "";
        if (ele) {
          gender = ele.title;
        }
        return gender;
      };
    },
    getAgentName() {
      return function (item) {
        let name = "-";
        if (item.base) {
          name =
            item.base.real_name +
            "-" +
            item.base.province +
            "-" +
            item.base.city +
            "-" +
            item.base.area +
            "-" +
            item.base.system_agent_name;
        }
        return name;
      };
    },
  },
  mounted() {
    this.getAgentList();
    this.getXzqList();
    this.listData = this.user.children;
  },
  methods: {
    flexColumnWidth,
    getAgentList() {
      this.queryData = this.$options.data().queryData;
      getLabelList(this.queryData).then((res) => {
        const { data, total, current_page, per_page } = res.result;
        this.agentData = data;
      });
    },
    getXzqList() {
      const url = "/addr.json";
      axios
        .get(url)
        .then((response) => {
          this.xzqList = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    // 获取全部用户列表数据
    getUserList() {
      let searchData = JSON.parse(JSON.stringify(this.queryData));
      searchData.search_sub = 1;
      searchData.page_size = 100;
      getUserList(searchData).then((resp) => {
        this.userList = resp.result.data;
        this.userList.unshift({
          id: 0,
        });
      });
    },
    // 新增子身份
    getInfo(info) {
      this.formData = this.$options.data().formData;
      if (info) {
        this.formData.base = info;
        this.$set(this.formData, "system_agent_id", info.system_agent_id);
        this.$set(this.formData, "id", info.id);
        this.$set(this.formData, "phone", info.phone);
        this.$set(this.formData, "parent_id", info.parent_id);
      }
      this.formData.bind_user_id = this.user.id;
      this.getUserList();
      this.drawer = true;
    },
    //选择行政区
    xzqChange(data) {
      if (data[2]) {
        let xzq = getArea(data[2]);
        let district = xzq.split("-");
        this.formData.base.province = district[0];
        this.formData.base.city = district[1];
        this.formData.base.area = district[2];
      }
      // if (data[0]) {
      //   this.formData.base.province = getArea(data[0]);
      // }
      // if (data[1]) {
      //   this.formData.base.city = getArea(data[1]);
      // }
      // if (data[2]) {
      //   this.formData.base.area = getArea(data[2]);
      // }
      this.$refs.regionRef.dropDownVisible = false;
    },

    drawerSubmit() {
      this.$refs.drawerForm.validate((valid) => {
        if (valid) {
          debugger;
          this.drawerLoading = true;
          // 数据处理

          subSave(this.formData).then((res) => {
            // 清空表单验证
            this.$refs.drawerForm.resetFields();
            this.drawer = false;
            this.$messageNotify(res);
            this.$emit("editUserInfo");
          });

          setTimeout(() => {
            this.drawerLoading = false;
          }, 1000);
        }
      });
    },

    // 编辑身份
    editAgent(item) {
      this.tempUser = item;
      this.form.system_agent_id = item.system_agent_id;
      this.agentDialog = true;
    },
    submitAgent() {
      this.confirmLoading = true;
      reqUserChangeAgent({
        user_id: this.tempUser.id,
        system_agent_id: this.form.system_agent_id,
      }).then((res) => {
        setTimeout(() => {
          this.confirmLoading = false;
          this.$messageNotify();
          this.agentDialog = false;
          this.$emit("editUserInfo");
        }, 500);
      });
    },

    // 编辑归属人
    async editAscription(item) {
      this.tempUser = item;
      this.form = JSON.parse(JSON.stringify(this.$options.data().form));
      this.form.parent_id = this.$set(this.form, "parent_id", item.parent_id);
      this.getUserList();
      this.dialogFormVisible = true;
    },

    submitAscription() {
      if (!this.form.remark || !this.form.parent_id) {
        this.$message.warning("请填写修改信息");
        return;
      }
      this.confirmLoading = true;
      reqUserRelation({
        parent_id: this.form.parent_id,
        remark: this.form.remark,
        user_id: this.tempUser.id,
      }).then((res) => {
        setTimeout(() => {
          this.confirmLoading = false;
          this.$messageNotify();
          this.dialogFormVisible = false;
          this.$emit("editUserInfo");
        }, 500);
      });
    },

    // 删除
    getRemove(id) {
      this.$messageConfirm(`确认删除?`, () => {
        reqUserRemove({
          id,
        }).then((res) => {
          this.$messageNotify(res);
          this.$emit("editUserInfo");
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tc {
  color: #1890ff;
}
</style>
