<template>
  <div class="default">
    <!-- 单张 -->
    <el-upload
      action="https://up-z2.qiniup.com/"
      :data="dataObj"
      class="avatar-uploader-video"
      :show-file-list="false"
      :before-upload="beforeVideoUpload"
      :on-success="handleVideoSuccess"
      :on-progress="handleVideoProgress"
      v-if="!fileUrl"
    >
      <el-progress v-if="progressFlag" type="circle" :percentage="loadProgress"></el-progress>
      
      <div class="center">
        <i size="50" class="el-icon-upload2 avatar-uploader-icon" />
        <div style="color: #999;">上传视频</div>
      </div>
    </el-upload>

    <div v-if="fileUrl" id="video-player" class="video-player"></div>
 
  </div>
</template>

<script>
import { getToken } from "@/api/qiniu";

import 'xgplayer';
import XgPlayer from 'xgplayer'
import HlsPlayer from 'xgplayer-hls.js';
import "xgplayer/dist/index.min.css"

export default {
  name: "uploadVideo",
  props: {
    type: {
      type: Object,
      default: () => ({
        object: 0,
        type: 2,
        extension: ''
      })
    },
    fileUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dataObj: {
        token: "",
        key: ""
      },
      realPath: "",
      fileName: '', // 上传文件的名字

      // 进度条
      progressFlag: false,
      loadProgress: 0,

      player: null,
    };
  },
  computed: {
    filePath() {
      return this.fileUrl;
    }
  },
  watch: {
    fileUrl(val) {
      if (val) {
        setTimeout(() => {
          this.initPlayer();
        }, 500)
      }
      else {
        this.player = null;
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initPlayer();
    })
  },
  methods: {
    initPlayer() {
      if (!this.fileUrl) return;

      let excus = this.fileUrl.split(".");
      excus = excus[excus.length - 1];
      console.log(excus, 'excus');

      if (excus == "m3u8") {
        this.player = new HlsPlayer({
          id: "video-player",
          useHls: true,
          url: this.fileUrl,
          playsinline: true,
          autoplay: true,
          height: 210,
          width: 375,
          playbackRate: [],
        });
      }
      else {
        this.player = new XgPlayer({
          id: "video-player",
          url: this.fileUrl,
          playsinline: true,
          autoplay: true,
          height: 210,
          width: 375,
          playbackRate: [],
        })
      }
    },

    emitUrlPath(val, fileName) {
      this.progressFlag = false;
      this.$message.success("上传成功!");
      this.$emit("uploadVideoPath", val, fileName);
    },

    handleVideoSuccess() {
      this.emitUrlPath(this.realPath, this.fileName);
    },

    // 文件上传时的钩子
    handleVideoProgress(event) {
      this.progressFlag = true;
      this.loadProgress = parseInt(event.percent);
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
      }
    },

    // 文件上传前的钩子
    beforeVideoUpload(file) {
      if ([ "video/mp4", "video/ogg", "video/flv", "video/avi", "video/wmv", "video/rmvb" ].indexOf(file.type) == -1) {
        this.$message.error(
          "上传视频只能是 mp4、ogg、flv、avi、wmv、rmvb 格式!"
        );
        return false;
      }

      let config = this.type;
      let suffix = file.type.split('/')[1]; // 获取后缀
      config.extension = suffix;
      this.fileName = file.name;

      const _self = this;
      return new Promise((resolve, reject) => {
        getToken(config)
          .then(response => {
            _self._data.dataObj.token = response.result.token;
            _self._data.dataObj.key = response.result.folderPath;
            _self._data.realPath = response.result.realPath;
            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },
  },
  destroyed() {
    this.player.destroy(true);
  },
};
</script>

<style>
.default {
  width: 375px;
  height: 210px;
}
.avatar-uploader-video .el-upload {
  width: 375px;
  height: 210px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.center {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.avatar-uploader-icon {
  font-size: 32px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.el-progress--circle,
.el-progress--dashboard {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_title {
  margin: -10px 0 10px;
  color: #a4abb4;
}
</style>
