var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", staticStyle: { "min-height": "600px" } },
    [
      _c("page-header", {
        attrs: {
          content: _vm.id > 0 ? "编辑商品" : "新增商品",
          "back-path": _vm.backPath,
        },
        on: { saveClick: _vm.onSubmit },
      }),
      _c("basics-setting", {
        ref: "basics",
        attrs: { formList: _vm.formList, type: _vm.id == 0 ? 2 : 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }