var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", { staticClass: "card-box", attrs: { shadow: "always" } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("span", [_vm._v("柱状图")]),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", padding: "3px 0" },
                attrs: { type: "text" },
              },
              [_vm._v("操作按钮")]
            ),
          ],
          1
        ),
        _c("div", [_c("BarChart", { attrs: { height: "300px" } })], 1),
      ]),
      _c("el-card", { staticClass: "card-box", attrs: { shadow: "always" } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("span", [_vm._v("环形图 - 1")]),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", padding: "3px 0" },
                attrs: { type: "text" },
              },
              [_vm._v("操作按钮")]
            ),
          ],
          1
        ),
        _c("div", [_c("PieChart", { attrs: { height: "300px" } })], 1),
      ]),
      _c("el-card", { staticClass: "card-box", attrs: { shadow: "always" } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("span", [_vm._v("环形图 - 2")]),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", padding: "3px 0" },
                attrs: { type: "text" },
              },
              [_vm._v("操作按钮")]
            ),
          ],
          1
        ),
        _c("div", [_c("PieTitleChart", { attrs: { height: "300px" } })], 1),
      ]),
      _c("el-card", { staticClass: "card-box", attrs: { shadow: "always" } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("span", [_vm._v("排行榜（表格）")]),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", padding: "3px 0" },
                attrs: { type: "text" },
              },
              [_vm._v("操作按钮")]
            ),
          ],
          1
        ),
        _c("div"),
      ]),
      _c("el-card", { staticClass: "card-box", attrs: { shadow: "always" } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("span", [_vm._v("数量统计 - 名片样式")]),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", padding: "3px 0" },
                attrs: { type: "text" },
              },
              [_vm._v("操作按钮")]
            ),
          ],
          1
        ),
        _c("div"),
      ]),
      _c("el-card", { staticClass: "card-box", attrs: { shadow: "always" } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c("span", [_vm._v("折线图")]),
            _c(
              "el-button",
              {
                staticStyle: { float: "right", padding: "3px 0" },
                attrs: { type: "text" },
              },
              [_vm._v("操作按钮")]
            ),
          ],
          1
        ),
        _c("div"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }