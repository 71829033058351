import request from '@/utils/request'

// 商品分类
export function getAllGoodsCategory(data) {
  return request({
    url: 'admin/goods_category/all',
    method: 'post',
    data
  })
}

// 账号管理 角色
export function getRoleAll() {
  return request({
    url: 'api/Role/all',
    method: 'post'
  })
}

// 管理员个人信息
export function reqAdministratorsInfo(data) {
  return request({
    url: 'admin/adminInfo/info',
    method: 'post',
    data
  })
}

// 管理员修改登录密码
export function reqAdministratorsPassword(data) {
  return request({
    url: 'admin/adminInfo/resetPassword',
    method: 'post',
    data
  })
}

// 管理员修改个人信息
export function reqAdministratorsSave(data) {
  return request({
    url: 'admin/adminInfo/save',
    method: 'post',
    data
  })
}
