var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodity" },
    [
      _c("div", { staticClass: "commodity_header" }, [
        _c(
          "div",
          { staticClass: "commodity_header_left" },
          [
            _c("div", [_c("span", [_vm._t("title")], 2), _vm._t("desc")], 2),
            _vm.switchStatus
              ? _c("el-switch", {
                  staticClass: "el_switch_small",
                  attrs: {
                    value: _vm.switchValue,
                    "inactive-value": _vm.inactiveValue,
                    "active-value": _vm.activeValue,
                    "active-text": "开启",
                    "inactive-text": "关闭",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onClick($event, "switch")
                    },
                  },
                })
              : _vm._e(),
            _vm.buttonStatus
              ? _c(
                  "div",
                  { staticClass: "btns" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onClick($event, "click")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.btnTitle))]
                    ),
                    _vm._t("btnTwo"),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.navState
          ? _c("div", { staticClass: "commodity_content" }, [
              _c(
                "div",
                {
                  staticClass: "commodity_list",
                  style: {
                    background: _vm.navBg ? "#f7f7f7" : "#fff",
                    border: _vm.navBg ? "1px solid #f7f7f7" : "0",
                  },
                },
                [_vm._t("default")],
                2
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }