<template>
  <sticky :sticky-top="84" :z-index="5">
    <div class="header">
      <el-tooltip
        class="item"
        effect="dark"
        :content="content"
        placement="bottom"
      >
        <el-page-header :content="content" @back="goBack" />
      </el-tooltip>

      <el-button
        type="primary"
        size="small"
        @click="saveClick"
        v-if="showSaveBtn == true"
        >{{ btntext }}</el-button
      >
      <slot name="headerBtnTwo" />
    </div>
    <div class="tabClass" v-if="showTabs == true">
      <el-tabs
        v-model="tabActiveName"
        type="card"
        @tab-click="tabClick"
      >
        <el-tab-pane
          v-for="item in tabData"
          :key="item.id"
          :label="item.name"
          :name="item.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div>

  </sticky>
</template>

<script>
import Sticky from "@/components/Sticky";

export default {
  name: "PageHeader",

  components: { Sticky },

  props: {
    // 内容
    content: {
      type: String,
      default: "",
    },
    // 按钮内容
    btntext: {
      type: String,
      default: "保存",
    },
    // 返回默认页面
    backPath: {
      type: String,
      default: "1",
    },
    // 自定义后退事件
    back: {
      type: Function,
    },
    // 是否显示按钮
    showSaveBtn: {
      type: Boolean,
      default: true,
    },
    // 是否显示tab页签
    showTabs: {
      type: Boolean,
      default: false,
    },
    //tab页签数据
    tabData: {
      type: Array,
      default: ()=>[],
    },
    //当前页签
    activeName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tabActiveName: this.activeName,
    }
  },

  watch: {
    activeName(newVal) {
      if (newVal) this.tabActiveName = newVal;
    }
  },

  methods: {
    goBack(event) {
      // 自定义后退事件触发
      if (this.back) {
        return this.back(event);
      }
      // 是否跳转到默认页面
      if (this.backPath) {
        this.$router.push({ path: this.backPath });
      } else {
        this.$router.back();
      }
    },

    saveClick() {
      this.$emit("saveClick");
    },
    tabClick(e) {
      this.$emit("tabClick",e);
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 24px;
  font-size: 16px;
  background-color: #fff;
}
.tabClass {
  background: #fff;
  padding-top: 10px;
}
.header >>> .el-page-header {
  width: 85%;
}
.header >>> .el-page-header__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 65%;
  overflow: hidden;
  /* cursor: pointer; */
}
</style>
