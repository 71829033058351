<template>
  <div class="app-container">

    <!-- 搜索 -->
    <query-form :queryForm="queryForm" :queryData="queryData" @handleQuery="handleQuery" @handleReset="handleReset" />

    <div class="sys_search_line">
      <el-button type="primary" size="medium" icon="el-icon-plus" @click="getInfo(0)">添加角色</el-button>
      <el-button type="danger" :disabled="selectAllStatus" plain size="medium" @click="removeAll">批量删除</el-button>
    </div>

    <el-table stripe 
      v-loading="loading" 
      :data="pageData" 
      element-loading-text="加载中..."
      :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }" @selection-change="handleSelectionChange">

      <!-- 空数据状态的插槽 -->
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>

      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column label="ID" width="100" fixed>
        <template slot-scope="scope">{{ scope.row.id }}</template>
      </el-table-column>

      <el-table-column label="角色">
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-switch class="el_switch_small" :value="scope.row.status"
            @change="switchChange(scope.row)" 
            :active-value="1"
            :inactive-value="0"
            active-text="开启" inactive-text="关闭" />
        </template>
      </el-table-column>

      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link operation">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><span @click="getInfo(scope.row.id)">编辑</span></el-dropdown-item>
              <el-dropdown-item><span @click="getRemove(scope.row.id)">删除</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination :total="pageSize.total" :page.sync="pageSize.current_page" :limt.sync="pageSize.per_page"
      @pagination="pagination" />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index.vue'
import { getRolesList, getRolesChangeStatus, getRolesRemove, getRolesRemoveAll } from '@/api/setting';
export default {
  name: 'rolesList',

  components: {
    Pagination
  },

  data() {
    return {

      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 10,
        search_name: "",
      },
      // 搜索框
      queryForm: [
        { type: "input", label: "标题", model: "search_name", placeholder: "请输入标题" },
      ],
      // 页面数据
      pageData: [],
      pageSize: {},
      removeData: [], // 批量删除

      // 页面状态
      loading: false,
      selectAllStatus: true,

    }
  },

  created() {
    this.init()
  },

  methods: {

    init() {
      this.getList();
    },

    getList() {
      this.loading = true;
      getRolesList(this.queryData).then(res => {
        const { data, total, current_page, per_page } = res.result

        this.pageData = data;
        this.pageSize = {
          total,
          current_page,
          per_page
        }

        this.loading = false;
      })
    },

    // 查询按钮
    handleQuery(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 重置按钮
    handleReset(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 选择表格操作
    handleSelectionChange(val) {
      if (val.length) {
        this.selectAllStatus = false;
        val.map((item) => {
          this.removeData.push(item.id)
        })
        this.removeData = [...new Set(this.removeData)]
      } else {
        this.selectAllStatus = true;
      }
    },

    // 分页点击事件
    pagination(data) {
      this.queryData = { ...this.queryData, ...data };
      this.getList();
    },

    // 新增 / 编辑
    getInfo(id) {
      this.$router.push({
        path: 'rolesInfo',
        query: {
          id
        }
      })
    },

    // 上 / 下架状态
    switchChange(item) {
      this.$messageConfirm(`确认${item.status == 0 ? '开启' : '关闭'}`, () => {
        let httpData = {
          id: item.id,
          status: item.status == 0 ? 1 : 0
        }
        getRolesChangeStatus(httpData).then(res => {
          this.$messageNotify(res);
          item.status == 0 ? item.status = 1 : item.status = 0;
        })
      })
    },

    // 删除
    getRemove(id) {
      this.$messageConfirm(`确认删除?`, () => {
        getRolesRemove({
          id
        }).then(res => {
          this.$messageNotify(res);
          this.getList()
        })
      })
    },

    // 批量删除
    removeAll() {
      if (this.selectAllStatus) {
        return this.$message.warning("请先选中数据再操作!");
      }
      this.$messageConfirm(`确认批量删除?`, () => {
        getRolesRemoveAll({
          ids: this.removeData
        }).then(res => {
          this.$messageNotify(res);
          this.getList()
        })
      })
    },
  }
}
</script>

<style scoped>
.operation {
  color: #1890ff;
  cursor: pointer;
}
</style>
