import request from '@/utils/request'

// 商品列表
export function getGoodsList(data) {
  return request({
    url: '/api/goods/list',
    method: 'post',
    data
  })
}

// 商品新增 || 编辑
export function getGoodsSave(data) {
  return request({
    url: '/api/goods/save',
    method: 'post',
    data
  })
}



// 商品详情
export function getGoodsInfo(id) {
  return request({
    url: '/api/goods/info',
    method: 'post',
    data: { id }
  })
}

// 商品删除
export function getGoodsRemove(data) {
  return request({
    url: '/api/goods/remove',
    method: 'post',
    data
  })
}

// 商品上下架
export function getGoodsStatus(data) {
  return request({
    url: '/api/goods/change_status',
    method: 'post',
    data
  })
}

// 商品分类列表
export function getGoodsCategoryList(data) {
  return request({
    url: '/api/goods_category/list',
    method: 'post',
    data
  })
}

// 商品分类新增 || 编辑
export function getGoodsCategorySave(data) {
  return request({
    url: '/api/goods_category/save',
    method: 'post',
    data
  })
}

// 商品分类删除
export function getGoodsCategoryRemove(id) {
  return request({
    url: '/api/goods_category/remove',
    method: 'post',
    data: { id }
  })
}

// 商品分类详情
export function getGoodsCategoryInfo(data) {
  return request({
    url: '/api/goods_category/info',
    method: 'post',
    data
  })
}


