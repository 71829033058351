import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'
import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters


// 动态表单组件
import NgFormElement from 'ng-form-element'
import 'ng-form-element/lib/ng-form-element.css'

import {
  GenerateForm,
  MakingForm
} from 'form-making'
import 'form-making/dist/FormMaking.css'

if (process.env.NODE_ENV === 'production') {
  const { mockXHR } = require('../mock')
  mockXHR()
}

// 全局搜索组件
import PageFilter from '@/components/PageFilter/index.vue'
import QueryForm from './components/QueryForm/index'

// 全局富文本Tinymce组件
import Tinymce from '@/components/Tinymce/index.vue'
import TinymceAntd from '@/components/TinymceAntd/index.vue' // 加载快一点
import newEditor from '@/components/quillEditor/index.vue'
// 全局头部组件
import PageHeader from '@/components/PageHeader/index'

Vue.component('PageFilter', PageFilter)
Vue.component('Tinymce', Tinymce)
Vue.component('TinymceAntd', TinymceAntd)
Vue.component('PageHeader', PageHeader)
Vue.component('newEditor', newEditor)

// 全局确定弹框
import { messageConfirm, messageNotify } from '@/utils/confirm'
Vue.prototype.$messageConfirm = messageConfirm;
Vue.prototype.$messageNotify = messageNotify;

// 全局表格自定义宽度
import { flexColumnWidth } from '@/utils/table'
Vue.prototype.$flexColumnWidth = flexColumnWidth;

import sumJs from '@/utils/sum'
Vue.prototype.$sumJs = sumJs;

import {thousandth} from '@/utils/index'
Vue.prototype.$thousandth = thousandth;

import { Loading } from 'element-ui'
Vue.prototype.$fullLoading = (loadingText) => {
  const loading = Loading.service({ // 声明一个loading对象
    lock: true, // 是否锁屏
    text: loadingText, // 加载动画的文字
    spinner: 'el-icon-loading', // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.8)', // 背景颜色
  })
  setTimeout(function() { // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
     loading.close() // 关闭遮罩层
   }, 2000000)
  return loading
}

// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
}).use(NgFormElement).use(QueryForm).use(MakingForm).use(GenerateForm)

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
