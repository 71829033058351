import city from '@/static/addr.json'

export function getArea(cnCode) {
  if (cnCode === 'CN') {
    return '中国'
  }

  const state = cnCode.indexOf("C") != -1
  let code = ''
  if (state) {
    code = cnCode.split("_")[1]
  } else {
    code = cnCode
  }

  let values = [];
  let codes = [];
  function findCity(list, code, father) {
    const index = list.findIndex(ev => {
      return ev.code === code;
    });
 
    if (index > -1) {
      const children = list[index];
      values.unshift(children.value);
      codes.unshift(children.code);
      father && values.unshift(father.value) && codes.unshift(father.code);
      father && findCity(city || [], father.code);
      return;
    }
    list.map(item => {
      if (item.children) {
        findCity(item.children || [], code, item);
      }
    });
  }
  findCity(city, code);
  values = [...new Set(values)];
  codes = [...new Set(codes)];
  const value = values.join('-')
  // return { codes, values };
  return value;
}