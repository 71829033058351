<template>
  <div>
    <div style="margin-bottom: 10px" v-if="showCondition && conditionShow">
      <span>查询条件:</span>
      <el-cascader
        v-model="cascaderValue"
        :options="options"
        :props="props"
        collapse-tags
        clearable
        @change="selectChange"
        @visible-change="visibleChange"
        ref="selectForm"
        size="mini"
      ></el-cascader>
    </div>

    <el-form ref="queryForm" :inline="true" :model="searchData">
      <template v-for="(item, index) in queryFormList">
        <FormItem
          :key="index"
          :item="item"
          size="mini"
          @keyup.enter.native="handleQuery"
          v-bind="item"
          v-model="searchData[item.model]"
        />
      </template>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleQuery()"
          icon="el-icon-search"
          size="mini"
          >查询</el-button
        >
        <el-button @click="handleReset()" icon="el-icon-refresh" size="mini"
          >重置</el-button
        >
        <el-button
          @click="conditionShow = true"
          icon="el-icon-setting"
          size="mini"
          v-if="showCondition"
          >自定义查询条件</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import FormItem from "./FormItem.vue";
export default {
  components: { FormItem },
  name: "QueryForm",
  props: {
    queryData: {
      type: Object,
    },
    queryForm: {
      type: Array,
    },
    options: {
      type: Array,
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    showCondition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      props: { multiple: true },
      searchData: this.queryData,
      queryFormList: [],
      conditionShow: false,
      cascaderValue: [],
    };
  },
  watch: {
    queryData(val) {
      if (val) {
        this.searchData = val;
      }
    },
  },
  mounted() {
    this.cascaderValue = this.defaultValue;
    if (this.cascaderValue.length) {
      this.cascaderValue.forEach((item) => {
        let id = this.options[item[0] - 1].children.findIndex((v) => {
          return v.value == item[1];
        });
        let ele = this.options[item[0] - 1].children[id];
        this.queryFormList.push(ele);
      });
    } else {
      this.queryFormList = this.queryForm;
    }
  },
  methods: {
    // 初始化重置数据
    resetInit() {
      this.searchData.page = 1;
      this.searchData.page_size = 10;
    },

    handleQuery() {
      this.resetInit();
      this.$emit("handleQuery", this.searchData);
    },
    handleReset() {
      this.resetInit();
      this.$refs.queryForm.resetFields();

      this.queryFormList.forEach((item) => {
        for (let key in this.searchData) {
          if (item.type == "date" && key == item.model) {
            this.searchData[key] = null;
          }
        }
      });

      this.$emit("handleReset", this.searchData);
    },
    selectChange() {
      let s = this.$refs.selectForm.getCheckedNodes();
      let arr = [];
      s.forEach((item) => {
        if (!item.children.length) arr.push(item.data);
      });
      this.queryFormList = arr;
      this.$refs.queryForm.resetFields();
    },
    //自定义查询条件隐藏面板
    visibleChange(val) {
      if (!val) this.conditionShow = false;
    },
  },
};
</script>
