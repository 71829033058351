var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("query-form", {
        attrs: { queryForm: _vm.queryForm, queryData: _vm.queryData },
        on: { handleQuery: _vm.handleQuery, handleReset: _vm.handleReset },
      }),
      _c("div", { staticClass: "sys_search_line" }),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "5px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.getInfo(1)
                },
              },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            stripe: "",
            data: _vm.pageData,
            "element-loading-text": "加载中...",
            "header-cell-style": {
              backgroundColor: "#fafafa",
              color: "#606266",
            },
          },
        },
        [
          _c(
            "template",
            { slot: "empty" },
            [
              _c("el-empty", {
                attrs: { "image-size": 100, description: "暂无数据" },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "100", fixed: "" },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.base && row.base.real_name
                      ? _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.getInfo(2, row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.base.real_name) + " ")]
                        )
                      : _c("span", [_vm._v(" - ")]),
                    _c("div", [
                      _vm._v(" " + _vm._s(row.phone ? row.phone : " - ") + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "身份" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.base.system_agent_id
                      ? _c("el-tag", [
                          _vm._v(
                            " " + _vm._s(row.base.system_agent_name) + " "
                          ),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "行政区", "show-overflow-tooltip": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.base.province +
                              "-" +
                              row.base.city +
                              "-" +
                              row.base.area
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "上级", prop: "parent_id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.getInfo(2, row.parent)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.parent ? row.parent.base.real_name : " - "
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "子身份数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.children_total_count
                            ? scope.row.children_total_count
                            : 0
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "span",
                          { staticClass: "el-dropdown-link operation" },
                          [
                            _vm._v(" 操作"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.getInfo(1, row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("编辑")])]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.getInfo(2, row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("查看")])]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.getRemove(row.id)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("删除")])]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("pagination", {
        attrs: {
          total: _vm.pageSize.total,
          page: _vm.pageSize.current_page,
          limt: _vm.pageSize.per_page,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageSize, "current_page", $event)
          },
          "update:limt": function ($event) {
            return _vm.$set(_vm.pageSize, "per_page", $event)
          },
          pagination: _vm.pagination,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.formData.id == 0 ? "新增" : "编辑",
            visible: _vm.drawer,
            direction: "rtl",
            size: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "drawer_box" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 20px" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.formData,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户名称:", prop: "base.real_name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入用户名称" },
                          model: {
                            value: _vm.formData.base.real_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.base, "real_name", $$v)
                            },
                            expression: "formData.base.real_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户性别:", prop: "base.gender" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: true },
                            model: {
                              value: _vm.formData.base.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.base, "gender", $$v)
                              },
                              expression: "formData.base.gender",
                            },
                          },
                          _vm._l(_vm.genderList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.title, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系方式:", prop: "phone" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入联系方式" },
                          model: {
                            value: _vm.formData.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "phone", $$v)
                            },
                            expression: "formData.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "上级ID:", prop: "parent_id" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "50%" },
                            attrs: { clearable: true, filterable: "" },
                            model: {
                              value: _vm.formData.parent_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "parent_id", $$v)
                              },
                              expression: "formData.parent_id",
                            },
                          },
                          _vm._l(_vm.userList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: _vm.getAgentName(item),
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "行政区:", prop: "base.address_code" },
                      },
                      [
                        _c("el-cascader", {
                          ref: "regionRef",
                          attrs: {
                            placeholder: "请选择行政区",
                            options: _vm.xzqList,
                            props: _vm.defaultProps,
                            filterable: "",
                            clearable: "",
                          },
                          on: { change: _vm.xzqChange },
                          model: {
                            value: _vm.formData.base.address_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.base, "address_code", $$v)
                            },
                            expression: "formData.base.address_code",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "等级身份:", prop: "system_agent_id" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: true },
                            on: {
                              change: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                            model: {
                              value: _vm.formData.system_agent_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "system_agent_id", $$v)
                              },
                              expression: "formData.system_agent_id",
                            },
                          },
                          _vm._l(_vm.agantList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.title, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "demo-drawer__footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.drawer = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: {
                      type: "primary",
                      size: "small",
                      loading: _vm.drawerLoading,
                    },
                    on: { click: _vm.drawerSubmit },
                  },
                  [_vm._v(_vm._s(_vm.drawerLoading ? "提交中" : "保 存"))]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }