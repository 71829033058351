var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "data" },
    [
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                stripe: "",
                data: _vm.listData,
                "element-loading-text": "加载中...",
                "default-sort": { prop: "date", order: "descending" },
                "header-cell-style": {
                  backgroundColor: "#fafafa",
                  color: "#606266",
                },
              },
            },
            [
              _c(
                "template",
                { slot: "empty" },
                [
                  _c("el-empty", {
                    attrs: { "image-size": 100, description: "暂无数据" },
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", width: "60", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: { label: "订单编号", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.goInfo(row.id)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.no))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "利润", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v("¥ " + _vm._s(_vm.$thousandth(row.profit, 1))),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "订单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-tag",
                          { attrs: { type: row.status ? "success" : "" } },
                          [_vm._v(_vm._s(_vm.statusLabel[row.status]))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "结算状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: row.commission_status ? "success" : "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.commissionStatusLabel[row.commission_status]
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "created_at", width: "200" },
              }),
            ],
            2
          ),
          _c("pagination", {
            attrs: {
              total: _vm.pageSize.total,
              page: _vm.pageSize.current_page,
              limt: _vm.pageSize.per_page,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pageSize, "current_page", $event)
              },
              "update:limt": function ($event) {
                return _vm.$set(_vm.pageSize, "per_page", $event)
              },
              pagination: _vm.pagination,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }