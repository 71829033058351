import { login, getClearCache, getMenu } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  menu: [],
  roles: [],
  rules: [],
  systemBrand: [],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, nickname) => {
    state.name = nickname
  },
  SET_AVATAR: (state, avatar) => {
    console.log(avatar, 'avatar');
    state.avatar = avatar
  },
  // SET_MENU: (state, menu) => {
  //   state.menu = menu
  // },
  // SET_ROLES: (state, roles) => {
  //   state.roles = roles;
  // },
  // SET_RULES: (state, rules) => {
  //   state.rules = rules
  // },
  // SET_BRAND: (state, systemBrand) => {
  //   state.systemBrand = systemBrand
  // }
}

const actions = {
  updateToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },

  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { result } = response

        commit('SET_TOKEN', result.token)
        setToken("Bearer "+result.token)

        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getMenu({ commit, state }) {
    if (state.token == '') return;
    return new Promise((resolve, reject) => {
      getMenu(state.token).then(response => {
        const res = response.result
        if (!res) {
          reject('验证失败，请重新登录')
        }

        const { nickname, avatar,  roles, } = res;
        console.log(res)
        // if (!rules || rules.length <= 0) {
        //   reject('当前账号角色权限错误，无法登录！')
        // }

        commit('SET_NAME', nickname); // 账号名称
        commit('SET_AVATAR', avatar); // 头像
        // commit('SET_ROLES', roles); // 角色标签
        // commit('SET_RULES', rules); // 路由标签
        // commit('SET_MENU', menu); // 路由菜单
        // commit('SET_BRAND', systemBrand); // 品牌数据标签
        
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  clearCache({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      getClearCache().then(response => {
        commit('SET_TOKEN', '')
        // commit('SET_ROLES', [])
        // commit('SET_RULES', [])
        // commit('SET_MENU', [])
        removeToken()
        resetRouter()
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      // commit('SET_ROLES', [])
      // commit('SET_RULES', [])
      // commit('SET_MENU', [])
      removeToken()
      resetRouter()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      // commit('SET_ROLES', [])
      // commit('SET_RULES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, ruels) {
    const token = ruels + '-token'

    commit('SET_TOKEN', token)
    setToken("Bearer "+token)

    // const { rules } = await dispatch('getMenu')

    resetRouter()

    const accessRoutes = await dispatch('permission/generateRoutes', rules, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
