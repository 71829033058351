import request from '@/utils/request'

// 角色列表
export function getRolesList(data) {
  return request({
    url: 'api/role/list',
    method: 'post',
    data
  })
}

// 角色状态修改
export function getRolesChangeStatus(data) {
  return request({
    url: 'api/role/changeStatus',
    method: 'post',
    data
  })
}

// 角色详情
export function getRolesInfo(data) {
  return request({
    url: 'api/role/info',
    method: 'post',
    data
  })
}

// 角色新增 & 编辑
export function getRolesSave(data) {
  return request({
    url: 'api/role/save',
    method: 'post',
    data
  })
}

// 角色删除
export function getRolesRemove(data) {
  return request({
    url: 'api/role/remove',
    method: 'post',
    data
  })
}

// 角色 - 批量删除
export function getRolesRemoveAll(data) {
  return request({
    url: 'api/role/removeAll',
    method: 'post',
    data
  })
}

// 账号列表
export function getAdminList(data) {
  return request({
    url: 'api/admin/list',
    method: 'post',
    data
  })
}

// 账号新增 || 编辑
export function getAdminSave(data) {
  return request({
    url: 'api/admin/save',
    method: 'post',
    data
  })
}

// 账号详情
export function getAdminInfo(data) {
  return request({
    url: 'api/admin/info',
    method: 'post',
    data
  })
}

// 账号 - 开启 & 关闭
export function getAdminStatusChange(data) {
  return request({
    url: 'api/admin/updateStatus',
    method: 'post',
    data
  })
}

// 账号 - 删除
export function getAdminRemove(data) {
  return request({
    url: 'api/admin/remove',
    method: 'post',
    data
  })
}

// 手机验证码
export function reqSendSmscode(data) {
  return request({
    url: 'api/send/sms_code',
    method: 'post',
    data
  })
}