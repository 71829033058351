var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("page-header", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.loading,
            expression: "loading",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        attrs: {
          content: _vm.id ? "编辑" : "新增",
          "back-path": "userLabelList",
        },
        on: { saveClick: _vm.onSubmit },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { width: "80%", "margin-top": "24px" },
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "180px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签名称:", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  "data-pirceChange": "1",
                  placeholder: "请输入标签名称",
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标签备注:", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }