import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken, setToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data;
    let Authorization = response.headers.authorization;
    if (Authorization) {
      Authorization = "Bearer "+Authorization
      setToken(Authorization)
      store.dispatch('user/updateToken', Authorization)
    }
    
    if (res.code == 401) {
      Message({
        message: '未登录',
        type: 'error',
        duration: 1500
      })
      store.dispatch('user/logout');
      setTimeout(() => {
        router.push(`/login?redirect=${''}`);
      }, 1500);
      return Promise.reject(new Error(res.message || 'Error'));
    }

    if (res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      if (res.code === 500 || res.code === 502 || res.code === 504) {
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    if(error.message.indexOf('401') !=-1){
      store.dispatch('user/logout');
      setTimeout(() => {
        router.push(`/login?redirect=${''}`);
      }, 1500);
    }
    return Promise.reject(error)
  }
)

export default service
