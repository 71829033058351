<template>
  <div class="app-container">
    <!-- 搜索 -->
    <query-form
      :queryForm="queryForm"
      :queryData="queryData"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    />

    <div class="sys_search_line"></div>

    <div style="margin-bottom: 5px">
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        @click="getInfo(1)"
        >新增</el-button
      >
    </div>

    <el-table
      stripe
      v-loading="loading"
      :data="pageData"
      element-loading-text="加载中..."
      :header-cell-style="{ backgroundColor: '#fafafa', color: '#606266' }"
    >
      <!-- 空数据状态的插槽 -->
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>
      <el-table-column label="ID" prop="id" width="100" fixed />

      <el-table-column label="姓名">
        <template slot-scope="{ row }">
          <el-link type="primary" v-if="row.base && row.base.real_name" @click="getInfo(2, row)">
            {{ row.base.real_name }}
          </el-link>
          <span v-else> - </span>

          <div>
            {{ row.phone ?  row.phone  : ' - '}}
          </div>

        </template>
      </el-table-column>

      <el-table-column label="身份">
        <template slot-scope="{ row }">
          <el-tag v-if="row.base.system_agent_id">
            {{ row.base.system_agent_name }}
          </el-tag>
          <span v-else> - </span>
        </template>
      </el-table-column>

      <el-table-column label="行政区" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span>
            {{row.base.province + "-" + row.base.city + "-" + row.base.area}}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="上级" prop="parent_id"  >
        <template slot-scope="{ row }">
          <el-link type="primary"  @click="getInfo(2, row.parent)">
            {{row.parent ? row.parent.base.real_name : ' - '}}
          </el-link>
        </template>
      </el-table-column>

      <el-table-column label="子身份数量">
        <template slot-scope="scope">
          <span>{{
            scope.row.children_total_count ? scope.row.children_total_count : 0
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="{ row }">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link operation">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="getInfo(1, row)">
                <span>编辑</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="getInfo(2, row)">
                <span>查看</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="getRemove(row.id)"
                ><span>删除</span>
              </el-dropdown-item>
            </el-dropdown-menu>

          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="pageSize.total"
      :page.sync="pageSize.current_page"
      :limt.sync="pageSize.per_page"
      @pagination="pagination"
    />

    <el-drawer
      :title="formData.id == 0 ? '新增' : '编辑'"
      :visible.sync="drawer"
      :direction="'rtl'"
      size="60%"
    >
      <div class="drawer_box">
        <div style="padding: 0 20px">
          <el-form
            ref="ruleForm"
            :model="formData"
            :rules="rules"
            label-width="100px"
          >
            <el-form-item label="用户名称:" prop="base.real_name">
              <el-input
                v-model="formData.base.real_name"
                placeholder="请输入用户名称"
              />
            </el-form-item>
            <el-form-item label="用户性别:" prop="base.gender">
              <el-select v-model="formData.base.gender" :clearable="true">
                <el-option
                  v-for="item in genderList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系方式:" prop="phone">
              <el-input v-model="formData.phone" placeholder="请输入联系方式" />
            </el-form-item>
            <el-form-item label="上级ID:" prop="parent_id">
              <!-- <el-input
                v-model="formData.parent_id"
                placeholder="请输入父级ID"
              /> -->
              <el-select
                v-model="formData.parent_id"
                :clearable="true"
                style="width: 50%"
                filterable
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="getAgentName(item)"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="行政区:" prop="base.address_code">
              <el-cascader
                ref="regionRef"
                v-model="formData.base.address_code"
                placeholder="请选择行政区"
                :options="xzqList"
                :props="defaultProps"
                filterable
                clearable
                @change="xzqChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="等级身份:" prop="system_agent_id">
              <el-select
                v-model="formData.system_agent_id"
                :clearable="true"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="item in agantList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>

        <div class="demo-drawer__footer">
          <el-button class="btn" size="small" @click="drawer = false"
            >取 消</el-button
          >
          <el-button
            class="btn"
            type="primary"
            size="small"
            @click="drawerSubmit"
            :loading="drawerLoading"
            >{{ drawerLoading ? "提交中" : "保 存" }}</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getUserList,
  getLabelList,
  reqUserSave,
  reqUserRemove,
  getSubList,
} from "@/api/member";
import axios from "axios";

import { flexColumnWidth } from "@/utils/table";

import Pagination from "@/components/Pagination/index.vue";
import { getArea } from "@/utils/addrFilter";
export default {
  name: "userList",

  components: {
    Pagination,
  },

  data() {
    return {
      tabIndex: 0,

      // 搜索条件和搜索框数据
      queryData: {
        page: 1,
        page_size: 10,
        search_sub: 0,
        search_id: "",
        search_phone: "",
      },
      // 搜索框
      queryForm: [
        {
          type: "input",
          label: "用户ID",
          model: "search_id",
          placeholder: "请输入用户ID",
        },
        {
          type: "input",
          label: "手机号",
          model: "search_phone",
          placeholder: "请输入昵称",
        },
        // {
        //   type: "select",
        //   label: "代理等级",
        //   model: "search_system_agent_id",
        //   placeholder: "请选择",
        //   options: [],
        // },
      ],

      // 页面数据
      pageData: [],
      pageSize: {},

      // 页面状态
      loading: false,
      tabsIndex: "all",

      drawer: false,
      drawerLoading: false,
      formData: {
        id: 0,
        base: {},
      },
      rules: {
        "base.real_name": [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        "base.gender": [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        parent_id: [
          { required: true, message: "请输入上级ID", trigger: "blur" },
        ],
        "base.address_code": [
          { required: true, message: "请选择行政区", trigger: "change" },
        ],
        system_agent_id: [
          { required: true, message: "请选择等级身份", trigger: "change" },
        ],
      },
      genderList: [
        { id: 0, title: "未知" },
        { id: 1, title: "男" },
        { id: 2, title: "女" },
      ],
      //行政区下拉选项
      // xzqhx: "",
      xzqList: [],
      defaultProps: {
        label: "value",
        value: "code",
        children: "children",
      },
      //等级身份列表
      agantList: [],
      //用户列表数据
      userList: [],
    };
  },

  created() {
    this.init();
  },

  computed: {
    getAgentName() {
      return function (item) {
        let name = "-";
        if (item.base) {
          name =
            item.base.real_name +
            "-" +
            item.base.province +
            "-" +
            item.base.city +
            "-" +
            item.base.area +
            "-" +
            item.base.system_agent_name;
        }
        return name;
      };
    },
  },

  methods: {
    flexColumnWidth,

    async init() {
      await this.getAgantList();
      await this.getXzqList();
      await this.getUserList();
      await this.getList();
    },


    // 获取用户列表数据
    getList() {
      this.loading = true;
      // 获取列表数据
      getUserList(this.queryData).then((resp) => {
        const { data, total, current_page, per_page } = resp.result;

        this.pageData = data;
        this.pageSize = {
          total,
          current_page,
          per_page,
        };
        this.loading = false;
      });
    },
    //获取行政区列表
    getXzqList() {
      const url = "/addr.json";
      axios
        .get(url)
        .then((response) => {
          this.xzqList = response.data;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    },
    //获取等级列表
    getAgantList() {
      getLabelList({
        page: 1,
        page_size: 100,
      }).then((res) => {
        this.agantList = res.result.data;
      });
    },
    // 获取全部用户列表数据
    getUserList() {
      let searchData = JSON.parse(JSON.stringify(this.queryData));
      searchData.search_sub = 1;
      searchData.page_size = 100;
      getUserList(searchData).then((resp) => {
        this.userList = resp.result.data;
        this.userList.unshift({
          id: 0,
        });
      });
    },
    // 查询按钮
    handleQuery(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 重置按钮
    handleReset(queryModel) {
      this.queryData = queryModel;
      this.getList();
    },

    // 分页点击事件
    pagination(data) {
      this.queryData = { ...this.queryData, ...data };
      this.getList();
    },

    // 详情
    getInfo(type, info) {
      if (type == 1) {
        this.formData = info ? info : this.$options.data().formData;
        this.formData.system_agent_id = info ? info.base.system_agent_id : "";
        this.getUserList();
        this.drawer = true;
      } else {
        let id = info.id;
        this.$router.push({
          path: "userInfo",
          query: {
            id,
          },
        });
      }
    },

    // 删除
    async getRemove(id) {
      this.$messageConfirm(`确认删除?`, () => {
        reqUserRemove({
          id,
        }).then((res) => {
          this.$messageNotify(res);
          this.getList();
        });
      });
    },

    //选择行政区
    xzqChange(data) {
      if (data[2]) {
        let xzq = getArea(data[2]);
        let district = xzq.split("-");
        this.formData.base.province = district[0];
        this.formData.base.city = district[1];
        this.formData.base.area = district[2];
      }
      this.$refs.regionRef.dropDownVisible = false;
    },

    drawerSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.drawerLoading = true;
          // 数据处理

          reqUserSave(this.formData).then((res) => {
            // 清空表单验证
            this.$refs.ruleForm.resetFields();
            this.drawer = false;

            this.$messageNotify(res);
            this.getList();
          });

          setTimeout(() => {
            this.drawerLoading = false;
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 2px;
  padding: 24px;
  margin-top: 10px;

  .user_header_item {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #dfdfdf;
    text-align: center;
    flex: 1;
    font-size: 14px;

    .user_header_item_span {
      margin-top: 6px;
      font-size: 24px;
    }
  }

  .user_header_item:last-of-type {
    border-right: 0;
  }
}

.operation {
  color: #1890ff;
  cursor: pointer;
}

::v-deep .el-tabs__header {
  margin-bottom: 0 !important;
}

::v-deep .el-tabs__content {
  padding: 20px 0 0;
  border-top: 0;
}

.container {
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  border: 1px solid #e8e8e8;
}

.tab_dropdown {
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 4px 4px 0 0;
  border: 1px solid #e8e8e8;
  border-bottom: 0;

  .tab_dropdown_title {
    font-size: 14px;

    span {
      color: #1890ff;
      font-weight: bold;
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #1890ff;
  }
}

</style>
